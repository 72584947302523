import React from 'react'
import PropTypes from 'prop-types'

import PlayerGroup from '../PlayerGroup'
import styles from './Team.module.scss'

export const teamTypeConstants = {
  HOME: 'home',
  AWAY: 'away',
}

const Team = ({ type, handleClick, maxNrPlayers, groupsConstants, onSaveNumber, page }) => {
  return (
    <div className={styles.teamWrapper}>
      <div className={styles.teamGroupWrapper}>
        {groupsConstants.map((groupType) => (
          <PlayerGroup
            key={`${type}-${groupType}`}
            teamType={type}
            groupType={groupType}
            handleClick={handleClick}
            maxNrPlayers={maxNrPlayers}
            onSaveNumber={onSaveNumber}
            type={page}
          />
        ))}
      </div>
    </div>
  )
}

Team.defaultProps = {
  type: teamTypeConstants.HOME,
  handleClick: () => {},
  handleExtraBtn: () => {},
  maxNrPlayers: 11,
  onSaveNumber: () => {},
}

Team.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  maxNrPlayers: PropTypes.number,
  onSaveNumber: PropTypes.func,
}

export default Team
