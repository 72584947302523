import React from 'react'
import { useIntl } from 'react-intl'
import styles from './EventsOrderConflictsHandler.module.scss'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Comment from '../Comments/Comment'
import { Button } from 'react-bootstrap'
import logo from '../../assets/logo48x48.svg'

const EventsOrderConflictsHandler = ({ show, onHide, conflicts, events, matchInfo, handleCancelEvent, handleDelete, handleInsertAnyway }) => {
  const { formatMessage: f } = useIntl()

  const cancelEvent = () => {
    handleCancelEvent()
    onHide()
  }

  const insertEvent = () => {
    handleInsertAnyway(conflicts[2])
    onHide()
  }

  const event = events.find((event) => event.id === conflicts[1])
  const icon = event?.img_path ? `https://www.zerozero.pt/images/mc/live/${event.img_path}` : logo

  return (
    <Modal show={show} onHide={cancelEvent} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'match.conflictsOrder.title' })}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.cmt_icon_container}>
          <img
            alt=""
            src={icon}
            className={classNames('rounded-circle', styles.event_icon)}
          />
          {event?.abrev}
        </div>
        <Comment
          matchInfo={matchInfo}
          comment={conflicts[0]}
          events={events}
          resolveConflict
          handleDelete={handleDelete}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancelEvent}>{f({ id: "events.notAddEvent" })}</Button>
        <Button onClick={insertEvent}>{f({ id: "events.addEvent" })}</Button>
      </Modal.Footer>
    </Modal>
  )
}

EventsOrderConflictsHandler.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  matchInfo: PropTypes.object.isRequired,
  conflicts: PropTypes.array,
  events: PropTypes.array.isRequired,
  handleCancelEvent: PropTypes.func.isRequired,
  handleInsertAnyway: PropTypes.func.isRequired,
}

export default EventsOrderConflictsHandler
