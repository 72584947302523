import { serviceUtils } from '../helpers/serviceUtils'
import moment from 'moment'

const responseHandler = (data) => {
  if (!data || data.date === '') {
    throw new Error(data)
  }
}

const errorHandler = () => {
  // eslint-disable-next-line
  return Promise.reject(
    'A network error has occured, trying to use cached results when possible',
  )
}

const fetchMatch = (matchId) => {
  const currentTime = moment().format('YYYY-MM-DD_HH:mm:ss')

  return serviceUtils.get(
    serviceUtils.baseUrl(`getMatchLiveInfo/MatchID/${matchId}/ClientTime/${currentTime}`),
    null,
    null,
    {
      response: responseHandler,
      error: errorHandler,
    },
  )
}

const fetchTeam = (teamId, matchId) => {
  return serviceUtils.get(
    serviceUtils.baseUrl(`getTeamLive/TeamID/${teamId}/MatchID/${matchId}`),
    null,
    null,
    {
      error: errorHandler,
    },
  )
}

const sendTeams = (teams) => {
  return serviceUtils.post(
    serviceUtils.baseUrl('setLineups'),
    {
      'authorization-key': localStorage.getItem('zos-cookie'),
      'content-type': 'application/json',
    },
    teams,
    {
      error: errorHandler,
    },
    true,
  )
}

const prepareSendTeams = (teams) => {
  return {
    url: serviceUtils.baseUrl('setLineups'),
    method: 'POST',
    json: teams,
    headers: {
      'authorization-key': localStorage.getItem('zos-cookie'),
      'content-type': 'application/json',
    },
  }
}

const sendFormations = (teams) => {
  return serviceUtils.post(
    serviceUtils.baseUrl('setFormations'),
    {
      'authorization-key': localStorage.getItem('zos-cookie'),
      'content-type': 'application/json',
    },
    teams,
    {
      error: errorHandler,
    },
    true,
  )
}

export const matchSetupService = {
  fetchMatch,
  fetchTeam,
  sendTeams,
  prepareSendTeams,
  sendFormations,
}
