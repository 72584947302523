import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import className from 'classnames'
import FiltersEngine from '../../components/FiltersEngine/FiltersEngine'
import { Row, Container, Col } from 'react-bootstrap'
import MatchesResult from '../../components/ResultsDisplay'
import { match } from '../../services/SearchServices'
import styles from './SearchMatches.module.scss'
import { useIntl } from 'react-intl'
import { getDistanceHaversine } from '../../helpers/geolocationArith'
import ContentLayout from '../../components/ContentLayout'

function SearchMatches() {
  const { formatMessage: f } = useIntl()
  const [results, setResults] = useState({})
  const [location, setLocation] = useState({ available: true })
  const [numberResults, setNumberResults] = useState(0)
  const update = (results) => {
    // if geolocation sorting is activated, coords are set
    if (location.coords) {
      sortResultsByGeolocation(results)
    } else setResults(results)

    nResults(results)
  }

  useEffect(() => {
    update(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getLocation = (event) => {
    if (event.target.checked) {
      // check if geolocation is supported/enabled on current browser
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          function success(position) {
            // for when getting location is a success
            setLocation({ coords: position.coords, available: true })
          },
          // eslint-disable-next-line
          function error(error_message) {
            // for when getting location results in an error
            setLocation({ available: false })
          },
        )
      } else {
        // geolocation is not supported
        setLocation({ available: false })
      }
    } else setLocation({ available: true })
  }

  const sortResultsByGeolocation = (results) => {
    const coords = location.coords
    // array to "flat out" results
    const arrNewRes = []
    // temporary array holds objects with position and sort-value
    const resultsMap = []
    for (const modality in results) {
      const mappedGames = results[modality].map(function (el) {
        return {
          id: el.jogo_id,
          value: getDistanceHaversine(
            coords.latitude,
            coords.longitude,
            el.estadio_lat,
            el.estadio_lng,
          ),
        }
      })
      resultsMap.push(...mappedGames)
      arrNewRes.push(...results[modality])
    }

    // sort temp map
    resultsMap.sort(function (a, b) {
      if (parseInt(a.value) === -1 && parseInt(b.value) !== -1) return 1
      else if (parseInt(a.value) !== -1 && parseInt(b.value) === -1) return -1
      return parseInt(a.value) - parseInt(b.value)
    })

    let newResults = {}
    let arrNewRes1 = []
    // container for the resulting order
    arrNewRes1 = resultsMap.map(function (el) {
      return arrNewRes.find((element) => element.jogo_id === el.id)
    })

    newResults = { games: arrNewRes1 }

    setResults(newResults)
    nResults(newResults)
  }

  const nResults = (games) => {
    let counter = 0
    for (const game in games) {
      for (let i = 0; i < games[game].length; i++) {
        counter++
      }
    }
    setNumberResults(counter)
  }

  return (
    <ContentLayout >

      <Container fluid className={className([styles.padContainers, styles.fieldsContainer])}>
        <Row >
          <Col className={styles.pageTitle} data-testid="page-title" >
            {f({ id: 'pageTitle.search' })}
          </Col>
        </Row>
        <Row >
          <Col className={styles.autocompleteRow}>
            <FiltersEngine
              callback={update}
              service={match}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {results && Object.keys(results).length > 0 && (
              <Form.Switch
                data-testid="geolocation"
                className={styles.geoSwitch}
                disabled={!location.available}
                id="custom-switch"
                label={
                  location.available
                    ? f({ id: 'search.sortGeolocation' })
                    : f({ id: 'search.geolocationUnavailable' })
                }
                onChange={getLocation}
              />
            )}
          </Col>
        </Row>
      </Container>
      {Object.keys(results).length > 0 &&
        <Container className={styles.padContainers} fluid>
          <Row xs="auto" lg="auto" className={styles.numberResults}>
            <Col>
              {numberResults} {numberResults === 1 ? f({ id: 'search.oneResult' }) : f({ id: 'search.multipleResults' })}
            </Col>
          </Row>
        </Container>
      }
      <Container data-testid="results" fluid className={className([styles.padContainers, styles.results])}>

        {Object.keys(results).map((modality, index1) => {
          return results[modality].map((game, index) => {
            const key = index1 + Object.keys(results).length * index
            return (
              <MatchesResult key={key} matchInfo={game}></MatchesResult>
            )
          })
        })}
      </Container>

    </ContentLayout>
  )
}

export default SearchMatches
