import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import styles from './MatchInfo.module.scss'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { TbSoccerField } from 'react-icons/tb'
import LivePenaltiesPanel from '../LivePenaltiesPanel/LivePenaltiesPanel'
import { useIntl } from 'react-intl'
import MatchClock from '../MatchClock/MatchClock'

const STATE_LABEL = Object.freeze({
  parte1: '1ª Parte',
  int: 'Intervalo',
  parte2: '2ª Parte',
  int2p: 'Fim 2ª Parte',
  extra_parte1: '1ª Parte Prolong.',
  intet: 'Intervalo Prolong.',
  extra_parte2: '2ª Parte Prolong.',
  fin: 'Fim do jogo',
})

const MatchInfo = ({
  matchDate, matchId, matchBegin, matchFinished, teamRight, acroTeamRight,
  teamLeft, acroTeamLeft, clock, extra, showMatchTimeEditor, showFormationsEditor, state,
  showLivePenalties, livePenalties, showInSmallScreen, edition, setClock, noClock,
  totalDuration, extraTimePartDuration,
}) => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <div className={styles.headerWrapper}>
        <div
          className={classNames(styles.match_info_container, styles.row_info)}
        >
          <Col
            md={5}
            xs={4}
            className={classNames(
              styles.colLeft,
              styles.alignCenter,
              'align-self-center',
              styles.flex_container_left,
            )}
          >
            <div
              className={classNames(
                styles.imgLeft,
                styles.alignCenter,
                'align-self-center',
                styles.match_info_container,
              )}
            >
              <img
                alt=""
                src={teamLeft.logo}
                className={styles.team_img_left}
              />
            </div>
            <div
              className={classNames(styles.alignCenter, 'align-self-center')}
            >
              <p data-testid="teamLeft" className={`${styles.left_team_name} ${showInSmallScreen ? '' : styles.team_name_invisible}`}>
                {acroTeamLeft}
              </p>
            </div>
          </Col>
          <Col
            md={2}
            xs={4}
          >
            <div
              className={classNames(
                styles.scores_container,
                styles.alignCenter,
                'align-self-center',
                'm-0',
              )}
              style={{
                color: '#ffffff',
                fontSize: '15px',
                lineHeight: '1rem',
              }}>
              {edition}
            </div>
            <div
              className={classNames(
                styles.scores_container,
                styles.alignCenter,
                'align-self-center',
              )}
            >
              <div
                data-testid="scoreTeamLeft"
                className={styles.matchScores}
                style={{ textAlign: 'right', marginRight: '0' }}
              >
                {teamLeft.score}
              </div>
              <p>&nbsp;-&nbsp;</p>
              <div
                data-testid="scoreTeamRight"
                className={styles.matchScores}
                style={{ textAlign: 'left', marginRight: '0' }}
              >
                {teamRight.score}
              </div>
            </div>
            {showLivePenalties && (
              <div
                className={classNames(
                  styles.scores_container,
                  styles.alignCenter,
                  'align-self-center',
                  'm-0',
                )}
                style={{
                  color: '#ffffff',
                  lineHeight: '1rem',
                }}>
                ({teamLeft.penScore} - {teamRight.penScore} {f({ id: 'penInitials' })})
              </div>
            )}
          </Col>
          <Col
            md={5}
            xs={4}
            className={classNames(
              styles.colRight,
              styles.alignCenter,
              'align-self-center',
              styles.flex_container_right,
            )}
          >
            <div
              className={classNames(
                styles.teamRight,
                styles.alignCenter,
                'align-self-center',
              )}
            >
              <p data-testid="teamRight" className={`${styles.right_team_name} ${showInSmallScreen ? '' : styles.team_name_invisible}`}>
                {acroTeamRight}
              </p>
            </div>
            <div
              className={classNames(
                styles.imgRight,
                styles.alignCenter,
                'align-self-center',
              )}
            >
              <img
                alt=""
                src={teamRight.logo}
                className={styles.team_img_right}
              />
            </div>
          </Col>
        </div>
        <div
          className={classNames(styles.match_info_container, styles.row_info, styles.game_state)}
        >
          <Col
            md={5}
            xs={4}
            className={classNames(
              styles.colLeft,
              styles.alignCenter,
              'align-self-center',
              styles.flex_container_left,
            )}
          ></Col>
          <Col
            md={2}
            xs={4}
            className={classNames(
              styles.scores_container,
              styles.alignCenter,
              'align-self-center',
            )}
          >
            {STATE_LABEL[state]}
          </Col>
          <Col
            md={5}
            xs={4}
            className={classNames(
              styles.colRight,
              styles.alignCenter,
              'align-self-center',
              styles.flex_container_right,
            )}
          ></Col>
        </div>

        <div
          className={classNames(styles.match_info_container, styles.row_clock)}
        >
          {showFormationsEditor && (
            <div className={classNames(styles.left_side)}>
              <LinkContainer to={`/match/${matchId}/home/home`}>
                <button className={classNames(styles.div_ex_clock)} href="">
                  <TbSoccerField size={'1.7em'} strokeWidth={'1'} />
                </button>
              </LinkContainer>
            </div>
          )}
          <div className={classNames(styles.left_side)}>
            <LinkContainer to={`/match/${matchId}/home`}>
              <button className={classNames(styles.div_ex_clock)} href="">
                <FontAwesomeIcon icon={faUserFriends} />
              </button>
            </LinkContainer>
          </div>

          <div className={classNames(styles.center_side)}>
            <MatchClock
              matchDate={matchDate}
              matchBegin={matchBegin}
              matchFinished={matchFinished}
              clock={clock}
              extra={extra}
              showMatchTimeEditor={showMatchTimeEditor}
              setClock={setClock}
              noClock={noClock}
              state={state}
              totalDuration={totalDuration}
              extraTimePartDuration={extraTimePartDuration}
            />
          </div>
          <div className={classNames(styles.right_side)}>
            <LinkContainer to={`/match/${matchId}/away`}>
              <button className={classNames(styles.div_ex_clock)} href="">
                <FontAwesomeIcon icon={faUserFriends} />
              </button>
            </LinkContainer>
          </div>
          {showFormationsEditor && (
            <div className={classNames(styles.right_side)}>
              <LinkContainer to={`/match/${matchId}/away/away`}>
                <button className={classNames(styles.div_ex_clock)} href="">
                  <TbSoccerField size={'1.7em'} strokeWidth={'1'} />
                </button>
              </LinkContainer>
            </div>
          )}
        </div>
        {showLivePenalties && (
          <LivePenaltiesPanel penalties={livePenalties} ended={matchFinished === '1'} />
        )}
      </div>
    </>
  )
}

MatchInfo.propTypes = {
  matchId: PropTypes.string.isRequired,
  matchDate: PropTypes.string.isRequired,
  matchBegin: PropTypes.string.isRequired,
  matchFinished: PropTypes.string.isRequired,
  acroTeamLeft: PropTypes.string.isRequired,
  acroTeamRight: PropTypes.string.isRequired,
  teamRight: PropTypes.shape({
    name: PropTypes.string.isRequired,
    score: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }),
  teamLeft: PropTypes.shape({
    name: PropTypes.string.isRequired,
    score: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }),
  clock: PropTypes.shape({
    minutos: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }),
  extra: PropTypes.number.isRequired,
  showMatchTimeEditor: PropTypes.func.isRequired,
  showFormationsEditor: PropTypes.bool.isRequired,
  state: PropTypes.oneOf([
    'parte1',
    'int',
    'parte2',
    'int2p',
    'extra_parte1',
    'intet',
    'extra_parte2',
    'fin',
    '',
    'fin_et',
  ]),
  showLivePenalties: PropTypes.bool.isRequired,
  livePenalties: PropTypes.object,
  edition: PropTypes.string.isRequired,
  showInSmallScreen: PropTypes.bool.isRequired,
}

export default MatchInfo
