import React from 'react'
import { useIntl } from 'react-intl'
import styles from './ConflictsHandler.module.scss'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
// import closeIcon from '../../assets/eventEditorIcons/closeIcon.svg'
import PropTypes from 'prop-types'
import Comment from '../Comments/Comment'
import { Button } from 'react-bootstrap'

const ConflictsHandler = ({ show, onHide, conflicts, events, handleSelectWinnerRevision, handleSelectAllWinners, matchInfo, handleCancelEvent, handleDelete }) => {
  const { formatMessage: f } = useIntl()

  const handleSelectWinner = (evt) => () => {
    // handleSelectWinnerRevision(evt, conflicts)
    // onHide()
  }

  const selectAllWinners = () => {
    handleSelectAllWinners(conflicts)
    onHide()
  }

  const cancelEvent = () => {
    handleCancelEvent()
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'match.conflicts.title' })}{' '}
        </Modal.Title>
        {/* <img
          data-testid="closeButton"
          src={closeIcon}
          className={classNames(
            styles.header_icon,
            styles.cursor_on,
            styles.close_btn,
          )}
          onClick={onHide}
        /> */}
      </Modal.Header>
      <Modal.Body>
        {conflicts.map((c, i) => (
          <Comment
            matchInfo={matchInfo}
            key={i}
            id={i}
            comment={c}
            events={events}
            selectAsConflictWinner={handleSelectWinner(c)}
            resolveConflict={ i !== conflicts.length - 1}
            handleDelete={handleDelete}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancelEvent}>{f({ id: 'events.notAddEvent' })}</Button>
        <Button onClick={selectAllWinners}>{f({ id: 'events.addEvent' })}</Button>
      </Modal.Footer>
    </Modal>
  )
}

ConflictsHandler.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  matchInfo: PropTypes.object.isRequired,
  conflicts: PropTypes.array,
  events: PropTypes.array,
  handleSelectWinnerRevision: PropTypes.func,
  handleSelectAllWinners: PropTypes.func,
  cancelEvent: PropTypes.func,
}

export default ConflictsHandler
