import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import translationsEn from './translations/en.json'
import translationsPt from './translations/pt.json'
import translationsBr from './translations/br.json'

import AppRouter from './AppRouter'

const languages = {
  pt: translationsPt,
  en: translationsEn,
  br: translationsBr,
}

const App = () => {
  const locale = useSelector(state => state.locale)

  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name)
    })
  })

  return (
    <IntlProvider locale={locale} messages={languages[locale]}>
      <AppRouter />
    </IntlProvider>
  )
}

export default App
