import React, { useEffect, useRef } from 'react'
import noPhoto from '../../assets/lineups/no_photo.png'
import styles from './PlayerCard.module.scss'

const PlayerCard = ({
  player,
  occupiedPositions,
  tactic,
  positionPlayer,
  positionsConversion,
}) => {
  const sortedKeys = useRef([])

  function sortTactic(pos1, pos2) {
    return pos1[1].order - pos2[1].order
  }

  function sortTacticKeys() {
    const sortedObject = Object.entries(tactic).sort(sortTactic)
    const keys = []
    sortedObject.forEach(position => {
      keys.push(position[0])
    })

    sortedKeys.current = keys
  }

  useEffect(() => {
    let preferredPosition = player.subposition_id === '0' || player.subposition_id === '999' || player.position_id === '1' ? player.position_id : player.subposition_id

    // Only main position specified or sub positions with sides
    if (preferredPosition in positionsConversion) {
      preferredPosition = positionsConversion[preferredPosition]
    }

    sortTacticKeys()

    let placed = false
    for (const position in tactic) {
      // Check if the position is part of the selected tactic
      if (preferredPosition === position) {
        placed = true
        // Check if position is available
        let isAvailable = true
        for (const occupiedPosition of occupiedPositions) {
          if (occupiedPosition === position) {
            isAvailable = false
            break
          }
        }
        if (isAvailable || player.positioned) {
          // Position player where he belongs
          positionPlayer(player, position, `Player${player.id}`)
        } else {
          // Last loop, add player anyway
          findClosestPosition(position)
        }
        break
      }
    }
    if (!placed) {
      findClosestMainPosition(player.position_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const findClosestPosition = (preferredPosition) => {
    let positionIndex = -1

    // Find index of preferred position
    for (let i = 0; i < sortedKeys.current.length; i++) {
      if (preferredPosition === sortedKeys.current[i]) {
        positionIndex = i
      }
    }
    // Find closest match
    let closestPosition = -1
    for (const position of sortedKeys.current) {
      if (
        closestPosition === -1 ||
        Math.abs(sortedKeys.current.indexOf(position) - positionIndex) < Math.abs(sortedKeys.current.indexOf(position) - closestPosition)
      ) {
        let isAvailable = true
        for (const occupied of occupiedPositions) {
          if (occupied === position) {
            isAvailable = false
            break
          }
        }
        if (isAvailable) {
          closestPosition = sortedKeys.current.indexOf(position)
        }
      }
    }
    // Add player to pitch
    positionPlayer(player, sortedKeys.current[closestPosition], `Player${player.id}`)
  }

  const findClosestMainPosition = (preferredPosition) => {
    switch (preferredPosition) {
      case '2': {
        preferredPosition = sortedKeys.current[1]
        break
      }
      case '3': {
        preferredPosition = sortedKeys.current[Math.floor(sortedKeys.current.length / 2)]
        break
      }
      case '4': {
        preferredPosition = sortedKeys.current[sortedKeys.current.length - 1]
        break
      }
      default: {
        break
      }
    }
    findClosestPosition(preferredPosition)
  }

  return (
    <div
      id={`${styles.playerCard} ${player.id}`}
      className={`${styles.playerCard} Player${player.id} PlayerCard ${styles.notScrollable}`}
      key={player.id}
      playerid={player.id}
    >
      <div
        className={`${styles.portrait} Portrait`}
        style={{
          backgroundImage: `url(${player.photo ? player.photo : noPhoto})`,
          backgroundColor: '#C9D1D9',
          border: 'solid 2px #FFFFFF',
        }}
        onDragStart={e => { e.preventDefault() }}
      />
      <p className='PlayerCardName'>{player.number === '0' ? '' : `${player.number}.`}{player.name}</p>
    </div>
  )
}

export default PlayerCard
