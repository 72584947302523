import React from 'react'
import PropTypes from 'prop-types'
import { LinkContainer } from 'react-router-bootstrap'
import classNames from 'classnames'
import { ReactComponent as Arrow } from '../../../assets/arrow.svg'

import styles from './MatchListItem.module.scss'

const MatchListItem = ({ type, id, title, date, result, time }) => {
  return (
    <LinkContainer to={`/match/${id}/home`}>
      <div className={styles.matchListItemWrapper}>
        <div className={styles.nameAndDate}>
          <p className={styles[type]}>{title}</p>
          <p>{date}</p>
        </div>
        <div className={styles.results}>
          {result && (
            <p className={classNames(styles.goals, styles[type])}>{result}</p>
          )}
          {time && <p className={styles.time}>{time}</p>}
        </div>
        <button className={styles.arrow}>
          <Arrow />
        </button>
      </div>
    </LinkContainer>
  )
}

MatchListItem.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  date: PropTypes.string,
  result: PropTypes.string,
  time: PropTypes.string,
}

export default MatchListItem
