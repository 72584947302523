import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import moment from 'moment'
import ContentLayout from '../../components/ContentLayout'
import MatchList from './MatchList'

import { loadingActions } from '../../actions/loadingActions'
import { notificationsActions } from '../../actions/notificationsActions'

import { match } from '../../services/SearchServices'
import { match as matchHistory } from '../../services/historyService'

import style from './Homepage.module.scss'
import { authActions } from '../../actions/authActions'

const Homepage = () => {
  const { formatMessage: f } = useIntl()
  const [matches, setMatches] = useState([])
  const [history, setHistory] = useState([])
  const dispatch = useDispatch()

  const mapMatchesFuture = (match) => {
    let time
    const timeInDays = moment(match.data_jogo).diff(moment(), 'days')
    if (timeInDays === 0) {
      const timeInHours = moment(match.data_jogo).diff(moment(), 'hours')

      if (timeInHours === 0) {
        const timeInMinutes = moment(match.data_jogo).diff(moment(), 'minutes')
        time =
          timeInMinutes +
          ' ' +
          f({ id: 'homepage.minutes' }) +
          (timeInMinutes > 1 ? 's' : '')
      } else {
        time =
          timeInHours +
          ' ' +
          f({ id: 'homepage.hours' }) +
          (timeInHours > 1 ? 's' : '')
      }
    } else {
      time =
        timeInDays +
        ' ' +
        f({ id: 'homepage.days' }) +
        (timeInDays > 1 ? 's' : '')
    }

    return {
      id: match.jogo_id,
      title: `${match.equipa_casa_descr} - ${match.equipa_fora_descr}`,
      date: `${match.data_jogo}`,
      time: time,
    }
  }

  const mapMatchesHistory = (match) => {
    let result
    if (match.estado === 'Terminado') {
      result = `${!match.golos_casa ? '0' : match.golos_casa}-${
        !match.golos_fora ? '0' : match.golos_fora
      }`
    } else {
      result = `${!match.golos_casa_live ? '0' : match.golos_casa_live}-${
        !match.golos_fora_live ? '0' : match.golos_fora_live
      }`
    }

    return {
      id: match.jogo_id,
      title: `${match.equipa_casa_descr} - ${match.equipa_fora_descr}`,
      date: `${match.data_jogo}`,
      result: result,
    }
  }

  useEffect(() => {
    const dismissNotification = (id) => {
      dispatch(notificationsActions.removeNotification(id))
    }

    function sortMatches(a, b) {
      if (a.data_jogo < b.data_jogo) {
        return -1
      }
      if (a.data_jogo > b.data_jogo) {
        return 1
      }
      return 0
    }

    match
      .matches()
      .then((data) => {
        dispatch(loadingActions.removeRequest())
        setMatches(
          data.Futebol.concat( // 1
            data['Futebol de Praia'], // 4
            data['Hóquei em Patins'], // 8
            data.Andebol, // 9
            data.Futsal, // 3
            data.Basquetebol, // 10
            data.Voleibol, // 11
          ).sort(sortMatches),
        )
      })
      .catch((error) => {
        if ("cause" in error && error["cause"] === 401) {
          dispatch(authActions.logout())
          return
        }
        const id = `${Date.now() + Math.random()}`
        dispatch(
          notificationsActions.addNotification(
            id,
            'error',
            typeof error === 'object' ? 'An error has ocorred' : error,
            'error',
          ),
        )
        setTimeout(dismissNotification, 4000, id)
        dispatch(loadingActions.removeRequest())
        const localEvents = localStorage.getItem(
          'search-' + localStorage.getItem('zos-cookie'),
        )

        if (localEvents) {
          setMatches(JSON.parse(localEvents).Futebol)
        }
      })

    dispatch(loadingActions.addRequest())

    matchHistory
      .matches()
      .then((data) => {
        dispatch(loadingActions.removeRequest())
        setHistory(
          data.Futebol.concat(
            data['Futebol de Praia'],
            data['Hóquei em Patins'],
            data.Futsal,
            data.Andebol,
            data.Basquetebol,
            data.Voleibol,
          ),
        )
      })
      .catch((error) => {
        if (error?.response?.data.error_code === 401) {
          dispatch(authActions.logout())
          return
        }
        const id = `${Date.now() + Math.random()}`
        dispatch(
          notificationsActions.addNotification(id, 'error', error, 'error'),
        )
        setTimeout(dismissNotification, 4000, id)
        dispatch(loadingActions.removeRequest())
        const localEvents = localStorage.getItem(
          'history-' + localStorage.getItem('zos-cookie'),
        )

        if (localEvents) {
          setMatches(JSON.parse(localEvents).Futebol)
        }
      })
    // eslint-disable-next-line
  }, [])

  return (
    <ContentLayout>
      <div className={style.header}>
        <p>{f({ id: 'homepage.matches' })}</p>
      </div>
      <div className={style.mainContentWrapper}>
        <MatchList
          type="ongoing"
          title={f({ id: 'homepage.ongoing' })}
          matches={matches
            .filter((match) => match.a_decorrer === '1')
            .slice(0, 10)
            .map((match) => ({
              id: match.jogo_id,
              title: `${match.equipa_casa_descr} - ${match.equipa_fora_descr}`,
              date: `${match.data_jogo}`,
              result: `${
                !match.golos_casa_live ? '0' : match.golos_casa_live
              }-${!match.golos_fora_live ? '0' : match.golos_fora_live}`,
              time: `${match.minuto}'`,
            }))}
        />
        <MatchList
          type="other"
          title={f({ id: 'homepage.future' })}
          matches={matches
            .filter(
              (match) =>
                moment(match.data_jogo).diff(moment(), 'days') >= 0 &&
                moment(match.data_jogo).diff(moment(), 'hours') >= 0 &&
                moment(match.data_jogo).diff(moment(), 'minutes') >= 0,
            )
            .slice(0, 10)
            .map((match) => mapMatchesFuture(match))}
        />
        <MatchList
          type="other"
          title={f({ id: 'homepage.history' })}
          matches={history
            .slice(0, 10)
            .map((match) => mapMatchesHistory(match))}
        />
      </div>
    </ContentLayout>
  )
}

export default Homepage
