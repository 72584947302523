import { matchSetupService } from '../services/matchSetupService'

import { actionUtils } from '../helpers/actionUtils'

import { teamTypeConstants } from '../components/Team'

export const matchSetupConstants = {
  MATCH_SETUP_SET_MATCH_ID: 'MATCH_SETUP_SET_MATCH_ID',
  // Request Home Team

  MATCH_SETUP_REQUEST_HOME_TEAM: 'MATCH_SETUP_REQUEST_HOME_TEAM',
  MATCH_SETUP_REQUEST_HOME_TEAM_FAILURE:
    'MATCH_SETUP_REQUEST_HOME_TEAM_FAILURE',
  MATCH_SETUP_REQUEST_HOME_TEAM_SUCCESS:
    'MATCH_SETUP_REQUEST_HOME_TEAM_SUCCESS',

  // Request Away Team
  MATCH_SETUP_REQUEST_AWAY_TEAM: 'MATCH_SETUP_REQUEST_AWAY_TEAM',
  MATCH_SETUP_REQUEST_AWAY_TEAM_FAILURE:
    'MATCH_SETUP_REQUEST_AWAY_TEAM_FAILURE',
  MATCH_SETUP_REQUEST_AWAY_TEAM_SUCCESS:
    'MATCH_SETUP_REQUEST_AWAY_TEAM_SUCCESS',

  // Request Info
  MATCH_SETUP_REQUEST_INFO: 'MATCH_SETUP_REQUEST_INFO',
  MATCH_SETUP_REQUEST_INFO_FAILURE: 'MATCH_SETUP_REQUEST_INFO_FAILURE',
  MATCH_SETUP_REQUEST_INFO_SUCCESS: 'MATCH_SETUP_REQUEST_INFO_SUCCESS',

  // Update Home Team
  MATCH_SETUP_UPDATE_HOME_TEAM: 'MATCH_SETUP_UPDATE_HOME_TEAM',

  // Update Away Team
  MATCH_SETUP_UPDATE_AWAY_TEAM: 'MATCH_SETUP_UPDATE_AWAY_TEAM',

  // Update Home Player
  MATCH_SETUP_UPDATE_HOME_PLAYER: 'MATCH_SETUP_UPDATE_HOME_PLAYER',

  // Update Away Player
  MATCH_SETUP_UPDATE_AWAY_PLAYER: 'MATCH_SETUP_UPDATE_AWAY_PLAYER',

  // Send teams
  MATCH_SETUP_SEND_TEAMS: 'MATCH_SETUP_SEND_TEAMS',
  MATCH_SETUP_SEND_TEAMS_COMMIT: 'MATCH_SETUP_SEND_TEAMS_COMMIT',
  MATCH_SETUP_SEND_TEAMS_ROLLBACK: 'MATCH_SETUP_SEND_TEAMS_ROLLBACK',

  // Reset
  MATCH_SETUP_RESET: 'MATCH_SETUP_RESET',
}

const setMatchId = (matchId) => {
  return {
    type: matchSetupConstants.MATCH_SETUP_SET_MATCH_ID,
    payload: matchId,
  }
}

const requestInfo = (matchId) => {
  return actionUtils.customAsyncRequest(
    matchSetupService.fetchMatch,
    matchSetupConstants.MATCH_SETUP_REQUEST_INFO,
    matchSetupConstants.MATCH_SETUP_REQUEST_INFO_SUCCESS,
    matchSetupConstants.MATCH_SETUP_REQUEST_INFO_FAILURE,
    'currentMatch',
    [matchId],
  )
}

const requestTeam = (type, teamId, matchId) => {
  return actionUtils.customAsyncRequest(
    matchSetupService.fetchTeam,
    type === teamTypeConstants.HOME
      ? matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM
      : matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM,
    type === teamTypeConstants.HOME
      ? matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM_SUCCESS
      : matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM_SUCCESS,
    type === teamTypeConstants.HOME
      ? matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM_FAILURE
      : matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM_FAILURE,
    type,
    [teamId], [matchId],
  )
}

const updateTeam = (type, team) => {
  return {
    type:
      type === teamTypeConstants.HOME
        ? matchSetupConstants.MATCH_SETUP_UPDATE_HOME_TEAM
        : matchSetupConstants.MATCH_SETUP_UPDATE_AWAY_TEAM,
    payload: team,
  }
}

const updatePlayer = (type, player) => {
  return {
    type:
      type === teamTypeConstants.HOME
        ? matchSetupConstants.MATCH_SETUP_UPDATE_HOME_PLAYER
        : matchSetupConstants.MATCH_SETUP_UPDATE_AWAY_PLAYER,
    payload: player,
  }
}

const sendTeams = (teams) => {
  /* const { url, method, json, headers } = matchSetupService.prepareSendTeams(
    teams,
  )
  return {
    type: matchSetupConstants.MATCH_SETUP_SEND_TEAMS,
    payload: teams,
    meta: {
      offline: {
        effect: { url, method, json, headers },
        commit: {
          type: matchSetupConstants.MATCH_SETUP_SEND_TEAMS_COMMIT,
          meta: teams,
        },
        rollback: {
          type: matchSetupConstants.MATCH_SETUP_SEND_TEAMS_ROLLBACK,
          meta: teams,
        },
      },
    },
  } */
  return matchSetupService.sendTeams(teams)
}

const sendFormations = (teams) => {
  return matchSetupService.sendFormations(teams)
}

const resetMatch = () => {
  return { type: matchSetupConstants.MATCH_SETUP_RESET }
}

export const matchSetupActions = {
  setMatchId,
  requestInfo,
  requestTeam,
  updateTeam,
  updatePlayer,
  sendTeams,
  sendFormations,
  resetMatch,
}
