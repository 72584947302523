import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'

import LoadingIndicator from '../LoadingIndicator'
import NavigationBar from '../NavigationBar'

import styles from './ContentLayout.module.scss'

const ContentLayout = ({ title, variant, children }) => {
  const { formatMessage: f } = useIntl()
  const loading = useSelector((state) => state.loading)

  return (
    <>
      <Helmet>
        <title>
          {title
            ? `zerozero.live | ${f({ id: `pageTitle.${title}` })}`
            : 'zerozero.live'}
        </title>
        <body className={styles[variant]} />
      </Helmet>
      <NavigationBar />
      {loading > 0 && <LoadingIndicator />}
      <div className={styles.mainContentWrapper}>{children}</div>
    </>
  )
}

ContentLayout.defaultProps = {
  title: null,
  variant: 'light',
  children: null,
}

ContentLayout.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
}

export default ContentLayout
