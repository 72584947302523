import React from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

const CatalogGoalForm = ({ isPen, isGoalEvent, origin = '0', zone = '0', bodyPart = '0', playerAssist = '0', options, assistOptions, onChangeOrigin, onChangeZone, onChangeBodyPart, onChangePlayerAssist, noClock }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Form className='p-2'>
      <fieldset>
        {!isPen && (
          <>
            {isGoalEvent && (
              <Form.Group>
                <Form.Label htmlFor='playerAssist'>{f({ id: 'catalogGoals.playerAssist' })}</Form.Label>
                <Form.Control as="select" id="playerAssist" value={playerAssist} onChange={onChangePlayerAssist}>
                  <option key={'0'} value={'0'}></option>
                  {assistOptions && assistOptions.map((item) =>
                    <option key={item.id} value={item.id}>{item.number !== '0' ? item.number + '. ' : ''}{item.player_name}</option>,
                  )}
                </Form.Control>
              </Form.Group>
            )}
            {!noClock && (
              <>
                <Form.Group>
                  <Form.Label htmlFor='goalOrigin'>{f({ id: 'catalogGoals.origin' })}</Form.Label>
                  <Form.Control as="select" id="goalOrigin" value={origin} onChange={onChangeOrigin}>
                    <option key={'0'} value={'0'}></option>
                    {options.origem && options.origem.map((item) =>
                      <option key={item.id} value={item.id}>{item.descr}</option>,
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor='goalZone'>{f({ id: 'catalogGoals.zone' })}</Form.Label>
                  <Form.Control as="select" id="goalZone" value={zone} onChange={onChangeZone}>
                    <option key={'0'} value={'0'}></option>
                    {options.zona && options.zona.map((item) =>
                      <option key={item.id} value={item.id}>{item.descr}</option>,
                    )}
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </>
        )}
        {!noClock && (
          <Form.Group>
            <Form.Label htmlFor='goalBodyPart'>{f({ id: 'catalogGoals.bodyPart' })}</Form.Label>
            <Form.Control as="select" id="goalBodyPart" value={bodyPart} onChange={onChangeBodyPart}>
              <option key={'0'} value={'0'}></option>
              {options.corpo && options.corpo.map((item) =>
                <option key={item.id} value={item.id}>{item.descr}</option>,
              )}
            </Form.Control>
          </Form.Group>
        )}
      </fieldset>
    </Form>
  )
}

CatalogGoalForm.propTypes = {
  isPen: PropTypes.bool.isRequired,
  isGoalEvent: PropTypes.bool.isRequired,
  origin: PropTypes.string,
  zone: PropTypes.string,
  bodyPart: PropTypes.string,
  playerAssist: PropTypes.string,
  options: PropTypes.object.isRequired,
  assistOptions: PropTypes.array.isRequired,
  onChangeOrigin: PropTypes.func.isRequired,
  onChangeZone: PropTypes.func.isRequired,
  onChangeBodyPart: PropTypes.func.isRequired,
  noClock: PropTypes.bool.isRequired,
}

export default CatalogGoalForm
