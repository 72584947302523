import { loadingConstants } from '../actions/loadingActions'

const initialState = 0

const loading = (state = initialState, action) => {
  switch (action.type) {
    case loadingConstants.ADD_REQUEST:
      return state + 1
    case loadingConstants.REMOVE_REQUEST:
      return state > 0 ? state - 1 : 0
    default:
      return state
  }
}

export default loading
