import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Container, Form, Modal, Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
//import { useSelector } from 'react-redux'


import Event from './Event'
import ClockInput from './ClockInput'
import styles from './Events.module.scss'

import no_photo from '../../assets/lineups/no_photo.png'
import timeIcon from '../../assets/eventsIcons/time.svg'
import flagIcon from '../../assets/eventsIcons/events.svg'
import leftIcon from '../../assets/eventsIcons/left.svg'
import PhotoUploader from '../PhotoUploader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { eventsActions } from '../../actions/eventsActions'
import { useDispatch } from 'react-redux'
import UniformEvent from './UniformEvent'

const ALL_EVENTS = -1
const FAVOURITE_EVENTS = -2

/*const EVENT_FORCED_MINUTES = {
  9: [1], // Apito Inicial
  11: [46], // Inicio 2º Parte
  14: [91], // Inicio 1º Parte Prol
  57: [106], // Inicio 2º Parte Prol
}*/

const validUserIds = [418137, 461573]
const Events = ({
  events,
  setEvents,
  eventsCategories,
  matchInfo,
  homeCoach,
  awayCoach,
  eventInfo,
  setEventInfo,
  setCommentBox,
  isVodafoneEnabled,
  halfTimes,
  halfs,
  handleCloseForm,
  uploaderEventsIds,
  setThumbnail
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const eventForcedMinutes = {
    9: 1,
    11: matchInfo.duracao_int + 1,
    14: (2 * matchInfo.duracao_int) + 1,
    57: (2 * matchInfo.duracao_int) + matchInfo.duracao_prolong + 1
  }
  const event = eventInfo.selectedEvent
    ? events.find((element) => parseInt(element.id) === eventInfo.selectedEvent)
    : null
  const isPlayMaker = event ? event.id === '37' || event.id === '45' : false
  const needsPlayer = event ? event.texto.includes('{jog1}') : null
  const needsPlayerOut = event ? event.texto.includes('{jog2}') : null
  const needsTeam = event ? needsPlayer || event.texto.includes('{eq') : null
  const needsUniform = event ? event.texto.includes('{uniform}') : null
  const [disabledPlayerSelected, setDisabledPlayerSelected] = useState(null);
  let homeTeamSelected = eventInfo.selectedTeam === parseInt(matchInfo.homeID)
  let awayTeamSelected = eventInfo.selectedTeam === parseInt(matchInfo.awayID)

  if (event && event.texto.includes('{eq2}')) {
    homeTeamSelected = !homeTeamSelected
    awayTeamSelected = !awayTeamSelected
  }

  let players = homeTeamSelected ? matchInfo.homePlayers : null
  players = players || (awayTeamSelected ? matchInfo.awayPlayers : null)
  let coach = homeTeamSelected ? homeCoach : null
  coach = coach || (awayTeamSelected ? awayCoach : null)

  const [showEvents, setShowEvents] = useState(true)
  const favouriteEvents = events.filter((event) => event.fav === "1")
  const [selectedCategory, setSelectedCategory] = useState(favouriteEvents.length > 0 ? FAVOURITE_EVENTS : ALL_EVENTS)
  const [clockHalf] = useState(
    matchInfo.estadoDesc === "fin"
      ?
      4
      : eventInfo.minute > halfs[2]
        ? 4
        : eventInfo.minute > halfs[1]
          ? 3
          : eventInfo.minute > halfs[0]
            ? 2
            : 1,
  )

  let label

  if (!event || (event && !needsTeam)) {
    label =
      f({ id: 'events.category' }) +
      ' ' +
      (selectedCategory === ALL_EVENTS
        ? f({ id: 'events.categoryAll' })
        : (selectedCategory === FAVOURITE_EVENTS ? f({ id: 'events.categoryFavourites' }) : eventsCategories.find((elem) => elem.id === selectedCategory).descr))
  } else {
    const labels = event.label.split(';')

    if (needsTeam && !eventInfo.selectedTeam) {
      label = labels[0]
    } else if (needsPlayerOut && !eventInfo.selectedPlayerOut) {
      label = labels[1]
    } else if (needsPlayer && !eventInfo.selectedPlayer) {
      needsPlayerOut ? (label = labels[2]) : (label = labels[1])
    } else {
      label =
        labels[labels.length - 1] === ''
          ? labels[labels.length - 2]
          : labels[labels.length - 1]
    }
  }

  useEffect(() => {
    if (eventForcedMinutes.hasOwnProperty(event?.id)) {
      setEventInfo(eventInfo => ({ ...eventInfo, minuteExtra: 0, minute: eventForcedMinutes[event.id], forceMinute: eventForcedMinutes[event.id] }))
    } else {
      setEventInfo(eventInfo => ({ ...eventInfo, forceMinute: null }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const displayPlayersOrder = (playersIn, playersOut, clickEvent, disabledFunction) => {
    if (matchInfo.allplayers_enable) {
      const players = playersIn.concat(playersOut).sort(function comparePlayers(p1, p2) {
        // In futsal - first goalkeepers
        if (p1.posicao !== p2.posicao && (p1.posicao === "52" || p2.posicao === "52")) {
          return parseInt(p1.posicao) - parseInt(p2.posicao)
        }
        // Order by number
        return parseInt(p1.number) - parseInt(p2.number)
      })
      return players.map((player, i) => {
        const selected = eventInfo.selectedPlayer === parseInt(player.id)
        return (
          <Event
            key={i}
            id={parseInt(player.id)}
            name={(player.number !== '0' ? player.number + '. ' : '') + player.player_name}
            icon={player.icon ? player.icon : no_photo}
            clickEvent={clickEvent}
            selected={selected}
          />
        )
      })
    }
    else {
      return (
        <>
          {
            playersIn.map((player, i) => {
              const selected = eventInfo.selectedPlayer === parseInt(player.id)
              return (
                <Event
                  key={i}
                  id={parseInt(player.id)}
                  name={(player.number !== '0' ? player.number + '. ' : '') + player.player_name}
                  icon={player.icon ? player.icon : no_photo}
                  clickEvent={clickEvent}
                  selected={selected}
                />
              )
            })
          }
          {
            playersOut.map((player, i) => {
              const selected = eventInfo.selectedPlayer === parseInt(player.id)
              return (
                <Event
                  key={i}
                  id={parseInt(player.id)}
                  name={(player.number !== '0' ? player.number + '. ' : '') + player.player_name}
                  icon={player.icon ? player.icon : no_photo}
                  clickEvent={clickEvent}
                  selected={selected}
                  disabledFunction={disabledFunction}
                  disabled={(!(needsPlayerOut && player.can_enter) && event.categoria !== "2")}
                />
              )
            })
          }
        </>
      )
    }
  }

  const displayPlayers = (playerOut) => {
    const clickEvent = (player, is_coach = false) => {
      if (playerOut) {
        setEventInfo({ ...eventInfo, selectedPlayerOut: player });
      }
      else setEventInfo({ ...eventInfo, selectedPlayer: player, hasSelectedCoach: is_coach });
    }
    const disabledFunction = (player) => {
      setShowModal(true);
      setDisabledPlayerSelected(player);
    }
    let playersOut = players.filter(player => player.out);
    let playersIn = players.filter(player => !player.out);

    if (playerOut) {
      playersOut = [];
    } else if (needsPlayerOut) {
      playersIn = [];
    }

    //se a categoria for golos
    /*if(event.categoria === "1"){
      players = players.filter(player => !player.out)
    }*/

    if (playersIn && playersOut) {
      const coachSelected = coach && eventInfo.selectedPlayer === parseInt(coach.id)
      return (
        <>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{f({ id: 'events.disabledPlayer' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{f({ id: 'events.disabledPlayerCannot' })}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                {f({ id: 'match.edit.cancel' })}
              </Button>
              <Button variant="primary" onClick={() => {
                setShowModal(false);
                clickEvent(disabledPlayerSelected);
              }}>
                {f({ id: 'match.edit.save' })}
              </Button>
            </Modal.Footer>
          </Modal>
          <Event
            key={'unknown player'}
            id={0}
            name={'Jogador Desconhecido'}
            icon={no_photo}
            clickEvent={clickEvent}
            selected={playerOut ? eventInfo.selectedPlayerOut === 0 : eventInfo.selectedPlayer === 0}
          />
          {displayPlayersOrder(playersIn, playersOut, clickEvent, disabledFunction)}
          {coach && (event.categoria === '2' || event.categoria === '8') && (
            <Event
              id={parseInt(coach.id)}
              name={`${f({ id: "team.coachTitle" })}. ${coach.player_name}`}
              icon={coach.icon ? coach.icon : no_photo}
              clickEvent={(player) => clickEvent(player, true)}
              selected={coachSelected}
              disabledFunction={disabledFunction}
              disabled={false}
            />
          )}
        </>
      )
    }

    return <></>
  }

  const displayTeams = (
    <>
      <Event
        id={parseInt(matchInfo.homeID)}
        name={matchInfo.homeTeam}
        icon={matchInfo.homeIcon}
        clickEvent={(team) =>
          setEventInfo({ ...eventInfo, selectedTeam: team })
        }
        selected={eventInfo.selectedTeam === parseInt(matchInfo.homeID)}
      />
      <Event
        id={parseInt(matchInfo.awayID)}
        name={matchInfo.awayTeam}
        icon={matchInfo.awayIcon}
        clickEvent={(team) =>
          setEventInfo({ ...eventInfo, selectedTeam: team })
        }
        selected={eventInfo.selectedTeam === parseInt(matchInfo.awayID)}
      />
    </>
  )

  const displayUniforms = (type) => {
    if (matchInfo[type + 'Shirts']) {
      let entries = Object.entries(matchInfo[type + 'Shirts']);
      return (
        entries && entries.map((uniform, index) => {
          return (
            <UniformEvent
              id={uniform[0]}
              key={uniform[0]}
              name={uniform[0].toString()}
              icon={uniform[1]}
              clickEvent={(id) =>
                setEventInfo({ ...eventInfo, selectedUniformId: id})
              }
              selected={eventInfo.selectedUniformId === uniform[0]}
            />
          )
        }));
    }

    return <></>;
  }

  const categoryEvents = () => {
    if (selectedCategory === ALL_EVENTS) {
      return events
    }
    if (selectedCategory === FAVOURITE_EVENTS) {
      return favouriteEvents
    }

    return events.filter((event) => event.categoria === selectedCategory)
  }

  const onClickFavourite = (eventId, isFav) => {
    dispatch(eventsActions.postFavouriteEvent({ event_id: eventId }, !isFav, null))
    setEvents((old) => old.map((event) => {
      if (parseInt(event.id) === eventId) {
        return { ...event, fav: isFav ? '0' : '1' }
      }
      return event
    }))
  }

  const displayEvents = categoryEvents().map((event, i) => {
    const userId = localStorage.getItem('user-id')
    if (parseInt(event.id) !== uploaderEventsIds[0] || validUserIds.includes(parseInt(userId)))
      return (
        <Event
          key={i}
          id={parseInt(event.id)}
          name={event.abrev}
          icon={'https://www.zerozero.pt/images/mc/live/' + event.img_path}
          clickEvent={(event) =>
            setEventInfo({ ...eventInfo, selectedEvent: event })
          }
          selected={eventInfo.selectedEvent === parseInt(event.id)}
          isFav={event.fav === '1'}
          onClickFav={onClickFavourite}
          star
        />
      )
    return ''
  })

  const displayHeader = () => {
    return (
      <Row className={styles.iconsRow}>
        {displayEventsButton()}
        {showEvents ? (
          (needsTeam || isPlayMaker) && event ? (
            displayBreadcrumbs()
          ) : (
            displayCategories()
          )
        ) : (
          <></>
        )}
      </Row>
    )
  }

  const goBack = () => {
    if (eventInfo.selectedPlayer) {
      setEventInfo({ ...eventInfo, selectedPlayer: null, hasSelectedCoach: false })
    } else if (eventInfo.selectedPlayerOut) {
      setEventInfo({ ...eventInfo, selectedPlayerOut: null })
    } else if (eventInfo.selectedTeam) {
      setEventInfo({ ...eventInfo, selectedTeam: null })
    } else if (eventInfo.selectedEvent) {
      setEventInfo({ ...eventInfo, selectedEvent: null })
      setCommentBox('')
    }
  }

  const displayEventsButton = () => {
    return showEvents && (needsTeam || isPlayMaker) && event ? (
      <img
        src={leftIcon}
        alt={f({ id: 'goBack' })}
        onClick={() => goBack()}
      ></img>
    ) : (
      <>
        {favouriteEvents.length > 0 && (
          <FontAwesomeIcon
            icon={faStar}
            size='lg'
            className={
              `${styles.favouritesImg} ${styles.categoryImg} ${showEvents && selectedCategory === FAVOURITE_EVENTS ? '' : styles.hidden}`
            }
            onClick={() => {
              setShowEvents(true)
              setSelectedCategory(FAVOURITE_EVENTS)
            }}
          />
        )}
        <img
          src={flagIcon}
          alt={f({ id: 'events.event' })}
          className={
            showEvents && selectedCategory === ALL_EVENTS ? '' : styles.hidden
          }
          onClick={() => {
            setShowEvents(true)
            setSelectedCategory(ALL_EVENTS)
          }}
        ></img>
      </>
    )
  }

  const displayCategories = () => {
    return eventsCategories.map((category, i) => {
      if (category.events.length > 0)
        return (
          <img
            key={i}
            src={
              'https://www.zerozero.pt/img/tpevent_categoria/' + category.img_path
            }
            alt={category.descr}
            className={
              styles.categoryImg +
              ' ' +
              (selectedCategory === category.id ? '' : styles.hidden)
            }
            onClick={() => {
              setSelectedCategory(category.id)
            }}
          ></img>
        )
      return ''
    })
  }

  const getSelectedPlayer = () => {
    const isCoach = coach && parseInt(coach.id) === eventInfo.selectedPlayer
    return isCoach ? coach : players.find(
      (player) =>
        parseInt(player.id) === eventInfo.selectedPlayer,
    )
  }

  const displayBreadcrumbs = () => {
    const fakeHomeTeamSelected =
      event && event.texto.includes('{eq2}')
        ? awayTeamSelected
        : homeTeamSelected

    return (
      <div className={styles.breadCrumbs}>
        <div className={styles.fade} />
        <div className={styles.inner}>
          {event ? (
            <div
              className={styles.breadCrumb}
              onClick={() => {
                setEventInfo({
                  ...eventInfo,
                  selectedEvent: null,
                  selectedTeam: null,
                  selectedPlayer: null,
                  selectedPlayerOut: null,
                  hasSelectedCoach: false,
                })
                setCommentBox('')
              }
              }
            >
              <span>{event.abrev}</span>
            </div>
          ) : (
            <></>
          )}
          {eventInfo.selectedTeam ? (
            fakeHomeTeamSelected ? (
              <div
                className={styles.breadCrumb}
                onClick={() =>
                  setEventInfo({
                    ...eventInfo,
                    selectedTeam: null,
                    selectedPlayer: null,
                    selectedPlayerOut: null,
                    hasSelectedCoach: false,
                  })
                }
              >
                <span>{matchInfo.homeTeam}</span>
              </div>
            ) : (
              <div
                className={styles.breadCrumb}
                onClick={() =>
                  setEventInfo({
                    ...eventInfo,
                    selectedTeam: null,
                    selectedPlayer: null,
                    selectedPlayerOut: null,
                    hasSelectedCoach: false,
                  })
                }
              >
                <span>{matchInfo.awayTeam}</span>
              </div>
            )
          ) : (
            <></>
          )}
          {eventInfo.selectedPlayerOut ? (
            <div
              className={styles.breadCrumb}
              onClick={() =>
                setEventInfo({
                  ...eventInfo,
                  selectedPlayer: null,
                  selectedPlayerOut: null,
                  hasSelectedCoach: false,
                })
              }
            >
              <span>
                {
                  players.find(
                    (player) =>
                      parseInt(player.id) === eventInfo.selectedPlayerOut,
                  ).player_name
                }
              </span>
            </div>
          ) : (
            <></>
          )}
          {eventInfo.selectedPlayer ? (
            <div
              className={styles.breadCrumb}
              onClick={() =>
                setEventInfo({
                  ...eventInfo,
                  selectedPlayer: null,
                  hasSelectedCoach: false,
                })
              }
            >
              <span>
                {
                  getSelectedPlayer().player_name
                }
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }

  const handleVodafoneMinuteChange = (e) => {
    const vodafoneTimestampMinute = String(e.target.value)
    setEventInfo(info => ({ ...info, vodafoneTimestampMinute }))
  }

  const handleVodafoneSecondChange = (e) => {
    const vodafoneTimestampSecond = String(e.target.value)
    setEventInfo(info => ({ ...info, vodafoneTimestampSecond }))
  }

  const displayVodafoneTimestampInput = (
    <div
      className={(showEvents ? styles.hidden : '')}
    >
      <input
        type="number"
        pattern="[0-9]*"
        onChange={handleVodafoneMinuteChange}
        value={matchInfo.vodafoneTimestampMinute}
        placeholder="Minuto"
      />
      {':'}
      <input
        type="number"
        pattern="[0-9]*"
        onChange={handleVodafoneSecondChange}
        value={matchInfo.vodafoneTimestampSecond}
        placeholder="Segundo"
      />
    </div>
  )

  label = isVodafoneEnabled && eventInfo.selectedEvent && !needsUniform &&
    ((needsTeam && eventInfo.selectedTeam) &&
      (!needsPlayer || (needsPlayer && eventInfo.selectedPlayer)) &&
      (!needsPlayerOut || (needsPlayerOut && eventInfo.selectedPlayer)))
    ? f({ id: 'events.vodafoneTimestampStepLabel' })
    : label

  const handleClockRegular = (newMinutes) => {
    const limits = halfTimes[clockHalf - 1]
    let time = parseInt(newMinutes) || 0
    let extra = 0

    if (time > limits.end) {
      extra = time - limits.end
      time = limits.end
    }
    else if (time < 0) {
      time = 0
    }

    setEventInfo({ ...eventInfo, minute: time, minuteExtra: extra })
  }

  const displayClock = () => {
    return (
      <div
        className={styles.clockBox}
      >
        <div className={styles.clockIcon} onClick={() => eventInfo.forceMinute ? null : setShowEvents(!showEvents)}>
          <span className={styles.clock}>
            {eventInfo.forceMinute ?
              `${eventInfo.forceMinute}’`
              : eventInfo.minute >= 0 ?
                (eventInfo.minute + (eventInfo.minuteExtra > 0 ? '+' + eventInfo.minuteExtra
                  : '') + '’') : "" + 0 + '’'}
          </span>
          <img
            src={timeIcon}
            alt={f({ id: 'events.clock' })}
          />
        </div>
        {!eventInfo.forceMinute && (
          <>
            <Button size='sm' className={styles.clockButton} onClick={() => handleClockRegular(eventInfo.minuteExtra + eventInfo.minute + 1)}>+</Button>
            <Button size='sm' className={styles.clockButton} onClick={() => handleClockRegular(eventInfo.minuteExtra + eventInfo.minute - 1)}>-</Button>
          </>
        )
        }
      </div >
    )
  }

  return (
    <Container fluid className={styles.eventsContainer}>
      {displayHeader()}
      {showEvents ? (
        <>
          <Row className={styles.categoryDescr}>
            <span>{label}</span>
          </Row>
          {!uploaderEventsIds.includes(eventInfo.selectedEvent) ? (
            <Row className={styles.eventsList}>
              {
                (eventInfo.selectedEvent && isVodafoneEnabled && !needsUniform &&
                  ((needsTeam && eventInfo.selectedTeam) &&
                    (!needsPlayer || (needsPlayer && eventInfo.selectedPlayer !== null)) &&
                    (!needsPlayerOut || (needsPlayerOut && eventInfo.selectedPlayerOut !== null))))
                  ? displayVodafoneTimestampInput
                  : needsPlayerOut
                    ? (eventInfo.selectedPlayerOut !== null)
                      ? displayPlayers(false)
                      : eventInfo.selectedTeam
                        ? displayPlayers(true)
                        : eventInfo.selectedEvent
                          ? displayTeams
                          : displayEvents
                    : eventInfo.selectedTeam && (needsPlayer || isPlayMaker) && !needsUniform
                      ? displayPlayers(false)
                      : eventInfo.selectedTeam && needsUniform
                        ? displayUniforms(awayTeamSelected ? 'away' : 'home')
                        : eventInfo.selectedEvent && (needsTeam || isPlayMaker)
                          ? displayTeams
                          : displayEvents}
            </Row>
          ) : (uploaderEventsIds[1] === eventInfo.selectedEvent ? (
            <PhotoUploader
              matchId={matchInfo.id}
              handleCloseForm={handleCloseForm}
              setPhotoThumbnail={setThumbnail}
            />
          ) : (
            <></>
          ))}
        </>
      ) : (
        <ClockInput
          clock={eventInfo.minute}
          setClock={(minute, extra) => setEventInfo({ ...eventInfo, minute: minute, minuteExtra: extra })}
          extra={eventInfo.minuteExtra}
          clockHalf={clockHalf}
          halfTimes={halfTimes}
        />
      )}
      <div className={styles.ignoreMinuteContainer}>
        <Row className='m-0'>
          <div>
            <Form.Check
              type="checkbox"
              label={f({ id: 'ignoreMinute' })}
              onChange={(e) => setEventInfo(({ ...eventInfo, ignoreMinute: e.target.checked }))}
            />
            {isPlayMaker && (
              <Form.Check
                type="checkbox"
                label={f({ id: 'playMaker.create' })}
                onChange={(e) => setEventInfo(({ ...eventInfo, createPlaymakerStats: e.target.checked }))}
              />
            )}
          </div>
          {showEvents && displayClock()}
        </Row>
      </div>
    </Container>
  )
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
  eventsCategories: PropTypes.array.isRequired,
  matchInfo: PropTypes.object.isRequired,
  homeCoach: PropTypes.object,
  awayCoach: PropTypes.object,
  eventInfo: PropTypes.object.isRequired,
  setEventInfo: PropTypes.func.isRequired,
  setCommentBox: PropTypes.func.isRequired,
  isVodafoneEnabled: PropTypes.bool,
  halfTimes: PropTypes.array.isRequired,
  halfs: PropTypes.array.isRequired
}

export default Events
