import { authService } from '../services/authService'
import { history } from '../helpers/history'
import { localeConstants } from './localeActions'

export const authConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
}

export const authActions = {
  login,
  loginGoogle,
  loginFacebook,
  loginTwitter,
  loginZerozero,
  logout,
}

function request() {
  return { type: authConstants.LOGIN_REQUEST }
}
function success(cookie) {
  return { type: authConstants.LOGIN_SUCCESS, payload: cookie }
}
function failure(error) {
  return { type: authConstants.LOGIN_FAILURE, payload: error }
}
function changeLocale(lang) {
  return { type: localeConstants.LOCALE_CHANGE, locale: lang }
}

function login(username, password, errorMessage) {
  return (dispatch) => {
    dispatch(request({ username }))

    authService.login(username, password).then(
      (res) => {
        const cookie = {
          cookie: res.cookie,
          zz_member: res.zz_member,
        }
        dispatch(success(cookie))
        dispatch(changeLocale(res.lang_key))
        history.push('/')
      },
      (error) => {
        errorMessage('Login error occurred')
        dispatch(failure(error))
      },
    )
  }
}

function loginGoogle(accessToken, errorMessage) {
  return (dispatch) => {
    dispatch(request())

    authService.loginGoogle(accessToken).then(
      (res) => {
        const cookie = {
          cookie: res.cookie,
          zz_member: res.zz_member,
        }
        dispatch(success(cookie))
        dispatch(changeLocale(res.lang_key))
        history.push('/')
      },
      (error) => {
        errorMessage('Login error occurred')
        dispatch(failure(error))
      },
    )
  }
}

function loginFacebook(accessToken, errorMessage) {
  return (dispatch) => {
    dispatch(request())

    authService.loginFacebook(accessToken).then(
      (res) => {
        const cookie = {
          cookie: res.cookie,
          zz_member: res.zz_member,
        }
        dispatch(success(cookie))
        dispatch(changeLocale(res.lang_key))
        history.push('/')
      },
      (error) => {
        errorMessage('Login error occurred')
        dispatch(failure(error))
      },
    )
  }
}

function loginTwitter(oauth_token, oauth_verifier, errorMessage) {
  return (dispatch) => {
    dispatch(request())

    authService.loginTwitter(oauth_token, oauth_verifier).then(
      (res) => {
        const cookie = {
          cookie: res.cookie,
          zz_member: res.zz_member,
        }
        dispatch(success(cookie))
        dispatch(changeLocale(res.lang_key))
        history.push('/')
      },
      (error) => {
        errorMessage('Login error occurred')
        dispatch(failure(error))
      },
    )
  }
}

function loginZerozero(oauth_token, fk_jogo, errorMessage) {
  return (dispatch) => {
    dispatch(request())

    authService.loginZerozero(oauth_token).then(
      (res) => {
        const cookie = {
          cookie: res.cookie,
          zz_member: res.zz_member,
        }
        dispatch(success(cookie))
        dispatch(changeLocale(res.lang_key))
        if (fk_jogo) {
          history.push(`/match/${fk_jogo}/home`)
        } else history.push('/')
      },
      (error) => {
        errorMessage('Login error occurred')
        dispatch(failure(error))
      },
    )
  }
}

function logout() {
  authService.logout()
  history.push('/login')
  return { type: authConstants.LOGOUT }
}
