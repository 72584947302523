import React, { useEffect, useRef } from 'react'
import Pitch from '../Pitch'
import styles from './Lineups.module.scss'
import './normalize.css'
import { Col, Dropdown, Row, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { matchSetupActions } from '../../actions/matchSetupActions'
import Team from '../Team/Team'
import { playerGroupConstants } from '../PlayerGroup'
import { useIntl } from 'react-intl'

function Lineups({
  modality,
  availableTactics,
  activeTacticHome,
  activeTacticAway,
  setActiveTacticHome,
  setActiveTacticAway,
  selectedFormation,
  maxNrPlayers,
  selectedPlayersHome,
  setSelectedPlayersHome,
  selectedPlayersAway,
  setSelectedPlayersAway,
  isHomeFilled,
  isAwayFilled,
  setIsHomeFilled,
  setIsAwayFilled,
  resetFormation,
  homeOccupiedPositions,
  awayOccupiedPositions,
  setHomeOccupiedPositions,
  setAwayOccupiedPositions,
  userHasEditedFormations,
  setUserHasEditedFormations,
  onSave,
}) {
  const dispatch = useDispatch()
  const { info, home, away } = useSelector((state) => state.matchSetup)
  const automaticTacticHome = useRef(true)
  const automaticTacticAway = useRef(true)
  const isHomeTacticSet = useRef(false)
  const isAwayTacticSet = useRef(false)
  const { formatMessage: f } = useIntl()

  const handleChangeTactic = (tactic) => {
    if (selectedFormation === 'home' && activeTacticHome !== tactic) {
      automaticUnselectPlayers(true)
      setActiveTacticHome(tactic)
      clickAutofill(true)
      if (!userHasEditedFormations) setUserHasEditedFormations(true)
    } else if (selectedFormation === 'away' && activeTacticAway !== tactic) {
      automaticUnselectPlayers(true)
      setActiveTacticAway(tactic)
      clickAutofill(true)
      if (!userHasEditedFormations) setUserHasEditedFormations(true)
    }
  }

  const unselectPlayer = (playerObject) => {
    playerObject.selected = false
    playerObject.positioned = false
    playerObject.positionedTactic = false
    dispatch(matchSetupActions.updatePlayer(selectedFormation, playerObject))
    const setFunc = selectedFormation === 'home' ? setSelectedPlayersHome : setSelectedPlayersAway
    setFunc((current) =>
      current.filter(element => {
        return element.id !== playerObject.id
      }),
    )
  }

  const selectPlayer = (playerObject) => {
    playerObject.selected = true
    playerObject.positionedTactic = false
    dispatch(matchSetupActions.updatePlayer(selectedFormation, playerObject))
    const setFunc = selectedFormation === 'home' ? setSelectedPlayersHome : setSelectedPlayersAway
    setFunc((oldplayers) => [...oldplayers, playerObject])
  }

  function automaticSelectPlayers(changedTactic = false) {
    let updatedPlayers = home.updatedPlayers
    let isTacticSet = isHomeTacticSet.current
    let playersList = home.main
    let setIsFilled = setIsHomeFilled

    if (selectedFormation === 'away') {
      updatedPlayers = away.updatedPlayers
      isTacticSet = isAwayTacticSet.current
      playersList = away.main
      setIsFilled = setIsAwayFilled
    }

    if (updatedPlayers && isTacticSet) {
      for (let i = 0; i < playersList.length; i++) {
        if (playersList[i].selected) continue
        // Synchronously select players
        if ((changedTactic && playersList[i].positionedTactic) || !changedTactic) {
          setTimeout(() => {
            selectPlayer(playersList[i])
          })
        }
      }
      setIsFilled(true)
    }
  }

  function automaticUnselectPlayers(changedTactic = false) {
    let playersList = home.main
    let setSelectedPlayers = setSelectedPlayersHome
    let setOccupiedPositions = setHomeOccupiedPositions

    if (selectedFormation === 'away') {
      playersList = away.main
      setSelectedPlayers = setSelectedPlayersAway
      setOccupiedPositions = setAwayOccupiedPositions
    }

    for (let i = 0; i < playersList.length; i++) {
      const playerObject = playersList[i]
      if (playerObject.selected || playerObject.positioned) {
        playerObject.selected = false
        playerObject.positioned = false
        playerObject.positionedTactic = changedTactic
        dispatch(matchSetupActions.updatePlayer(selectedFormation, playerObject))
      }
    }
    setSelectedPlayers([])
    setOccupiedPositions([])
  }

  function automaticSetFootballTactic(playersList, setActiveTactic, isTacticSet, automatic, updatedPlayers) {
    if (automatic.current && updatedPlayers && playersList.length > 0) {
      let hasDefendingMiedfielder = false
      let defenders = 0
      let attackers = 0
      const midfielderPlayers = []

      if (playersList.find((player) => player.subposition_id === '40')) { hasDefendingMiedfielder = true }

      playersList.forEach(player => {
        // Main positions
        switch (player.position_id) {
          case '2':
            defenders++
            break
          case '3':
            midfielderPlayers.push(player)
            break
          case '4':
            attackers++
            break
          case '1':
          default:
            break
        }

        // Sub positions
        switch (player.subposition_id) {
          case '57':
            if (hasDefendingMiedfielder) {
              player.subposition_id = '58'
            }
            break
          default:
            break
        }
      })
      const tactics = Object.keys(availableTactics)
      // Filter by defenders
      let filtered = tactics.filter((tacticPos) => tacticPos.split('-')[0] === defenders.toString())
      // Filter by attackers
      filtered = filtered.filter((tacticPos) => {
        const splitted = tacticPos.split('-')
        return splitted[splitted.length - 1] === attackers.toString() || splitted[splitted.length - 1] === `${attackers}(MD)`
      })
      let activeTactic = ''

      out: for (let i = 0; i < filtered.length; i++) {
        const index = []
        for (let j = 0; j < midfielderPlayers.length; j++) {
          const posKeys = Object.keys(availableTactics[filtered[i]])
          if (!posKeys.includes(midfielderPlayers[j].subposition_id)) {
            if (!(midfielderPlayers[j].subposition_id in index)) {
              index[midfielderPlayers[j].subposition_id] = 1
            }
            if (!posKeys.includes(`${midfielderPlayers[j].subposition_id}-${index[midfielderPlayers[j].subposition_id]}`)) {
              continue out
            }
          }
          if (posKeys.includes(`${midfielderPlayers[j].subposition_id}-${index[midfielderPlayers[j].subposition_id]}`)) {
            index[midfielderPlayers[j].subposition_id]++
          }
        }
        activeTactic = filtered[i]
        break
      }

      if (activeTactic === '') {
        if (filtered.length > 0) {
          setActiveTactic(filtered[0])
        } else setActiveTactic(Object.keys(availableTactics)[0])
      } else setActiveTactic(activeTactic)
      isTacticSet.current = true
      automatic.current = false
    }
  }

  function automaticSetFutsalTactic(playersList, setActiveTactic, isTacticSet, automatic, updatedPlayers) {
    if (automatic.current && updatedPlayers && playersList.length > 0) {
      let defenders = 0

      playersList.forEach(player => {
        // Main positions
        switch (player.position_id) {
          case '53':
            defenders++
            break
          default:
            break
        }
      })

      // Filter by defenders
      const tactics = Object.keys(availableTactics)
      const filtered = tactics.filter((tacticPos) => tacticPos.split('-')[0] === defenders.toString())

      if (filtered.length > 0) {
        setActiveTactic(filtered[0])
      } else setActiveTactic(Object.keys(availableTactics)[0])
      isTacticSet.current = true
      automatic.current = false
    }
  }

  function automaticSetHoqueiTactic(playersList, setActiveTactic, isTacticSet, automatic, updatedPlayers) {
    if (automatic.current && updatedPlayers && playersList.length > 0) {
      let defenders = 0
      let hasMiedfielder = false

      if (playersList.find((player) => player.position_id === '349')) {
        hasMiedfielder = true
      }

      playersList.forEach(player => {
        // Main positions
        switch (player.position_id) {
          case '348':
            defenders++
            break
          case '331':
            if (!hasMiedfielder) { player.position_id = '349' }
            break
          default:
            break
        }
      })

      // Filter by defenders
      const tactics = Object.keys(availableTactics)
      const filtered = tactics.filter((tacticPos) => tacticPos.split('-')[0] === defenders.toString())

      if (filtered.length > 0) {
        setActiveTactic(filtered[0])
      } else setActiveTactic(Object.keys(availableTactics)[0])
      isTacticSet.current = true
      automatic.current = false
    }
  }

  const clickAutofill = (changedTactic) => {
    automaticSelectPlayers(changedTactic)
  }

  useEffect(() => {
    if (!isHomeFilled) {
      const automaticSetTactic = modality === '1' ? automaticSetFootballTactic : (modality === '3' ? automaticSetFutsalTactic : automaticSetHoqueiTactic)
      automaticSetTactic(home.main, setActiveTacticHome, isHomeTacticSet, automaticTacticHome, home.updatedPlayers)
    } else isHomeTacticSet.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home.main, selectedFormation])

  useEffect(() => {
    if (!isAwayFilled) {
      const automaticSetTactic = modality === '1' ? automaticSetFootballTactic : (modality === '3' ? automaticSetFutsalTactic : automaticSetHoqueiTactic)
      automaticSetTactic(away.main, setActiveTacticAway, isAwayTacticSet, automaticTacticAway, away.updatedPlayers)
    } else isAwayTacticSet.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [away.main, selectedFormation])

  useEffect(() => {
    if (info.tactic_board === '1') {
      const updatedPlayers = selectedFormation === 'away' ? away.updatedPlayers : home.updatedPlayers
      if (updatedPlayers) {
        automaticSelectPlayers()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormation, home.updatedPlayers, away.updatedPlayers])

  return (
    <Row className={styles.matchWrapper}>
      <Col className='m-0 p-0' lg={8}>
        <Pitch
          show={selectedFormation === 'home'}
          tactic={availableTactics[activeTacticHome]}
          playersList={selectedPlayersHome}
          unselectPlayer={unselectPlayer}
          occupiedPositions={homeOccupiedPositions}
          setOccupiedPositions={setHomeOccupiedPositions}
          userHasEditedFormations={userHasEditedFormations}
          setUserHasEditedFormations={setUserHasEditedFormations}
          modality={modality}
        />
        <Pitch
          show={selectedFormation === 'away'}
          tactic={availableTactics[activeTacticAway]}
          playersList={selectedPlayersAway}
          unselectPlayer={unselectPlayer}
          occupiedPositions={awayOccupiedPositions}
          setOccupiedPositions={setAwayOccupiedPositions}
          userHasEditedFormations={userHasEditedFormations}
          setUserHasEditedFormations={setUserHasEditedFormations}
          modality={modality}
        />
      </Col>
      <Col className='p-0' lg={4} style={{ marginTop: '24px' }}>
        <Row style={{ justifyContent: 'space-between', width: '310px', margin: '0', alignContent: 'left' }}>
          <Button
            className={styles.autofillBtn}
            onClick={() => {
              const selectedPlayers = selectedFormation === 'home' ? selectedPlayersHome : selectedPlayersAway
              if (!userHasEditedFormations && selectedPlayers.length < maxNrPlayers) {
                setUserHasEditedFormations(true)
              }
              clickAutofill(false)
            }}
          >
            {f({ id: 'lineups.autofill' })}
          </Button>
          <Button
            className={styles.resetBtn}
            onClick={() => {
              const selectedPlayers = selectedFormation === 'home' ? selectedPlayersHome : selectedPlayersAway
              if (!userHasEditedFormations && selectedPlayers.length > 0) {
                setUserHasEditedFormations(true)
              }
              resetFormation(selectedFormation)
            }}
          >
            {f({ id: 'lineups.reset' })}
          </Button>
          <Button
            className={styles.autofillBtn}
            onClick={onSave}
          >
            {f({ id: 'match.edit.save' })}
          </Button>
        </Row>
        <Row className='mx-0 mt-3'>
          <Dropdown>
            <Dropdown.Toggle style={{ height: '100%' }}>{selectedFormation === 'home' ? activeTacticHome : activeTacticAway}</Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(availableTactics).map((tactic, key) => (
                <Dropdown.Item key={key} onClick={() => handleChangeTactic(tactic)}>{tactic}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Row>
        <Team
          key={selectedFormation}
          type={selectedFormation}
          handleClick={(playerObject) => {
            if (!userHasEditedFormations) {
              setUserHasEditedFormations(true)
            }
            selectPlayer(playerObject)
          }}
          maxNrPlayers={maxNrPlayers}
          groupsConstants={[
            playerGroupConstants.MAIN,
          ]}
          page={'lineups'}
        />
      </Col>
    </Row>
  )
}

export default Lineups
