import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import styles from './Placeholder.module.scss'

const Placeholder = ({ name, messageTranslationId }) => {
  const { formatMessage: f } = useIntl()
  return (
    <div className={styles.noPlayersPlaceholder}>
      <span>{f({ id: 'matchSetup.noPlayers' })}</span>
    </div>
  )
}

Placeholder.defaultProps = {
  name: 'noItems',
  messageTranslationId: 'placeholder.noItems',
}

Placeholder.propTypes = {
  name: PropTypes.string,
  messageTranslationId: PropTypes.string,
}

export default Placeholder
