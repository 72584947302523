import axios from 'axios'
import { serviceUtils } from '../helpers/serviceUtils'

function setCookies(data) {
  localStorage.setItem('zos-cookie', data.Cookie)
  localStorage.setItem('user-id', data.UserData.id)
  localStorage.setItem('user-zz-member', data.UserData.zz_member)
  localStorage.setItem('lang-key', data.UserData.lang_key)
  return { cookie: data.Cookie, zz_member: data.UserData.zz_member, lang_key: data.UserData.lang_key }
}

function login(username, password) {
  const details = {
    username,
    password,
    from: 'zerozero.live'
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('authUser'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      return setCookies(res.data.data)
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
          // TODO
          // eslint-disable-next-line
          // location.reload(true);
        }
      }
      return Promise.reject(retErr)
    })
}

function loginGoogle(accessToken) {
  const details = {
    type: 'google',
    access_token: accessToken,
    from: 'zerozero.live'
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('authUserSocial'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      return setCookies(res.data.data)
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
          // TODO
          // eslint-disable-next-line
          // location.reload(true);
        }
      }
      return Promise.reject(retErr)
    })
}

function loginFacebook(accessToken) {
  const details = {
    type: 'facebook',
    access_token: accessToken,
    from: 'zerozero.live'
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('authUserSocial'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      return setCookies(res.data.data)
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
        }
      }
      return Promise.reject(retErr)
    })
}

function prepareLoginTwitter(callbackUri) {
  const details = {
    callback_uri: callbackUri,
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('getUrlLoginTwitter'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      if (res.data?.data) {
        localStorage.setItem("oauth_token_secret_twitter", res.data.data.token_secret)
        window.location.href = res.data.data.Url
      }
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
        }
      }
      return Promise.reject(retErr)
    })
}

function loginTwitter(oauth_token, oauth_verifier) {
  const details = {
    type: 'twitter',
    oauth_token: oauth_token,
    oauth_token_secret: localStorage.getItem("oauth_token_secret_twitter"),
    oauth_verifier: oauth_verifier,
    from: 'zerozero.live'
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('authUserSocial'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      return setCookies(res.data.data)
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
        }
      }
      return Promise.reject(retErr)
    })
}

function loginZerozero(oauth_token) {
  const details = {
    type: 'zerozero',
    oauth_token: oauth_token,
    from: 'zerozero.live'
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  }

  return axios
    .post(
      serviceUtils.baseUrl('authUserSocial'),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      return setCookies(res.data.data)
    })
    .catch(err => {
      let retErr
      if (!err.response) {
        // not a network error
        retErr = err.message
      } else {
        retErr = err.response.data.error_text
        if (err.response.data.error_code === 401) {
          logout()
        }
      }
      return Promise.reject(retErr)
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("oauth_token_secret_twitter")
  localStorage.removeItem('zos-cookie')
}

export const authService = {
  login,
  loginGoogle,
  loginFacebook,
  prepareLoginTwitter,
  loginTwitter,
  loginZerozero,
  logout,
}
