import React from 'react'
import styles from './PositionIndicator.module.scss'

const PositionIndicator = ({ position, leftValue, topValue, occupied }) => {
  return (
    <div
      className={`${styles.positionIndicator} PositionIndicator`}
      data-position={position}
      style={{
        left: leftValue,
        top: topValue,
        opacity: occupied ? '0' : '1',
      }}
    ></div>
  )
}

export default PositionIndicator
