import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { LinkContainer } from 'react-router-bootstrap'
import classNames from 'classnames'

import { authActions } from '../../actions/authActions'
import { localeActions } from '../../actions/localeActions'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as LogoSmall } from '../../assets/logo_small.svg'
import { ReactComponent as Menu } from '../../assets/menu.svg'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import styles from './NavigationBar.module.scss'
import FeedbackModal from '../FeedbackModal/FeedbackModal'

const languages = ['pt', 'en', 'br']

const NavigationBar = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.locale)
  const [selectedTab, setSelectedTab] = useState(null)
  const [feedbackModalShow, setFeedbackModalShow] = useState(false)

  const { formatMessage: f } = useIntl()

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(authActions.logout())
  }

  const handleLocaleChange = (e, lang) => {
    dispatch(localeActions.changeLocale(lang))
    localStorage.setItem('lang-key', lang)
    window.location.reload()
  }

  const handleTabChange = (e) => {
    e.preventDefault()
    if (e.target.getAttribute('tab') === selectedTab) {
      setSelectedTab(null)
    } else {
      setSelectedTab(e.target.getAttribute('tab'))
    }
  }

  const handleDismiss = () => {
    setSelectedTab(null)
  }

  return (
    <header>
      <div className={styles.navbar}>
        <LinkContainer to="/">
          <button className={styles.brand}>
            <Logo className='d-none d-sm-block' />
            <LogoSmall className='d-block d-sm-none' />
          </button>
        </LinkContainer>
        <div className={styles.buttons}>
          <div className={`${styles.button} pr-2`}>
            <Dropdown>
              <Dropdown.Toggle variant='none' style={{ color: 'white' }}>
                {locale}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {languages && languages.map((lang) =>
                  <Dropdown.Item key={lang} onClick={(e) => handleLocaleChange(e, lang)}>{lang}</Dropdown.Item>,
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={styles.button}>
            <div
              className={classNames(
                styles.selected,
                selectedTab !== 'settings' ? styles.hidden : '',
              )}
            />
            <button data-testid="settings" tab="settings" className={styles.settingsButton} onClick={handleTabChange}>
              <FontAwesomeIcon icon={faCog} size="lg" />
            </button>
          </div>
          <div className={styles.button}>
            <div
              className={classNames(
                styles.selected,
                selectedTab !== 'links' ? styles.hidden : '',
              )}
            />
            <button data-testid="links" tab="links" className={styles.menuButton} onClick={handleTabChange}>
              <Menu />
            </button>
          </div>
        </div>
      </div>
      <div
        data-testid="links-container"
        className={classNames(
          styles.sidebar,
          selectedTab !== 'settings' ? styles.hidden : '',
        )}
      >
        <div className={styles.linkContainer}>
          <button onClick={() => {
            handleDismiss()
            setFeedbackModalShow(true)
          }}>
            {f({ id: 'navbar.feedback' })}
          </button>
          <button onClick={handleLogout}>
            {f({ id: 'navbar.logout' })}
          </button>
        </div>
      </div>
      <div
        data-testid="links-container"
        className={classNames(
          styles.sidebar,
          selectedTab !== 'links' ? styles.hidden : '',
        )}
      >
        <div className={styles.linkContainer}>
          <LinkContainer to="/">
            <button>{f({ id: 'navbar.home' })}</button>
          </LinkContainer>
          <LinkContainer to="/search">
            <button href="">{f({ id: 'navbar.search' })}</button>
          </LinkContainer>
          {/* <LinkContainer to="/search">
            <button href="">{f({ id: 'navbar.begin' })}</button>
          </LinkContainer>
          <LinkContainer to="/search">
            <button href="">{f({ id: 'navbar.competitions' })}</button>
        </LinkContainer> */}
          <LinkContainer to="/history">
            <button href="">{f({ id: 'navbar.history' })}</button>
          </LinkContainer>
        </div>
      </div>
      <div
        onClick={handleDismiss}
        className={classNames(
          styles.darkenBottom,
          !selectedTab ? styles.hidden : '',
        )}
      />
      <div
        onClick={handleDismiss}
        className={classNames(
          styles.darkenNavbar,
          !selectedTab ? styles.hidden : '',
        )}
      />
      <FeedbackModal
        show={feedbackModalShow}
        onHide={() => setFeedbackModalShow(false)}
      />
    </header>
  )
}

export default NavigationBar
