import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import styles from './MatchClock.module.scss'
import classNames from 'classnames'
import { generateTimestamp, getBoundaryValues } from '../../helpers/utils'

const MatchClock = ({
  state, matchDate, matchBegin, matchFinished, clock, extra,
  showMatchTimeEditor, setClock, noClock, totalDuration, extraTimePartDuration,
}) => {
  const timeString = generateTimestamp(clock.minutos, clock.seconds, extra)

  const incrementTime = (increment) => {
    const minutes = clock.minutos + increment
    const [minMinute, maxMinute] = getBoundaryValues(state, parseInt(totalDuration), parseInt(extraTimePartDuration))

    if (parseInt(minutes) >= minMinute && parseInt(minutes) <= maxMinute) { setClock({ ...clock, minutos: minutes }) }
  }

  const inGame = () => {
    return matchBegin === '1' && matchFinished === '0'
  }

  return (
    <>
      <div
        className={classNames(
          styles.ext_matchClock,
          styles.alignCenter,
          'align-self-center',
        )}
      >
        <div className={classNames(noClock && inGame()
          ? styles.clockWrapper : (styles.alignCenter, 'align-self-center'))}
        >
          {noClock && inGame() && <div className={classNames(styles.alignRightHelper)} />}
          <div
            className={classNames(
              styles.matchClock,
              styles.alignCenter,
              'align-self-center',
            )}
            onClick={() => showMatchTimeEditor()}
          >
            <div>
              {inGame() ? timeString : matchDate}
            </div>
          </div>
          {noClock && inGame() && <div className={classNames(styles.buttonWrapper)}
          >
            <Button
              size='sm'
              className={styles.clockButton}
              onClick={() => incrementTime(1)}
            >
              +
            </Button>
            <Button
              size='sm'
              className={styles.clockButton}
              onClick={() => incrementTime(-1)}
            >
              -
            </Button>
          </div>}
        </div>
      </div>
    </>
  )
}

MatchClock.propTypes = {
  matchDate: PropTypes.string.isRequired,
  matchBegin: PropTypes.string.isRequired,
  matchFinished: PropTypes.string.isRequired,
  clock: PropTypes.shape({
    minutos: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }),
  extra: PropTypes.number.isRequired,
  showMatchTimeEditor: PropTypes.func.isRequired,
}

export default MatchClock
