const compareEvents = (actionInQueue, actionToRemove) => {
  return (
    actionInQueue.fk_jogo &&
    actionInQueue.minuto &&
    actionInQueue.texto &&
    actionInQueue.event_id &&
    actionInQueue.event_id === actionToRemove.fk_live_tpevent &&
    actionInQueue.minuto === actionToRemove.minute &&
    actionInQueue.texto === actionToRemove.text
  )
}

export { compareEvents }
