import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const cookie = useSelector(state => state.auth.cookie)
  const loggedIn = useSelector(state => state.auth.loggedIn)

  if (loggedIn && !sessionStorage.getItem('auto_logged')) {
    sessionStorage.setItem('auto_logged', true)
  }

  return (
    <Route
      {...rest}
      render={props =>
        cookie && loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default PrivateRoute
