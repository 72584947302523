import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styles from './ConfirmResult.module.scss'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row } from 'react-bootstrap'

const invalidModalityIds = ['1', '6', '7']

const ConfirmResult = ({ show, onHide, handleConfirmResult, matchInfo, teamRight, teamLeft }) => {
  const { formatMessage: f } = useIntl()
  const [homeScoreInitial, setHomeScoreInitial] = useState(0)
  const [awayScoreInitial, setAwayScoreInitial] = useState(0)
  const [homeScore, setHomeScore] = useState(0)
  const [awayScore, setAwayScore] = useState(0)
  const [benchedPlayers, setBenchedPlayers] = useState({ home: [], away: [] })
  const [playersNotPlayed, setPlayersNotPlayed] = useState({ home: [], away: [] })

  useEffect(() => {
    setAwayScore(teamRight.score)
    setHomeScore(teamLeft.score)
    setAwayScoreInitial(teamRight.score)
    setHomeScoreInitial(teamLeft.score)
  }, [teamRight, teamLeft])

  useEffect(() => {
    setBenchedPlayers({
      home: matchInfo.homePlayers?.filter((player) => player.benched),
      away: matchInfo.awayPlayers?.filter((player) => player.benched),
    })
  }, [matchInfo.awayPlayers, matchInfo.homePlayers])

  const confirmResultChanged = () => {
    handleConfirmResult(homeScore, awayScore, playersNotPlayed)
    onHide()
  }

  const confirmResultUnchanged = () => {
    handleConfirmResult(homeScoreInitial, awayScoreInitial)
    onHide()
  }

  const onChangePlayed = (player, type) => {
    player.checked = !player.checked
    setPlayersNotPlayed((oldPlayers) => {
      if (!player.checked) {
        return {
          ...oldPlayers,
          [type]: [...oldPlayers[type], player.id]
        }
      }
      return {
        ...oldPlayers,
        [type]: oldPlayers[type].filter((item) => item !== player.id)
      }
    })
  }

  return (
    <Modal show={show} onHide={onHide} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'finalResult.confirmResult' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.confirmModal}>
          <img
            alt=""
            src={matchInfo.homeIcon}
            className={styles.confirmIcon}
          />
          <input
            className={styles.confirmInput}
            type="number"
            onChange={(e) => { e.target.value >= 0 ? setHomeScore(e.target.value) : setHomeScore(0) }}
            value={homeScore}
          />
          <p className={styles.confirmIfan}>-</p>
          <input
            className={styles.confirmInput}
            type="number"
            onChange={(e) => { e.target.value >= 0 ? setAwayScore(e.target.value) : setAwayScore(0) }}
            value={awayScore}
          />
          <img
            alt=""
            src={matchInfo.awayIcon}
            className={styles.confirmIcon}
          />
        </div>
        {!invalidModalityIds.includes(matchInfo.te_modalidade) && (
          <div className='p-3'>
            <h3 className={classNames(styles.sub_header_title, 'mb-3')}>{f({ id: 'finalResult.playedNotPlayed' })}</h3>
            <Row>
              <Col style={{ borderRight: "solid 1px #23323f" }}>
                <Form>
                  {benchedPlayers.home?.map((player, index) => (
                    <Form.Check
                      key={index}
                      type='checkbox'
                      id={`checkbox-home-${index}`}
                      defaultChecked={player.checked}
                      onChange={() => onChangePlayed(player, 'home')}
                      label={`${player.number !== '0' ? player.number + '. ' : ''}${player.player_name}`}
                    />
                  ))}
                </Form>
              </Col>
              <Col style={{ borderLeft: "solid 1px #23323f" }}>
                <Form>
                  {benchedPlayers.away?.map((player, index) => (
                    <Form.Check
                      key={index}
                      type='checkbox'
                      id={`checkbox-away-${index}`}
                      defaultChecked={player.checked}
                      onChange={() => onChangePlayed(player, 'away')}
                      label={`${player.number !== '0' ? player.number + '. ' : ''}${player.player_name}`}
                    />
                  ))}
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.cancelButton} onClick={confirmResultUnchanged}>{f({ id: 'match.edit.cancel' })}</Button>
        <Button onClick={confirmResultChanged}>{f({ id: 'finalResult.confirm' })}</Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmResult.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  matchInfo: PropTypes.object.isRequired,
}

export default ConfirmResult
