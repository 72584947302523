import React, { useState } from 'react'
import { Card, CardGroup } from 'react-bootstrap'
import style from './LivePenaltiesPanel.module.scss'
import TeamPenalties from './TeamPenalties'

function LivePenaltiesPanel({ penalties = { home: '', away: '', homePlayers: '', awayPlayers: '' }, ended = false }) {
  const [showAllHome, setShowAllHome] = useState(false)
  const [showAllAway, setShowAllAway] = useState(false)
  const equalNrGoals = (penalties?.home.match(/V/g) || []).length === (penalties?.away.match(/V/g) || []).length
  const lastPenEnabledHome = penalties?.home.length >= 5 && penalties?.away.length >= 5 && penalties?.home.length <= penalties?.away.length && !ended && ((penalties?.home.length === penalties?.away.length && equalNrGoals) || penalties?.home.length !== penalties?.away.length)
  const lastPenEnabledAway = penalties?.home.length >= 5 && penalties?.away.length >= 5 && penalties?.home.length >= penalties?.away.length && !ended && ((penalties?.home.length === penalties?.away.length && equalNrGoals) || penalties?.home.length !== penalties?.away.length)
  const appendPenHome = penalties?.home.length < penalties?.away.length && !ended
  const appendPenAway = penalties?.home.length > penalties?.away.length && !ended

  const showAll = penalties?.home.length >= 5 && penalties?.away.length >= 5 &&
    ((penalties?.home.length === 5 && penalties?.away.length === 5 && equalNrGoals) ||
      penalties?.home.length !== 5 || penalties?.away.length !== 5)

  return (
    <CardGroup className={style.panel}>
      <Card className={`${style.card} ${style.cardHome} ${showAll ? style.cardHomeHover : ''}`} onClick={() => {
        if (showAll)
          setShowAllHome(!showAllHome)
      }}>
        <Card.Body className={style.cardBody}>
          <TeamPenalties penalties={penalties?.home + (appendPenHome ? '-' : '')} showAll={showAllHome} lastPenEnabled={lastPenEnabledHome} players={penalties?.homePlayers?.split(',')} />
        </Card.Body>
      </Card>
      <Card className={`${style.card} ${style.cardAway} ${showAll ? style.cardAwayHover : ''}`} onClick={() => {
        if (showAll)
          setShowAllAway(!showAllAway)
      }}>
        <Card.Body className={style.cardBody}>
          <TeamPenalties penalties={penalties?.away + (appendPenAway ? '-' : '')} showAll={showAllAway} lastPenEnabled={lastPenEnabledAway} players={penalties?.awayPlayers?.split(',')} />
        </Card.Body>
      </Card>
    </CardGroup>
  )
}

export default LivePenaltiesPanel
