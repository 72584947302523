import React, { useEffect, useState, useRef } from 'react'
import PlayerCard from '../PlayerCard'
import footballPitchImage from '../../assets/lineups/football_pitch.svg'
import futsalPitchImage from '../../assets/lineups/futsal_pitch.svg'
import hockeyPitchImage from '../../assets/lineups/hockey_rink.svg'
import PositionIndicator from '../PositionIndicator/PositionIndicator'
import styles from './Pitch.module.scss'
import subToMainPositionsFootball from '../../assets/lineups/tactics/football/positions.json'

const positionsConversionFootball = {
  2: '20-1',
  20: '20-1',
  22: '15',
  3: '58-1',
  40: '40-1',
  58: '58-1',
  4: '100-1',
  100: '100-1',
}

const positionsConversionFutsal = {
  53: '53-1',
  54: '53-1',
  55: '53-1',
  56: '56-1',
  57: '56-1',
  327: '53-1',
}

const positionsConversionHoquei = {
  330: '348-1',
  331: '331-1',
  346: '348-1',
  348: '348-1',
  349: '349-2',
}

const Pitch = ({ occupiedPositions, setOccupiedPositions, show, tactic, playersList, unselectPlayer, userHasEditedFormations, setUserHasEditedFormations, modality }) => {
  const frameRef = useRef()
  const [frame, setFrame] = useState()
  const positionsConversion = modality === '1' ? positionsConversionFootball : (modality === '3' ? positionsConversionFutsal : positionsConversionHoquei)
  const [originX, setOriginX] = useState(0)
  const [originY, setOriginY] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const unplaced = useRef(false)
  const [currentPlayerCard, setCurrentPlayerCard] = useState()
  const [currentPlayer, setCurrentPlayer] = useState()

  const occupyPosition = position => {
    if (typeof (occupiedPositions.find(e => e === position)) === 'undefined') {
      setOccupiedPositions((old) => [...old, position])
    }
  }

  const unoccupyPosition = position => {
    const newPositions = occupiedPositions
    // Delete selected position from array
    for (let i = 0; i < occupiedPositions.length; i++) {
      if (occupiedPositions[i] === position) {
        newPositions.splice(i, 1)
      }
    }
    setOccupiedPositions(newPositions)
  }

  const positionPlayer = (player, position, selector, swap = false) => {
    const card = document.querySelector(`.${selector}`)
    // Position card
    card.style.left = `${tactic[position].x - 8.5}%`
    card.style.top = `${tactic[position].y - 7.5}%`
    card.style.transform = 'unset'
    if (swap) {
      card.style.transitionProperty = 'top, left'
    } else {
      card.style.transitionProperty = ''
    }
    // Update data
    occupyPosition(position)
    const subpositions = position.split('-')
    player.position_id = subToMainPositionsFootball.hasOwnProperty(subpositions[0]) && modality === '1' ? subToMainPositionsFootball[subpositions[0]].main : subpositions[0]
    player.subposition_id = position
    player.positioned = true
    card.dataset.activePosition = position
    setFrame(frameRef.current.getBoundingClientRect())
  }

  const unpositionPlayer = (player) => {
    player.positioned = false
    setFrame(frameRef.current.getBoundingClientRect())
  }

  useEffect(() => {
    if (show) {
      const rect = frameRef.current.getBoundingClientRect()
      setFrame(rect)
      window.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleResize)
    }
  }, [show])

  const handleResize = () => {
    if (frameRef.current) {
      setFrame(frameRef.current.getBoundingClientRect())
    }
  }

  useEffect(() => {
    if (frameRef.current) {
      setFrame(frameRef.current.getBoundingClientRect())
    }
  }, [playersList])

  const onMouseDown = (e) => {
    dragStart(e, e.clientX, e.clientY)
  }

  const onMouseMove = (e) => {
    // Only drag if mouse is being pressed
    if (isDragging) {
      dragMove(e.clientX, e.clientY)
    }
  }

  const onMouseUp = () => {
    if (isDragging) {
      dragEnd()
      setCurrentPlayerCard()
      setCurrentPlayer()
    }
  }

  const onTouchStart = (e) => {
    dragStart(e, e.touches[0].clientX, e.touches[0].clientY)
  }

  const onTouchMove = (e) => {
    // Only drag if mouse is being pressed
    if (isDragging) {
      // Move card around
      dragMove(e.touches[0].clientX, e.touches[0].clientY)
    }
  }

  const onTouchEnd = () => {
    if (isDragging) {
      dragEnd()
    }
  }

  const dragStart = (e, x, y) => {
    // Get elements of player and player card
    let playerCard
    if (!e.target.classList.contains('PlayerCard')) {
      if (!e.target.classList.contains('Portrait') && !e.target.classList.contains('PlayerCardName')) return
      playerCard = e.target.parentElement
    } else playerCard = e.target
    const player = playersList.find((player) => player.id === playerCard.getAttribute('playerid'))
    setCurrentPlayerCard(playerCard)
    setCurrentPlayer(player)

    if (!userHasEditedFormations) {
      setUserHasEditedFormations(true)
    }
    setIsDragging(true)
    setOriginX(x)
    setOriginY(y)
    playerCard.style.zIndex = '400'
  }

  const dragMove = (x, y) => {
    const currentPos = currentPlayerCard.getBoundingClientRect()
    unplaced.current = true
    // Prevent dragging outside of Pitch
    if (
      currentPos.left >= frame.left &&
      currentPos.right <= frame.right &&
      currentPos.top >= frame.top &&
      currentPos.bottom <= frame.bottom
    ) {
      // Move player card visually
      currentPlayerCard.style.transform = `
      translateX(${x - originX}px)
      translateY(${y - originY}px)
    `
    } else {
      // Prevent further dragging
      dragEnd(true)
      const activePosition = currentPlayerCard.dataset.activePosition
      unselectPlayer(currentPlayer)
      unpositionPlayer(currentPlayer)
      // Reset position indicator
      unoccupyPosition(activePosition)
    }
  }

  const dragEnd = (outsidePitch = false) => {
    setIsDragging(false)
    currentPlayerCard.style.zIndex = '300'

    if (outsidePitch) return

    // Get card center relatively to Pitch
    const currentPos = currentPlayerCard.getBoundingClientRect()
    const cardCenterPos = {}
    cardCenterPos.x = 100 * (currentPos.left + (currentPos.width / 2) - frame.left) / frame.width
    cardCenterPos.y = 100 * (currentPos.top + (currentPos.height / 2) - frame.top) / frame.height

    // Snap to position if dragged next to position indicator
    for (const indicator of Object.keys(tactic)) {
      if (
        indicator !== currentPlayerCard.dataset.activePosition &&
        getDistance(
          tactic[indicator].x,
          tactic[indicator].y,
          cardCenterPos.x,
          cardCenterPos.y,
        ) < 8
      ) {
        let isAvailable = true
        for (const occupied of occupiedPositions) {
          if (occupied === indicator) {
            isAvailable = false
          }
        }
        if (playersList.length === 11) {
          isAvailable = false
        }
        const activePosition = currentPlayerCard.dataset.activePosition

        // Swap players if position is occupied
        if (!isAvailable) {
          // Do the reverse travel with the other player
          unoccupyPosition(indicator)
          const cardToMove = document.querySelector(`[data-active-position='${indicator}']`)
          const otherPlayer = playersList.filter((e) => `Player${e.id}` === cardToMove.classList[1])[0]
          positionPlayer(otherPlayer, currentPlayer.subposition_id, cardToMove.classList[1], true)
        } else {
          // Prepare next drag
          unoccupyPosition(activePosition)
          unoccupyPosition(indicator)
        }
        unplaced.current = false
        positionPlayer(currentPlayer, indicator, `Player${currentPlayer.id}`)
        break
      }
    }

    if (unplaced.current) {
      positionPlayer(currentPlayer, currentPlayerCard.dataset.activePosition, `Player${currentPlayer.id}`)
    }
    setCurrentPlayerCard()
    setCurrentPlayer()
  }

  // Calculate distance between 2 points
  const getDistance = (x0, y0, x1, y1) => {
    // Using Pythagore
    const differenceX = x0 - x1
    const differenceY = y0 - y1
    return Math.sqrt(Math.pow(differenceX, 2) + Math.pow(differenceY, 2))
  }

  const pitchImage = modality === '3' ? futsalPitchImage : (modality === '8' ? hockeyPitchImage : footballPitchImage)

  return show ? (
    <div
      className={`${styles.pitch} ${styles.pitchGroupWrapper} ${styles.pitchWrapper}`}
      ref={frameRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      <img className={styles.outlines} src={pitchImage} alt="Pitch outlines" />
      <div>
        {Object.keys(tactic).map(positionKey => {
          return (
            <PositionIndicator
              key={positionKey}
              position={positionKey}
              leftValue={`${tactic[positionKey].x}%`}
              topValue={`${tactic[positionKey].y}%`}
              occupied={
                typeof (occupiedPositions.find(e => e === positionKey)) !== 'undefined'
              }
            />
          )
        })}
        {playersList.map(player => {
          return (
            <PlayerCard
              player={player}
              key={player.id}
              tactic={tactic}
              occupiedPositions={occupiedPositions}
              positionPlayer={positionPlayer}
              positionsConversion={positionsConversion}
            />
          )
        })}
      </div>
    </div>
  ) : (<></>)
}

export default Pitch
