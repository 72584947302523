import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import styles from './PhotoUploader.module.scss'
import { useIntl } from 'react-intl'
import { eventsService } from '../../services/eventsService'
import Spinner from 'react-bootstrap/Spinner'

const PhotoUploader = ({ matchId, handleCloseForm, setPhotoThumbnail }) => {
  const [showPhotoUploader, setShowPhotoUploader] = useState(true)
  const [dragActive, setDragActive] = useState(false)
  const [startedUploading, setStartedUploading] = useState(false)
  const [errors, setErrors] = useState()
  const { formatMessage: f } = useIntl()

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      prepareUploadPhoto(e.dataTransfer.files[0])
    }
  }

  const handleChangeFile = (e) => {
    prepareUploadPhoto(e.target.files[0])
  }

  const handleHide = () => {
    setShowPhotoUploader(false)
    handleCloseForm()
  }

  function prepareUploadPhoto(photo) {
    setStartedUploading(true)
    const request = eventsService.prepareUploadPhoto(matchId, photo)
    if (request) {
      request.then((res) => {
        setErrors()
        setStartedUploading(false)
        setShowPhotoUploader(false)
        setPhotoThumbnail(res.data)
      })
        .catch((error) => {
          setStartedUploading(false)
          if (error.response?.request?.status === 413) {
            setErrors(f({ id: "fileUploader.error" }))
          }
        })
    }
  }

  window.addEventListener('dragover', function (e) {
    e.preventDefault()
  }, false)
  window.addEventListener('drop', function (e) {
    e.preventDefault()
  }, false)

  return (
    <Modal show={showPhotoUploader} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title className={styles.header_title}>
          {f({ id: 'photoUploader.title' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {startedUploading ? (
          <Row className='m-0'>
            <Spinner animation="border" role="status" />
            <p className='m-0 pl-3' style={{ lineHeight: '2' }}>{f({ id: 'photoUploader.loading' })}</p>
          </Row>
        ) : (
          <Form
            className={styles.form_photo_upload}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
            onSubmit={(e) => e.preventDefault()}
          >
            <input type="file" className={styles.input_photo_upload} id="photo-uploader" onChange={handleChangeFile} accept="image/*" />
            <label className={`${styles.label_photo_upload} ${dragActive ? styles.drag_active : ''}`} htmlFor="photo-uploader">
              <div>
                <p>{f({ id: 'photoUploader.dragAndDrop' })}</p>
                <p className={styles.upload_button}>{f({ id: 'photoUploader.link' })}</p>
                {errors && (
                  <p style={{color:'red'}}>{errors}</p>
                )}
              </div>
            </label>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide}>{f({ id: 'close' })}</Button>
      </Modal.Footer>
    </Modal >
  )
}

PhotoUploader.propTypes = {
  matchId: PropTypes.string.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  setPhotoThumbnail: PropTypes.func.isRequired,
}

export default PhotoUploader
