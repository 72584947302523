import { eventsConstants } from '../actions/eventsActions'
import { compareEvents } from '../helpers/reducerUtils'
import { matchSetupConstants } from '../actions/matchSetupActions'
import { feedbackConstants } from '../actions/feedbackActions'

const prepareNotification = (e) => {
  const eventName = JSON.parse(localStorage.getItem('events')).find(
    (event) => event.id === e.event_id,
  )
  return {
    id: `${Date.now() + Math.random()}`,
    text: `${e.minuto}'${
      e.minuto_extra ? '+ ' + e.minuto_extra + "' " : ' '
    }: ${eventName.abrev} (${e.fk_jogo})`,
    variant: 'offline',
  }
}

const prepareFeedbackNotification = () => {
  return {
    id: `${Date.now() + Math.random()}`,
    text: '',
    variant: 'offline',
  }
}

const initialState = { queue: [], notify: [] }

const requests = (state = initialState, action) => {
  switch (action.type) {
    case eventsConstants.POST_EVENT:
      return {
        queue: state.queue.concat([{ ...action.payload, time: Date.now() }]),
        notify: state.notify,
      }
    case eventsConstants.POST_EVENT_COMMIT:
      action.callback(action.payload)
      return {
        queue: state.queue.slice(1),
        notify: [...state.notify, prepareNotification(action.meta)],
      }
    case eventsConstants.POST_EVENT_ROLLBACK:
      return {
        queue: state.queue.slice(1),
        notify: state.notify,
      }
    case feedbackConstants.POST_FEEDBACK:
      return {
        queue: state.queue.concat([{ ...action.payload, time: Date.now() }]),
        notify: state.notify,
      }
    case feedbackConstants.POST_FEEDBACK_COMMIT:
      action.callback(action.payload)
      return {
        queue: state.queue.slice(1),
        notify: [...state.notify, prepareFeedbackNotification()],
      }
    case feedbackConstants.POST_FEEDBACK_ROLLBACK:
      return {
        queue: state.queue.slice(1),
        notify: state.notify,
      }
    case eventsConstants.EDIT_EVENT_COMMIT:
      action.callback(action.payload)
      return {
        queue: state.queue.slice(1),
        notify: [...state.notify, prepareNotification(action.meta)],
      }
    case eventsConstants.EDIT_EVENT_ROLLBACK:
      return {
        queue: state.queue.slice(1),
        notify: state.notify,
      }

    case eventsConstants.DELETE_EVENT_COMMIT:
      action.callback(action.payload)
      return {
        queue: state.queue.slice(1),
        notify: [...state.notify, prepareNotification(action.meta)],
      }
    case eventsConstants.DELETE_EVENT_ROLLBACK:
      return {
        queue: state.queue.slice(1),
        notify: state.notify,
      }
    case matchSetupConstants.MATCH_SETUP_SEND_TEAMS:
      return {
        ...state,
        queue: state.queue.concat([{ ...action.payload, time: Date.now() }]),
      }
    case matchSetupConstants.MATCH_SETUP_SEND_TEAMS_COMMIT:
      return {
        ...state,
        queue: state.queue.slice(1),
      }
    case matchSetupConstants.MATCH_SETUP_SEND_TEAMS_ROLLBACK:
      return {
        queue: state.queue.slice(1),
      }
    case eventsConstants.CANCEL_OFFLINE_POST:
      return {
        queue: state.queue.filter(
          (task) => !compareEvents(task, action.payload),
        ),
        notify: state.notify,
      }
    case eventsConstants.SENT_NOTIFY:
      return {
        queue: state.queue,
        notify: state.notify.filter(
          (notification) => notification.id !== action.payload,
        ),
      }
    default:
      return state
  }
}

export default requests
