import { serviceUtils } from '../helpers/serviceUtils'

function preparePostFeedback(text) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    text: text || '',
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  return {
    url: serviceUtils.baseUrl('zzLiveFeedback'),
    method: 'POST',
    body: formBody,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

export const feedbackService = {
  preparePostFeedback,
}
