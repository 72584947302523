import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import MatchListItem from '../MatchListItem'

import style from './MatchList.module.scss'

const MatchList = ({ type, title, matches }) => {
  const { formatMessage: f } = useIntl()
  return (
    <div className={style.matchListWrapper}>
      <div className={style.matchListHeader}>
        <p>{title}</p>
      </div>
      {matches.length > 0 ? (
        <div>
          {matches.map((match) => (
            <MatchListItem
              type={type}
              key={match.id}
              id={match.id}
              title={match.title}
              date={match.date}
              result={match.result}
              time={match.time}
            />
          ))}
        </div>
      ) : (
        <div className={style.placeholder}>
          <p>{f({ id: 'homepage.noOngoingMatches' })}</p>
        </div>
      )}
    </div>
  )
}

MatchList.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      date: PropTypes.string,
      result: PropTypes.string,
      time: PropTypes.string,
    }),
  ),
}

export default MatchList
