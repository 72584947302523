import React from 'react'
import PropTypes from 'prop-types'

import styles from './TeamVersus.module.scss'

const TeamVersus = ({ home, away, info }) => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.team}>
        <div
          className={styles.teamLogo}
          style={{ backgroundImage: `url(${home.logo})` }}
        />
        <p className={info.showNamesInSmallScreen ? '' : styles.invisible}>{home.name}</p>
      </div>
      <div className={styles.versus}>
        <p className={styles.edition}>{info.edition}</p>
        <p className={styles.score}>{info.fullTime ? `${info.homeGoals} - ${info.awayGoals}` : '-'}</p>
      </div>
      <div className={styles.team}>
        <p className={info.showNamesInSmallScreen ? '' : styles.invisible}>{away.name}</p>
        <div
          className={styles.teamLogo}
          style={{ backgroundImage: `url(${away.logo})` }}
        />
      </div>
    </div>
  )
}

TeamVersus.defaultProps = {
  home: {
    name: '-',
  },
  away: {
    name: '-',
  },
}

TeamVersus.propTypes = {
  home: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  away: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  info: PropTypes.shape({
    fullTime: PropTypes.bool,
    homeGoals: PropTypes.string,
    awayGoals: PropTypes.string,
    edition: PropTypes.string,
    showNamesInSmallScreen: PropTypes.bool,
  }),
}

export default TeamVersus
