import React from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

const CatalogPenForm = ({ playerSuffered = '0', playerCommited = '0', options, onChangePlayerSuffered, onChangePlayerCommited, teamId1, teamId2 }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Form className='p-2'>
      <fieldset>
        <Form.Group>
          <Form.Label htmlFor='penSuffered'>{f({ id: 'catalogPens.suffered' })}</Form.Label>
          <Form.Control as="select" id="penSuffered" value={playerSuffered} onChange={onChangePlayerSuffered}>
            <option key={'0'} value={'0'}></option>
            {options[teamId1] && options[teamId1].map((item) =>
              <option key={item.id} value={item.id}>{item.number !== '0' ? item.number + '. ' : ''}{item.player_name}</option>,
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='penCommited'>{f({ id: 'catalogPens.commited' })}</Form.Label>
          <Form.Control as="select" id="penCommited" value={playerCommited} onChange={onChangePlayerCommited}>
            <option key={'0'} value={'0'}></option>
            {options[teamId2] && options[teamId2].map((item) =>
              <option key={item.id} value={item.id}>{item.number !== '0' ? item.number + '. ' : ''}{item.player_name}</option>,
            )}
          </Form.Control>
        </Form.Group>
      </fieldset>
    </Form>
  )
}

CatalogPenForm.propTypes = {
  playerSuffered: PropTypes.string,
  playerCommited: PropTypes.string,
  options: PropTypes.object.isRequired,
  onChangePlayerSuffered: PropTypes.func.isRequired,
  onChangePlayerCommited: PropTypes.func.isRequired,
  teamId1: PropTypes.string.isRequired,
  teamId2: PropTypes.string.isRequired,
}

export default CatalogPenForm
