import React from 'react'
import { useIntl } from 'react-intl'
import { history } from '../../helpers/history'

import { Button, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styles from './FormationsModal.module.scss'

const FormationsModal = ({ show, onHide, matchId }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <p>{f({ id: 'formations.content' })}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} className={styles.noButton}>
          {f({ id: 'no' })}
        </Button>
        <Button onClick={() => history.push(`/match/${matchId}/home/home`)}>
          {f({ id: 'yes' })}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

FormationsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  matchId: PropTypes.string.isRequired,
}

export default FormationsModal
