import { eventsConstants } from './eventsActions'

export const notificationsConstants = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
}

const addNotification = (id, title, text, variant) => {
  return {
    type: notificationsConstants.ADD_NOTIFICATION,
    payload: {
      title,
      text,
      id,
      variant,
    },
  }
}

const removeNotification = (notificationId) => {
  return {
    type: notificationsConstants.REMOVE_NOTIFICATION,
    payload: notificationId,
  }
}

const removeOfflineNotification = (notificationId) => {
  return {
    type: eventsConstants.SENT_NOTIFY,
    payload: notificationId,
  }
}

export const notificationsActions = {
  addNotification,
  removeNotification,
  removeOfflineNotification,
}
