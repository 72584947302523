import React, { useState, useEffect, useRef } from 'react'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Events from '../Events'
import { useIntl } from 'react-intl'
import TextareaAutosize from 'react-textarea-autosize'
import styles from './CommentsForm.module.scss'
import classNames from 'classnames'
import sendIcon from '../../assets/matchCommentsIcons/send.svg'
import openAppsIcon from '../../assets/matchCommentsIcons/openApps.svg'
import closeAppsIcon from '../../assets/matchCommentsIcons/closeApps.svg'
import { errorActions } from '../../actions/errorActions'
import { useDispatch, useSelector } from 'react-redux'
import { generateVodafoneTimestamp } from '../../helpers/utils'
import { eventsService } from '../../services/eventsService'

function isNullOrWhitespace(input) {
  return !input || !input.trim()
}

const CommentsForm = ({
  eventInfo,
  setEventInfo,
  events,
  setEvents,
  eventsCategories,
  handleInsert,
  matchInfo,
  homeCoach,
  awayCoach,
  clock,
  acronyms,
  setEventsOpen,
  extra,
  isVodafoneEnabled,
  halfTimes,
  halfs,
  uploaderEventsIds,
  showEvents,
  setShowEvents,
  canOpenTab = true,
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const eventsToShow = events.filter((elem) => elem.external === '1')

  const [commentBox, setCommentBox] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const deletedThumbnail = useRef(false)
  const zzMember = useSelector((state) => state.auth.zz_member)
  const [shiftPressed, setShiftPressed] = useState(false)

  function deleteTmpPhoto() {
    if (uploaderEventsIds.includes(eventInfo.selectedEvent)) {
      if (eventInfo.selectedEvent === uploaderEventsIds[1] && thumbnail !== '') {
        eventsService.deleteUploadPhoto(matchInfo.id, thumbnail)
        deletedThumbnail.current = true
      }
    }
  }

  useEffect(() => {
    if (!showEvents) {
      setEventInfo((eventInfo) => ({
        ...eventInfo,
        minute: clock.minutos + ((clock.seconds > 0 && extra === 0) ? 1 : 0),
        minuteExtra: extra,
      }))
    }
  }, [clock, eventInfo.minutoExtra, extra, showEvents, setEventInfo])

  const handleClick = (e) => {
    if (!showEvents) {
      setEventInfo(eventInfo => ({
        ...eventInfo,
        minute: clock.minutos + ((clock.seconds > 0 && extra === 0) ? 1 : 0),
        minuteExtra: extra,
      }))
    } else {
      setCommentBox('')
      deleteTmpPhoto()
      setThumbnail('')
    }
    setShowEvents(!showEvents)
    setEventInfo(eventInfo => ({
      minute: clock.minutos + ((clock.seconds > 0 && extra === 0) ? 1 : 0),
      minuteExtra: extra,
      selectedEvent: null,
      selectedTeam: null,
      selectedPlayer: null,
      selectedPlayerOut: null,
      hasSelectedCoach: false,
    }))
  }

  const handleCloseForm = () => {
    setCommentBox('')
    setShowEvents(false)
    setEventInfo(eventInfo => ({
      minute: clock.minutos + ((clock.seconds > 0 && extra === 0) ? 1 : 0),
      minuteExtra: extra,
      selectedEvent: null,
      selectedTeam: null,
      selectedPlayer: null,
      selectedPlayerOut: null,
      hasSelectedCoach: false,
    }))
  }

  const handleChange = (e) => {
    setCommentBox(e.target.value)
  }

  const handleSubmitComment = (commentText) => {
    if (isNullOrWhitespace(commentText)) {
      dispatch(errorActions.alertError(f({ id: 'match.emptyComment' })))
      return
    }

    const commentEventID = 3
    const event =
      events.find(
        (element) => parseInt(element.id) === eventInfo.selectedEvent,
      ) || events.find((element) => parseInt(element.id) === commentEventID)

    if (!event) return;

    const needsPlayer = event.texto.includes('{jog1')
    const needsPlayerOut = event.texto.includes('{jog2')
    const needsTeam = needsPlayer || event.texto.includes('{eq')

    if (needsTeam && !eventInfo.selectedTeam) {
      dispatch(errorActions.alertError(f({ id: 'events.needsTeam' })))
      return
    }

    if (needsPlayerOut && (eventInfo.selectedPlayer == null)) {
      dispatch(errorActions.alertError(f({ id: 'events.needsPlayerOut' })))
      return
    }

    if (needsPlayer && (eventInfo.selectedPlayer == null)) {
      dispatch(errorActions.alertError(f({ id: 'events.needsPlayer' })))
      return
    }

    handleInsert(
      commentText,
      event,
      eventInfo.selectedTeam,
      eventInfo.selectedPlayer,
      eventInfo.selectedPlayerOut,
      eventInfo.minute,
      eventInfo.minuteExtra,
      eventInfo.ignoreMinute,
      eventInfo.createPlaymakerStats,
      generateVodafoneTimestamp(isVodafoneEnabled, eventInfo.vodafoneTimestampMinute, eventInfo.vodafoneTimestampSecond),
      eventInfo.hasSelectedCoach,
      eventInfo.selectedUniformId,
    )
    setShowEvents(false)
    setCommentBox('')
    setEventInfo({
      minuto: clock.minutos + ((clock.seconds > 0 && extra === 0) ? 1 : 0),
      minutoExtra: extra + ((clock.seconds > 0 && extra !== 0) ? 1 : 0),
      selectedEvent: null,
      selectedTeam: null,
      selectedPlayer: null,
      selectedPlayerOut: null,
      vodafoneTimestampMinute: '',
      vodafoneTimestampSecond: '',
      ignoreMinute: false,
      createPlaymakerStats: false,
      forceMinute: null,
      forceHalf: null,
      hasSelectedCoach: false,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    // Video or photo
    if (uploaderEventsIds.includes(eventInfo.selectedEvent)) {
      // Photo
      if (eventInfo.selectedEvent === uploaderEventsIds[1]) {
        eventsService.postUploadPhoto(matchInfo.id, thumbnail)
          .then((res) => {
            eventsService.deleteUploadPhoto(matchInfo.id, res.data.data.local_url)
            handleSubmitComment(`${res.data.data.url}|${commentBox !== '' ? commentBox : ''}`)
          })
      }
      deletedThumbnail.current = true
      setThumbnail('')
    } else {
      handleSubmitComment(commentBox)
    }
  }

  useEffect(() => {
    if (eventInfo.selectedEvent) {
      let event = events.find((element) => parseInt(element.id) === eventInfo.selectedEvent);
      let commentText = event.texto
        .replaceAll('{arbitro}', matchInfo.referee)
        .replaceAll('{sigla1}', acronyms[0] || '')
        .replaceAll('{sigla2}', acronyms[1] || '')
        .replace('{home_team}', matchInfo.homeTeam)
        .replace('{away_team}', matchInfo.awayTeam)
        .replace('{home_absences}', matchInfo.homeAbsences?.length > 0 ? matchInfo.homeAbsences.map((player) => player.name).join(', ') : '[' + f({ id: 'team.noAbsences' }) + ']')
        .replace('{away_absences}', matchInfo.awayAbsences?.length > 0 ? matchInfo.awayAbsences.map((player) => player.name).join(', ') : '[' + f({ id: 'team.noAbsences' }) + ']')

      if (matchInfo.homeTeamClassif && matchInfo.awayTeamClassif) {
        commentText = commentText
          .replace('{classif_points1}', matchInfo.homeTeamClassif?.points)
          .replace('{classif_position1}', matchInfo.homeTeamClassif?.position)
          .replace('{classif_points2}', matchInfo.awayTeamClassif?.points)
          .replace('{classif_position2}', matchInfo.awayTeamClassif?.position)
      }

      setCommentBox(commentText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo.selectedEvent])

  useEffect(() => {
    const event = events.find(
      (element) => parseInt(element.id) === eventInfo.selectedEvent,
    )
    const homeTeamSelected =
      eventInfo.selectedTeam === parseInt(matchInfo.homeID)
    const awayTeamSelected =
      eventInfo.selectedTeam === parseInt(matchInfo.awayID)

    if (homeTeamSelected) {
      let starters = ''
      let benched = ''
      matchInfo.homePlayers.forEach((player, i) => {
        if (player.starter) {
          starters += (starters === '' ? '' : ', ') + player.player_name
        } else {
          benched += (benched === '' ? '' : ', ') + player.player_name
        }
      })
      setCommentBox(
        event.texto
          .replace(/\{eq\}/g, matchInfo.homeTeam)
          .replace(/\{o%eq\}/g, matchInfo.homeArticle)
          .replace(/\{sigla\}/g, acronyms[0] || '')
          .replace(/\{eq2\}/g, matchInfo.homeTeam)
          .replace(/\{o%eq2\}/g, matchInfo.homeArticle)
          .replace(/\{players_start_list\}/g, starters)
          .replace(/\{players_bench_list\}/g, benched)
          .replace('  ', ' '),
      )
    }

    if (awayTeamSelected) {
      let starters = ''
      let benched = ''
      matchInfo.awayPlayers.forEach((player, i) => {
        if (player.starter) {
          starters += (starters === '' ? '' : ', ') + player.player_name
        } else {
          benched += (benched === '' ? '' : ', ') + player.player_name
        }
      })
      setCommentBox(
        event.texto
          .replace(/\{eq\}/g, matchInfo.awayTeam)
          .replace(/\{o%eq\}/g, matchInfo.awayArticle)
          .replace(/\{sigla\}/g, acronyms[1] || '')
          .replace(/\{eq2\}/g, matchInfo.awayTeam)
          .replace(/\{o%eq2\}/g, matchInfo.awayArticle)
          .replace(/\{players_start_list\}/g, starters)
          .replace(/\{players_bench_list\}/g, benched)
          .replace('  ', ' '),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo.selectedTeam])

  useEffect(() => {
    if (eventInfo.selectedUniformId) {
      const text = commentBox.replace('{uniform}', eventInfo.selectedUniformId);
      setCommentBox(text)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo.selectedUniformId])

  useEffect(() => {
    const event = events.find(
      (element) => parseInt(element.id) === eventInfo.selectedEvent,
    )

    const ownGoalEventID = '7'
    let isOwnGoalEvent
    if (event) {
      isOwnGoalEvent = event.id === ownGoalEventID
    }

    const homeTeamSelected =
      eventInfo.selectedTeam === parseInt(matchInfo.homeID)
    const awayTeamSelected =
      eventInfo.selectedTeam === parseInt(matchInfo.awayID)

    let players = null

    if (homeTeamSelected) {
      if (isOwnGoalEvent) players = matchInfo.awayPlayers
      else players = homeCoach ? [...matchInfo.homePlayers, homeCoach] : matchInfo.homePlayers
    } else if (awayTeamSelected) {
      if (isOwnGoalEvent) players = matchInfo.homePlayers
      else players = awayCoach ? [...matchInfo.awayPlayers, awayCoach] : matchInfo.awayPlayers
    }

    if (players) {
      players = [{ id: 0, number: 0, player_name: 'Jogador Desconhecido' }, ...players]

      const player = players.find(
        (element) => parseInt(element.id) === eventInfo.selectedPlayer,
      )

      const playerOut = players.find(
        (element) => parseInt(element.id) === eventInfo.selectedPlayerOut,
      )

      const team = homeTeamSelected ? matchInfo.homeTeam : matchInfo.awayTeam
      const abrev = homeTeamSelected ? acronyms[0] : acronyms[1]
      const article = homeTeamSelected ? matchInfo.homeArticle : matchInfo.awayArticle

      setCommentBox(
        event.texto
          .replace(/\{eq\}/g, team)
          .replace(/\{o%eq\}/g, article)
          .replace(/\{eq2\}/g, team)
          .replace(/\{o%eq2\}/g, article)
          .replace(/\{sigla\}/g, abrev || '')
          .replace(/\{jog1\}/g, player ? player.player_name : '{jog1}')
          .replace(/\{jog2\}/g, playerOut ? playerOut.player_name : '{jog2}')
          .replace('  ', ' '),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo.selectedPlayer, eventInfo.selectedPlayerOut])

  useEffect(() => {
    setEventsOpen(showEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEvents])

  useEffect(() => {
    if (thumbnail === '') {
      deletedThumbnail.current = false
    }
    return () => {
      if (!deletedThumbnail.current) {
        deleteTmpPhoto()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnail])

  return (
    <div className={styles.formWrapper}>
      {showEvents ? (
        <Card.Footer
          className={classNames(styles.show_events, styles.cardFooter)}
        >
          <Events
            events={eventsToShow}
            setEvents={setEvents}
            eventsCategories={eventsCategories}
            matchInfo={matchInfo}
            homeCoach={homeCoach}
            awayCoach={awayCoach}
            eventInfo={eventInfo}
            setEventInfo={setEventInfo}
            setCommentBox={setCommentBox}
            isVodafoneEnabled={isVodafoneEnabled}
            halfTimes={halfTimes}
            halfs={halfs}
            handleCloseForm={handleCloseForm}
            uploaderEventsIds={uploaderEventsIds}
            setThumbnail={setThumbnail}
          />
        </Card.Footer>
      ) : null}
      {canOpenTab && (
        <Card.Footer className={styles.cardFooter}>
          {thumbnail !== '' && uploaderEventsIds.includes(eventInfo.selectedEvent) && (
            <div style={{ backgroundImage: `url(${thumbnail})` }} className={styles.thumbnail} />
          )}
          <div className={classNames('input-group', styles.parent_size)}>
            <div
              data-testid="clickEvents"
              onClick={handleClick}
              className={classNames(styles.div_ex_plus, 'input-group-append')}
            >
              {showEvents ? (
                <img
                  src={closeAppsIcon}
                  alt="CloseIcon"
                  data-testid="showEventsTrue"
                  className={styles.pointer}
                ></img>
              ) : (
                <img
                  src={openAppsIcon}
                  alt="OpenIcon"
                  data-testid="showEventsFalse"
                  className={styles.pointer}
                ></img>
              )}
            </div>

            <TextareaAutosize
              data-testid="textArea"
              style={showEvents ? { maxHeight: 150 } : { maxHeight: 450 }}
              minRows={1}
              value={uploaderEventsIds.includes(eventInfo.selectedEvent) && !zzMember ? f({ id: 'fileUploader.title' }) : commentBox}
              id="message"
              name=""
              className={classNames('form-control', styles.type_cmt)}
              onKeyDown={(e) => {
                if (!shiftPressed && e.keyCode === 16) {
                  setShiftPressed(true)
                }
                if (e.keyCode === 13 && !shiftPressed) {
                  handleSubmit(e)
                }
              }}
              onKeyUp={(e) => {
                if (shiftPressed && e.keyCode === 16) {
                  setShiftPressed(false)
                }
              }}
              onChange={(e) => {
                if (!uploaderEventsIds.includes(eventInfo.selectedEvent) || zzMember) {
                  handleChange(e)
                }
              }}
              placeholder={f({ id: 'match.commentInitText' })}
            />
            <div className="input-group-append">
              <span
                className={classNames('input-group-text', styles.add_comment_btn)}
                onClick={handleSubmit}
                data-testid="submit"
              >
                <img
                  src={sendIcon}
                  alt="SendIcon"
                  className={classNames(styles.send_arrow)}
                ></img>
              </span>
            </div>
          </div>
        </Card.Footer>
      )}
    </div>
  )
}

CommentsForm.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  setEventInfo: PropTypes.func.isRequired,
  showEvents: PropTypes.bool.isRequired,
  setShowEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
  eventsCategories: PropTypes.array.isRequired,
  handleInsert: PropTypes.func.isRequired,
  matchInfo: PropTypes.object.isRequired,
  homeCoach: PropTypes.object,
  awayCoach: PropTypes.object,
  clock: PropTypes.object.isRequired,
  acronyms: PropTypes.array.isRequired,
  setEventsOpen: PropTypes.func,
  extra: PropTypes.number.isRequired,
  isVodafoneEnabled: PropTypes.bool,
  halfTimes: PropTypes.array.isRequired,
  halfs: PropTypes.array.isRequired,
  uploaderEventsIds: PropTypes.array.isRequired,
  canOpenTab: PropTypes.bool,
}

export default CommentsForm
