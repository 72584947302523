import { combineReducers } from 'redux'
import auth from './authReducer'
import locale from './localeReducer'
import error from './errorReducer'
import matchSetup from './matchSetupReducer'
import loading from './loadingReducer'
import requests from './requestsReducer'
import notifications from './notificationsReducer'

export default combineReducers({
  auth,
  locale,
  error,
  matchSetup,
  loading,
  requests,
  notifications,
})
