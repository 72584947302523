import React from 'react'
import PropTypes from 'prop-types'
import styles from './Events.module.scss'
import { Col } from 'react-bootstrap'

import { decodeHTML } from '../../helpers/htmlEntities'

const UniformEvent = ({ id: ID, name: Name, icon: Icon, clickEvent, selected }) => {
  return (
    <>
      <Col className={styles.eventCard}>
        <div className={styles.circle + (selected ? ' ' + styles.selected : '')} data-testid={'circle-' + ID} onClick={() => {
          clickEvent(ID)
        }}>
          <div className={styles.uniform} dangerouslySetInnerHTML={{ __html: Icon }}/>
        </div>
        <span className={styles.eventName}>
          {decodeHTML(Name)}
        </span>
      </Col>
    </>
  )
}

UniformEvent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  clickEvent: PropTypes.func.isRequired,
  selected: PropTypes.bool,
}

export default UniformEvent
