import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import TextareaAutosize from 'react-textarea-autosize'
import styles from './EventEditor.module.scss'
import Modal from 'react-bootstrap/Modal'
import { Button, Dropdown, Form } from 'react-bootstrap'
import classNames from 'classnames'
import deleteIcon from '../../assets/eventEditorIcons/deleteIcon.svg'
import closeIcon from '../../assets/eventEditorIcons/closeIcon.svg'
import PropTypes from 'prop-types'
import ClockInput from '../Events/ClockInput'
import Comment from '../Comments/Comment'
import { generateVodafoneTimestamp } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import CatalogGoalForm from '../CatalogGoalForm/CatalogGoalForm'
import CatalogPenForm from '../CatalogPenForm/CatalogPenForm'
import SubstitutionsForm from '../SubstitutionsForm/SubstitutionsForm'

// categorias
// 1- golos
// 2- cartoes
// 4 - substituiçao

const goalId = "1"
const substitutionId = "5"
const zerozeroVideosId = "28"
const shirtsId = "109"

const EventEditor = ({ show, onHide, id, event, handleDelete, handleEdit, matchInfo, events, clock, isVodafoneEnabled, halfTimes, halfs, catalogOptions, uploaderEventsIds }) => {
  const { formatMessage: f } = useIntl()
  const [textBox, setTextBox] = useState('')
  const [edited, setEdited] = useState(false)
  const [minuteEdit, setMinuteEdit] = useState(0)
  const [minuteExtraEdit, setMinuteExtraEdit] = useState(0)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [vodafoneTimestampMinuteEdit, setVodafoneTimestampMinuteEdit] = useState('')
  const [vodafoneTimestampSecondEdit, setVodafoneTimestampSecondEdit] = useState('')
  const [ignorarMinuto, setIgnorarMinuto] = useState()
  const [canEdit, setCanEdit] = useState(useSelector((state) => state.auth.zz_member))
  const [needsCatalog, setNeedsCatalog] = useState(false)
  const [needsCatalogPen, setNeedsCatalogPen] = useState(false)
  const [goalCatalog, setGoalCatalog] = useState({
    origin: '0',
    zone: '0',
    bodyPart: '0',
    playerAssist: '0'
  })
  const [penCatalog, setPenCatalog] = useState({
    playerSuffered: '0',
    playerCommited: '0',
  })
  const [penOptions, setPenOptions] = useState({})
  const [substitutionCatalog, setSubstitutionCatlog] = useState({
    playerIn: '0',
    playerOut: '0'
  })
  const [playersInOptions, setPlayersInOptions] = useState([])
  const [playersOutOptions, setPlayersOutOptions] = useState([])
  const [teamId1, setTeamId1] = useState('')
  const [teamId2, setTeamId2] = useState('')
  const [eventTypeId, setEventTypeId] = useState('')
  const [eventTypeName, setEventTypeName] = useState('')
  const [canChangeEventType, setCanChangeEventType] = useState(false)
  const [eventTypeNameBackup, setEventTypeNameBackup] = useState('')
  const zzMember = useSelector((state) => state.auth.zz_member)

  useEffect(() => {
    if (event.text) {
      setTextBox(event.text)
    }
    if (event.minute) {
      setMinuteEdit(parseInt(event.minute))
    }
    if (event.minute_extra) {
      setMinuteExtraEdit(parseInt(event.minute_extra))
    }
    if (event.ignore_minute) {
      setIgnorarMinuto(event.ignore_minute)
    }

    if (event.vodafone_clock_time) {
      setVodafoneTimestampMinuteEdit(event.vodafone_clock_time.split(':')[0])
      setVodafoneTimestampSecondEdit(event.vodafone_clock_time.split(':')[1])
    } else {
      setVodafoneTimestampMinuteEdit('')
      setVodafoneTimestampSecondEdit('')
    }

    if (event.fk_live_tpevent) {
      setEventTypeId(event.fk_live_tpevent)
    }

    if (events) {
      const foundEvent = events.find((e) => e.id === event.fk_live_tpevent)
      const eventType = foundEvent?.abrev
      const isMedia = uploaderEventsIds.includes(parseInt(foundEvent?.id))
      setCanChangeEventType(!isMedia && foundEvent && foundEvent?.categoria !== '2' && foundEvent?.categoria !== '3' && foundEvent?.categoria !== '4' && !foundEvent?.texto && zzMember)
      setEventTypeName(eventType)
      setEventTypeNameBackup(eventType)
      const array = []
      for (let i = 0; i < events.length; i++) {
        if (events[i].categoria === '1' || events[i].categoria === '2' || events[i].categoria === '4') { // golos, cartoes, substituiçoes
          array.push(events[i].id)
          array.push(events[i].abrev)
        }
      }
      setCanEdit((zzMember || !(array.some(c => (c === event.fk_live_tpevent)))) && event.fk_live_tpevent !== zerozeroVideosId && event.fk_live_tpevent !== shirtsId && !isMedia)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, events, zzMember])

  useEffect(() => {
    setGoalCatalog({
      origin: event?.origem === '' ? '0' : event.origem,
      zone: event?.zona === '' ? '0' : event.zona,
      bodyPart: event?.corpo === '' ? '0' : event.corpo,
      playerAssist: event?.fk_jogador_assist === '' ? '0' : event.fk_jogador_assist
    })
    setPenCatalog({
      playerSuffered: event?.fk_jogador_sp === '' ? '0' : event.fk_jogador_sp,
      playerCommited: event?.fk_jogador_cp === '' ? '0' : event.fk_jogador_cp
    })
    setSubstitutionCatlog({
      playerIn: event.fk_jogador || '0',
      playerOut: event.fk_jogador_out || '0'
    })
  }, [event, show])

  useEffect(() => {
    const filtered = events.find((item) => item.id === event.fk_live_tpevent)
    setNeedsCatalog(filtered?.catalogacao === '1')
    setNeedsCatalogPen(filtered?.catalogacao_pen === '1')
    if (filtered?.catalogacao_pen === '1') {
      setGoalCatalog((goalCatalog) => {
        return {
          ...goalCatalog,
          origin: '1',
          zone: '2'
        }
      })
    }

    setTeamId1(event.fk_equipa)
    setTeamId2(event.fk_equipa === matchInfo.homeID ? matchInfo.awayID : matchInfo.homeID)

    const options = {}
    options[matchInfo.homeID] = matchInfo.homePlayers
    options[matchInfo.awayID] = matchInfo.awayPlayers
    setPenOptions(options)

    const playersOptions = event.fk_equipa === matchInfo.homeID ? matchInfo?.homePlayers : matchInfo?.awayPlayers
    setPlayersInOptions(playersOptions?.filter((item) => item.can_enter || item.id === event.fk_jogador))
    setPlayersOutOptions(playersOptions?.filter((item) => (!item.out || item.id === event.fk_jogador_out) && item.id !== event.fk_jogador))
  }, [events, event, matchInfo])

  const handleChange = (e) => {
    if (!edited) { setEdited(true) }
    setTextBox(e.target.value)
  }

  const handleVodafoneMinuteChange = (e) => {
    if (!isVodafoneEnabled) return
    setEdited(true)
    const vodafoneTimestampMinute = e.target.value
    setVodafoneTimestampMinuteEdit(vodafoneTimestampMinute)
  }

  const handleVodafoneSecondChange = (e) => {
    if (!isVodafoneEnabled) return
    setEdited(true)
    const vodafoneTimestampSecond = e.target.value
    setVodafoneTimestampSecondEdit(vodafoneTimestampSecond)
  }

  const handleDeleteButton = () => {
    setShowDeleteModal(true)
  }

  const handleCancelDelete = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteEvent = () => {
    handleDelete(id)
    onHide()
    setShowDeleteModal(false)
  }

  const handleEditEvent = () => {
    if (edited) {
      event.fk_live_tpevent = eventTypeId
      if (event.fk_live_tpevent === substitutionId) {
        event.fk_jogador = substitutionCatalog.playerIn
        event.fk_jogador_out = substitutionCatalog.playerOut
      }
      handleEdit(
        event,
        textBox,
        String(minuteEdit),
        String(minuteExtraEdit),
        generateVodafoneTimestamp(isVodafoneEnabled, String(vodafoneTimestampMinuteEdit), String(vodafoneTimestampSecondEdit)),
        ignorarMinuto,
        goalCatalog,
        penCatalog,
      )
    }
    onHide()
    setShowDeleteModal(false)
  }

  const onChangeGoalOrigin = (e) => {
    if (!edited) { setEdited(true) }
    setGoalCatalog({
      ...goalCatalog,
      origin: e.target.value
    })
  }

  const onChangeGoalZone = (e) => {
    if (!edited) { setEdited(true) }
    setGoalCatalog({
      ...goalCatalog,
      zone: e.target.value
    })
  }

  const onChangeGoalBodyPart = (e) => {
    if (!edited) { setEdited(true) }
    setGoalCatalog({
      ...goalCatalog,
      bodyPart: e.target.value
    })
  }

  const onChangePlayerAssist = (e) => {
    if (!edited) { setEdited(true) }
    setGoalCatalog({
      ...goalCatalog,
      playerAssist: e.target.value
    })
  }

  const onChangePlayerSufferedPen = (e) => {
    if (!edited) { setEdited(true) }
    setPenCatalog({
      ...penCatalog,
      playerSuffered: e.target.value
    })
  }

  const onChangePlayerCommitedPen = (e) => {
    if (!edited) { setEdited(true) }
    setPenCatalog({
      ...penCatalog,
      playerCommited: e.target.value
    })
  }

  const onChangeEventType = (e) => {
    if (!edited) setEdited(true)
    setEventTypeId(e.id)
    setEventTypeName(e.abrev)
  }

  const onChangePlayerIn = (e) => {
    if (!edited) { setEdited(true) }

    setSubstitutionCatlog({
      ...substitutionCatalog,
      playerIn: e.target.value
    })

    const foundEvent = events.find((item) => item.id === event.fk_live_tpevent)
    const playersOptions = event.fk_equipa === matchInfo.homeID ? matchInfo?.homePlayers : matchInfo?.awayPlayers
    const playerIn = playersOptions.find((item) => item.id === e.target.value)
    const playerOut = playersOptions.find((item) => item.id === substitutionCatalog.playerOut)
    const team = event.fk_equipa === matchInfo.homeID ? matchInfo.homeTeam : matchInfo.awayTeam
    const article = event.fk_equipa === matchInfo.homeID ? matchInfo.homeArticle : matchInfo.awayArticle
    const text = foundEvent.texto
      .replace('{eq}', team)
      .replace('{o%eq}', article)
      .replace('{jog1}', playerIn.player_name)
      .replace('{jog2}', playerOut.player_name)
      .replace('  ', ' ')
    setTextBox(text)
  }

  const onChangePlayerOut = (e) => {
    if (!edited) { setEdited(true) }

    setSubstitutionCatlog({
      ...substitutionCatalog,
      playerOut: e.target.value
    })

    const foundEvent = events.find((item) => item.id === event.fk_live_tpevent)
    const playersOptions = event.fk_equipa === matchInfo.homeID ? matchInfo?.homePlayers : matchInfo?.awayPlayers
    const playerOut = playersOptions.find((item) => item.id === e.target.value)
    const playerIn = playersOptions.find((item) => item.id === substitutionCatalog.playerIn)
    const team = event.fk_equipa === matchInfo.homeID ? matchInfo.homeTeam : matchInfo.awayTeam
    const article = event.fk_equipa === matchInfo.homeID ? matchInfo.homeArticle : matchInfo.awayArticle
    const text = foundEvent.texto
      .replace('{eq}', team)
      .replace('{o%eq}', article)
      .replace('{jog1}', playerIn.player_name)
      .replace('{jog2}', playerOut.player_name)
      .replace('  ', ' ')
    setTextBox(text)
  }

  if (showDeleteModal) {
    return (
      <Modal show={show} onHide={() => { onHide(); setShowDeleteModal(false) }} animation={true} className={styles.modal}>
        <Modal.Body>
          <div className={styles.confirmDelete}>
            <span>{f({ id: 'match.edit.confirmDelete' }) + '\n' + f({ id: 'match.edit.irreversible' })}</span>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.confirmDeleteFooter}>
          <Button
            variant="outline-primary"
            className={classNames(styles.cancelButton)}
            onClick={handleCancelDelete}
          >
            {f({ id: 'match.edit.cancel' })}
          </Button>
          <Button
            data-testid="confirmDeleteButton"
            className={classNames(styles.deleteButton)}
            onClick={handleDeleteEvent}
          >
            {f({ id: 'match.edit.delete' })}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const eventsFiltered = events.filter((e) => e.categoria !== '2' && e.categoria !== '3' && e.categoria !== '4' && !e.texto).sort((e1, e2) => e1.abrev > e2.abrev ? 1 : -1)

  return (
    <Modal show={show} onHide={() => { onHide(); setShowDeleteModal(false); setEventTypeName(eventTypeNameBackup); }} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'match.edit.title' })}{' '}
        </Modal.Title>
        {canChangeEventType && (
          <Dropdown className='ml-auto'>
            <Dropdown.Toggle className={styles.eventTypeDropdownBtn}>{eventTypeName}</Dropdown.Toggle>
            <Dropdown.Menu className={styles.eventTypeDropdown}>
              {eventsFiltered.map((e, key) => (
                <Dropdown.Item key={key} onClick={() => onChangeEventType(e)}>{e.abrev}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <img
          data-testid="deleteButton"
          alt='delete button'
          title="delete"
          src={deleteIcon}
          className={classNames(styles.delete, styles.header_icon, styles.cursor_on, canChangeEventType ? '' : 'ml-auto')}
          onClick={handleDeleteButton}
        />
        <img
          data-testid="closeButton"
          alt='close button'
          src={closeIcon}
          className={classNames(
            styles.header_icon,
            styles.cursor_on,
            styles.close_btn,
          )}
          onClick={() => { onHide(); setShowDeleteModal(false); setEventTypeName(eventTypeNameBackup); }}
        />
      </Modal.Header>
      <Modal.Body>
        {canEdit
          ? <TextareaAutosize
            data-testid="textArea"
            style={{ maxHeight: 115, fontSize: '16px' }}
            minRows={1}
            value={textBox}
            id="message"
            className={classNames('form-control', styles.type_edit)}
            onChange={handleChange}
            placeholder={f({ id: 'match.commentInitText' })}
          />
          : <Comment
            matchInfo={matchInfo}
            comment={event}
            events={events}
            canEdit={false}
          />
        }
        {event.fk_live_tpevent === substitutionId && (
          <SubstitutionsForm
            playerIn={substitutionCatalog.playerIn}
            playerOut={substitutionCatalog.playerOut}
            playersInOptions={playersInOptions}
            playersOutOptions={playersOutOptions}
            onChangePlayerIn={onChangePlayerIn}
            onChangePlayerOut={onChangePlayerOut}
          />
        )}
        {needsCatalog && (
          <CatalogGoalForm
            isPen={needsCatalogPen}
            isGoalEvent={eventTypeId === goalId}
            origin={goalCatalog.origin}
            zone={goalCatalog.zone}
            bodyPart={goalCatalog.bodyPart}
            playerAssist={goalCatalog.playerAssist}
            options={catalogOptions}
            assistOptions={penOptions.hasOwnProperty(event?.fk_equipa) ? penOptions[event.fk_equipa].filter((player) => player.id !== event.fk_jogador) : []}
            onChangeOrigin={onChangeGoalOrigin}
            onChangeZone={onChangeGoalZone}
            onChangeBodyPart={onChangeGoalBodyPart}
            onChangePlayerAssist={onChangePlayerAssist}
            noClock={matchInfo.no_clock}
          />
        )}
        {needsCatalogPen && (
          <CatalogPenForm
            playerSuffered={penCatalog.playerSuffered}
            playerCommited={penCatalog.playerCommited}
            options={penOptions}
            onChangePlayerSuffered={onChangePlayerSufferedPen}
            onChangePlayerCommited={onChangePlayerCommitedPen}
            teamId1={teamId1}
            teamId2={teamId2}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ClockInput
          clock={minuteEdit}
          extra={minuteExtraEdit}
          setClock={(minute, extra) => { setMinuteEdit(minute); setMinuteExtraEdit(extra); if (!edited) setEdited(true) }}
          halfTimes={halfTimes}
          clockHalf={
            clock.minutos >= halfs[2]
              ? 4
              : clock.minutos >= halfs[1]
                ? 3
                : clock.minutos >= halfs[0]
                  ? 2
                  : 1
          }
        />
        {
          isVodafoneEnabled && <div className={styles.vodafoneContainer}>
            <p>Vodafone Timestamp</p>
            <input
              type="number"
              pattern="[0-9]*"
              onChange={handleVodafoneMinuteChange}
              value={vodafoneTimestampMinuteEdit}
              placeholder="Min"
              className={styles.vodafone_input}
            />
            {':'}
            <input
              type="number"
              pattern="[0-9]*"
              onChange={handleVodafoneSecondChange}
              value={vodafoneTimestampSecondEdit}
              className={styles.vodafone_input}
              placeholder="Seg"
            />
          </div>
        }
        <div className={styles.ignoreMinuteContainer}>
          <Form.Check
            type="checkbox"
            label={f({ id: 'ignoreMinute' })}
            checked={ignorarMinuto === '1' ? 1 : 0}
            onChange={(e) => { setIgnorarMinuto(e.target.checked ? '1' : '0'); if (!edited) setEdited(true) }} />
        </div>
        <Button
          data-testid="submitEdit"
          className={classNames(styles.save_btn)}
          onClick={handleEditEvent}
        >
          {f({ id: 'match.edit.save' })}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

EventEditor.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isVodafoneEnabled: PropTypes.bool,
  catalogOptions: PropTypes.object.isRequired,
  uploaderEventsIds: PropTypes.array.isRequired,
}

export default EventEditor
