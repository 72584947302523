import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import styles from './LineupsSaveAlert.module.scss'

const LineupsSaveAlert = ({ title, text, onDismiss }) => {
  return (
    <div
      className={classNames(
        styles.container,
        !title && !text ? styles.hidden : '',
      )}
    >
      <div className={styles.title}>
        <span>{title}</span>
        <button className={styles.dismiss} onClick={onDismiss}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

LineupsSaveAlert.defaultProps = {
  title: null,
  text: null,
  onDismiss: () => {},
}

LineupsSaveAlert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
}

export default LineupsSaveAlert
