import React from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

const SubstitutionsForm = ({ playerIn, playerOut, playersInOptions = [], playersOutOptions = [], onChangePlayerIn, onChangePlayerOut }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Form className='p-2'>
      <fieldset>
        <Form.Group>
          <Form.Label htmlFor='playerIn'>{f({ id: 'substitutions.playerIn' })}</Form.Label>
          <Form.Control as="select" id="playerIn" value={playerIn} onChange={onChangePlayerIn}>
            {playersInOptions.map((item) =>
              <option key={item.id} value={item.id}>{item.player_name}</option>,
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='playerOut'>{f({ id: 'substitutions.playerOut' })}</Form.Label>
          <Form.Control as="select" id="playerOut" value={playerOut} onChange={onChangePlayerOut}>
            {playersOutOptions.map((item) =>
              <option key={item.id} value={item.id}>{item.player_name}</option>,
            )}
          </Form.Control>
        </Form.Group>
      </fieldset>
    </Form>
  )
}

SubstitutionsForm.propTypes = {
  playerIn: PropTypes.string.isRequired,
  playerOut: PropTypes.string.isRequired,
  playersInOptions: PropTypes.array,
  playersOutOptions: PropTypes.array,
  onChangePlayerIn: PropTypes.func.isRequired,
  onChangePlayerOut: PropTypes.func.isRequired,
}

export default SubstitutionsForm
