import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import Autocomplete from '../Autocomplete'
import { errorActions } from '../../actions/errorActions'

const filters = {
  modality: [],
  division: ['edicao_id', 'edicao_descr'],
  team: [
    'equipa_casa_id',
    'equipa_casa_descr',
    'equipa_fora_id',
    'equipa_fora_descr',
  ],
}

function FiltersEngine(props) {
  const { callback, service } = props

  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const [filtersToRender, setFiltersToRender] = useState({
    modality: {},
    division: {},
    team: {},
  })

  const [matches, setMatches] = useState({})

  const [arraySelection, setArraySelection] = useState({
    modality: [],
    division: [],
    team: [],
  })

  function handleResponse(data) {
    const noDups = array => {
      return Array.from(new Set(array.map(a => a.id)))
        .map(id => {
          return array.find(a => a.id === id)
        })
    }

    const arrayMod = []
    const arrayTeam = []
    const arrayDiv = []

    for (const mod in data) {
      const games = data[mod]

      arrayMod.push({ id: mod, name: mod })

      for (const game of games) {
        const divisionId = game[filters.division[0]]
        const divisionName = game[filters.division[1]]
        arrayDiv.push({ id: divisionId, name: divisionName })
        arrayTeam.push({ id: game[filters.team[0]], name: game[filters.team[1]] },
          { id: game[filters.team[0]], name: game[filters.team[1]] })
      }
    }

    setArraySelection({
      modality: noDups(arrayMod),
      division: noDups(arrayDiv),
      team: noDups(arrayTeam),
    })
    setMatches(data)
    callback(data)
  }

  useEffect(
    () => {
      service.matches()
        .then(data => {
          handleResponse(data)
        })
        .catch(() => {
          dispatch(
            errorActions.alertError(f({ id: 'search.couldNotLoadMatches' })),
          )
          const localEvents = localStorage.getItem('search-' + localStorage.getItem('zos-cookie'))

          if (localEvents) { handleResponse(JSON.parse(localEvents)) }
        })
    },
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    const { modality, team, division } = filtersToRender
    const filtering = {}

    function filterFunc(elem) {
      const div = !division.id
      const t = !team.id

      return (div || elem[filters.division[0]] === division.id) &&
        (t || (elem[filters.team[0]] === team.id || elem[filters.team[2]] === team.id))
    }

    if (!modality.name) {
      for (const mod in matches) {
        filtering[mod] = matches[mod].filter(filterFunc)
      }
    } else {
      filtering[modality.name] = matches[modality.name].filter(filterFunc)
    }

    callback(filtering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersToRender, matches])

  const update = (field) => {
    const { type, id, name } = field
    setFiltersToRender(state => ({ ...state, [type]: { name, id } }))
  }

  return (
    <>
      {
        Object.keys(arraySelection).map((element, index) => {
          return (
            <Autocomplete
              key={index}
              id={element}
              selected={field => update(field)}
              options={arraySelection[element]}
            />

          )
        })

      }

    </>
  )
}

FiltersEngine.propTypes = {
  callback: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
}

export default FiltersEngine
