import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Placeholder from '../Placeholder'
import PlayerInfo from '../PlayerInfo'
import { teamTypeConstants } from '../Team'
import noPhoto from '../../assets/lineups/no_photo.png'

import styles from './PlayerGroup.module.scss'

export const playerGroupConstants = {
  MAIN: 'main',
  SUBSTITUTES: 'substitutes',
  ALL: 'all',
}

const PlayerGroup = ({ teamType, groupType, handleClick, maxNrPlayers, onSaveNumber, type }) => {
  const { formatMessage: f } = useIntl()
  const { home, away, info } = useSelector((state) => state.matchSetup)

  const group = teamType === teamTypeConstants.HOME ? home[groupType] : away[groupType]
  const absentPlayers = (teamType === teamTypeConstants.HOME ? info.home_absences : info.away_absences)?.map((item) => item.id)

  return (
    <div
      className={styles.playerGroupWrapper}
      data-testid={`${teamType}-${groupType}`}
    >
      <div
        className={classNames(
          styles.playerGroupHeader,
          styles[`playerGroupHeader-${groupType}`],
        )}
      >
        <p>{f({ id: `matchSetup.${groupType}` })}</p>
        {groupType === playerGroupConstants.MAIN && (
          <p>
            {group && group.length ? group.length : '0'}/{maxNrPlayers}
          </p>
        )}
      </div>
      <div>
        {group && group.length > 0 ? (
          group.map((player) => {
            if (!player.selected || type !== 'lineups') {
              return (
                <div
                  className={styles.playerGroupInfoWrapper}
                  key={`${teamType}-${groupType}-player-${player.id ? player.id : `${player.name}-${player.number}`
                    }`}
                >
                  <PlayerInfo
                    meta={{ id: player.id, team: teamType, group: groupType, matchId: info.id }}
                    info={{
                      id: player.id,
                      name: player.name,
                      flag: player.flag,
                      number: player.number,
                      position: player.position,
                      position_id: player.position_id,
                      subposition_id: player.subposition_id,
                      backup_position_id: player.backup_position_id,
                      backup_subposition_id: player.backup_subposition_id,
                      photo: player.photo ? player.photo : noPhoto,
                      mainPercent: parseInt(player.mainPercent),
                      benchPercent: parseInt(player.benchPercent),
                      selected: player.selected,
                      positioned: player.positioned,
                      positionedTactic: player.positionedTactic,
                      absent: absentPlayers ? absentPlayers.includes(player.id) : false
                    }}
                    handleClick={handleClick}
                    onSaveNumber={onSaveNumber}
                    onPressCallbackArgs={[player.id, teamType, groupType]}
                    type={type}
                  />
                </div>
              )
            }
            return ''
          })
        ) : (
          <div className={styles.playerGroupCol}>
            <Placeholder />
          </div>
        )}
      </div>
    </div>
  )
}

PlayerGroup.propTypes = {
  teamType: PropTypes.string.isRequired, // home or away
  groupType: PropTypes.string.isRequired, // main, substitutes or all
  handleClick: PropTypes.func.isRequired,
  onSaveNumber: PropTypes.func.isRequired,
}

export default PlayerGroup
