// import { formatRelativeWithOptions } from 'date-fns/esm/fp'
import { authConstants } from '../actions/authActions'

const cookie = localStorage.getItem('zos-cookie')
const zzMember = localStorage.getItem('user-zz-member')
const initialState = cookie
  ? { loggedIn: true, cookie, zz_member: zzMember }
  : { loggedIn: false, cookie: null, zz_member: false }

const auth = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      }
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        cookie: action.payload.cookie,
        zz_member: action.payload.zz_member === '1',
      }
    case authConstants.LOGIN_FAILURE:
      return { loggedIn: false, cookie: null, zz_member: false }
    case authConstants.LOGOUT:
      return { loggedIn: false, cookie: null, zz_member: false }
    default:
      return state
  }
}

export default auth
