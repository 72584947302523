export const generateVodafoneTimestamp = (isVodafoneEnabled, vodafoneTimestampMinute = '', vodafoneTimestampSecond = '') => {
  if (!isVodafoneEnabled) return ''

  if (vodafoneTimestampMinute === '' && vodafoneTimestampSecond === '') return ''

  // If at least one of them is non empty, put the other as "0"
  if (vodafoneTimestampMinute !== '' && vodafoneTimestampSecond === '') vodafoneTimestampSecond = '0'
  if (vodafoneTimestampMinute === '' && vodafoneTimestampSecond !== '') vodafoneTimestampMinute = '0'

  const min = numberToClockTime(vodafoneTimestampMinute)
  const sec = numberToClockTime(vodafoneTimestampSecond)
  return `${min}:${sec}`
}

export const generateTimestamp = (minutes, seconds, extra) => {
  const minutesStr = numberToClockTime(minutes)
  const secondsStr = numberToClockTime(seconds)
  const extraStr = numberToClockTime(parseInt(extra) - 1)
  const timeString = !extra || extra === 0
    ? `${minutesStr}:${secondsStr}`
    : `${minutesStr}' +${extraStr}:${secondsStr}`

  return timeString
}

const numberToClockTime = (number) => {
  return parseInt(number).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
}

/**
 * Calculates the boundary values the clock can have at different stages of the game
 * @returns a list with the minimum and maximum allowed minute values.
 */
export const getBoundaryValues = (state, totalDuration, extraTimePartDuration) => {
  switch (state) {
    case 'parte1':
      return [0, totalDuration / 2]
    case 'int':
      return [totalDuration / 2, totalDuration / 2]
    case 'parte2':
      return [totalDuration / 2, totalDuration]
    case 'int2p':
      return [totalDuration, totalDuration]
    case 'extra_parte1':
      return [totalDuration, totalDuration + extraTimePartDuration]
    case 'intet':
      return [totalDuration + extraTimePartDuration, totalDuration + 2 * extraTimePartDuration]
    case 'extra_parte2':
      return [totalDuration + extraTimePartDuration, totalDuration + 2 * extraTimePartDuration]
    case 'fin':
      return [totalDuration, totalDuration + 2 * extraTimePartDuration]
    default:
      return 0
  }
}
