import { loadingActions } from '../actions/loadingActions'
import { notificationsActions } from '../actions/notificationsActions'

const customAsyncRequest = (
  callback,
  requestType,
  successType,
  failureType,
  cacheId, // null if no caching necessary
  ...callbackArgs
) => {
  return (dispatch) => {
    dispatch(request())
    dispatch(loadingActions.addRequest())

    const dismissNotification = (id) => {
      dispatch(notificationsActions.removeNotification(id))
    }

    // eslint-disable-next-line
    callback(...callbackArgs).then(
      (ret) => {
        dispatch(loadingActions.removeRequest())
        if (cacheId) {
          localStorage.setItem(cacheId, JSON.stringify(ret))
        }
        dispatch(success(ret))
      },
      (error) => {
        const id = `${Date.now() + Math.random()}`
        dispatch(
          notificationsActions.addNotification(id, 'error', error, 'error'),
        )
        setTimeout(dismissNotification, 4000, id)
        dispatch(loadingActions.removeRequest())
        dispatch(failure(error))
      },
    )
  }
  function request() {
    return { type: requestType }
  }
  function success(ret) {
    return {
      type: successType,
      payload: ret,
    }
  }
  function failure(error) {
    return {
      type: failureType,
      payload: error,
    }
  }
}

export const actionUtils = {
  customAsyncRequest,
}
