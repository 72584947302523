import { feedbackService } from '../services/feedbackService'

export const feedbackConstants = {
  POST_FEEDBACK: 'POST_FEEDBACK',
  POST_FEEDBACK_COMMIT: 'POST_FEEDBACK_COMMIT',
  POST_FEEDBACK_ROLLBACK: 'POST_FEEDBACK_ROLLBACK',
}

export const feedbackActions = {
  postFeedback,
}

function postFeedback(text, callback) {
  const { url, method, body, headers } = feedbackService.preparePostFeedback(text)
  return {
    type: feedbackConstants.POST_FEEDBACK,
    payload: text,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: feedbackConstants.POST_FEEDBACK_COMMIT, meta: text, callback: callback },
        rollback: { type: feedbackConstants.POST_FEEDBACK_ROLLBACK, meta: text },
      },
    },
  }
}
