import { notificationsConstants } from '../actions/notificationsActions'

const initialState = []

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case notificationsConstants.ADD_NOTIFICATION:
      return state.concat(action.payload)
    case notificationsConstants.REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload)
    default:
      return state
  }
}

export default notifications
