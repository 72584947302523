import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'

import style from './MatchResult.module.scss'
import { useIntl } from 'react-intl'

import { LinkContainer } from 'react-router-bootstrap'

const MatchesResult = ({ matchInfo }) => {
  const { formatMessage: f } = useIntl()

  const remainingTime = (match) => {
    let time
    const timeInDays = moment(match.data_jogo).diff(moment(), 'days')

    if (timeInDays === 0) {
      const timeInHours = moment(match.data_jogo).diff(moment(), 'hours')
      const timeInHoursString = (timeInHours.toString()).substr(1)

      if (timeInHours === 0) {
        const timeInMinutes = moment(match.data_jogo).diff(moment(), 'minutes')
        const timeInMinutesString = (timeInMinutes.toString()).substr(1)

        time =
          timeInMinutesString +
          ' ' +
          f({ id: 'homepage.minutes' }) +
          (timeInMinutesString > 1 ? 's' : '') + ' ' + f({ id: 'homepage.ago' })
      } else {
        time =
          timeInHoursString +
          ' ' +
          f({ id: 'homepage.hours' }) +
          (timeInHoursString > 1 ? 's' : '') + ' ' + f({ id: 'homepage.ago' })
      }
    } else {
      const timeInDaysString = (timeInDays.toString())
      if (timeInDaysString.charAt(0) === '-') {
        time =
          timeInDaysString.substr(1) +
          ' ' +
          f({ id: 'homepage.days' }) +
          (timeInDaysString.substr(1) > 1 ? 's' : '') + ' ' + f({ id: 'homepage.ago' })
      } else {
        time =
          timeInDays +
          ' ' +
          f({ id: 'homepage.days' }) +
          (timeInDays > 1 ? 's' : '')
      }
    }

    return {
      id: match.jogo_id,
      title: `${match.equipa_casa_descr} - ${match.equipa_fora_descr}`,
      date: `${match.data_jogo}`,
      time: time,
    }
  }

  const buildResult = () => {
    return <>
      <div className={className([style.result, style.inGame])}>
        {matchInfo.golos_casa_live + '-' + matchInfo.golos_fora_live}
      </div>
      <div className={style.gameTime}>
        {matchInfo.minuto + '\''}
      </div>
    </>
  }

  return (
    <>

      <Container fluid>
        <hr className={style.divider}></hr>
        <Row className={style.match}>
          <Col className={style.infos} xs="auto" md="auto">
            <p className={className([style.matchInfo, style[matchInfo.a_decorrer === 1 ? 'inGame' : '']])}>
              {matchInfo.equipa_casa_descr +
              ' - ' +
              matchInfo.equipa_fora_descr}
            </p>

            <p className={style.matchDateInfo}>{matchInfo.data_jogo}</p>
          </Col>
          <Col className={style.time} xs="auto" md="auto">
            <div> {
              matchInfo.a_decorrer === 1 ? buildResult() : remainingTime(matchInfo).time
            }</div>
            <LinkContainer to={`/match/${matchInfo.jogo_id}/home`}>
              <button className={style.arrow}>
                <Arrow/>
              </button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  )
}

MatchesResult.propTypes = {
  matchInfo: PropTypes.object.isRequired,
}

export default MatchesResult
