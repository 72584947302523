import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import useNotification from '../../hooks/useNotification'
import Notification from '../Notification'
import { notificationsActions } from '../../actions/notificationsActions'

import styles from './NotificationsContainer.module.scss'

const NotificationsContainer = () => {
  const { notifications, dismissNotification } = useNotification(true)
  const offline = useSelector((state) => state.requests.notify)
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const onNotificationClose = (e) => {
    dismissNotification(e.target.getAttribute('id'))
  }

  const onOfflineNotificationClose = (e) => {
    dispatch(
      notificationsActions.removeOfflineNotification(
        e.target.getAttribute('id'),
      ),
    )
  }

  return (
    <div
      className={classNames(
        styles.container,
        notifications &&
          notifications.length === 0 &&
          offline &&
          offline.length === 0
          ? styles.hidden
          : '',
      )}
    >
      {notifications &&
        notifications.map((notification) => {
          if (typeof notification.text === 'string') {
            return (
              <Notification
                onClose={onNotificationClose}
                key={notification.id}
                id={notification.id}
                title={f({ id: `notifications.${notification.title}` })}
                text={notification.text}
                variant={notification.variant}
              />
            )
          }
          return ''
        })}
      {offline &&
        offline.map((off) => (
          <Notification
            onClose={onOfflineNotificationClose}
            key={off.id}
            id={off.id}
            title={f({ id: 'offlineNotifications.title' })}
            text={off.text}
            variant={off.variant}
          />
        ))}
    </div>
  )
}

export default NotificationsContainer
