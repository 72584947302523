import { createStore, applyMiddleware, compose } from 'redux'
import { offline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import thunkMiddleware from 'redux-thunk'
import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue'
import { eventsConstants } from '../actions/eventsActions'
import { compareEvents } from '../helpers/reducerUtils'

import rootReducer from '../reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunkMiddleware),
    offline({
      ...offlineConfig,
      persistOptions: {
        blacklist: ['notifications', 'loading', 'matchSetup', 'error'],
      },
      retry: (_action, _retries) => null,
      queue: {
        ...defaultQueue,
        enqueue(array, action) {
          if (action.type === eventsConstants.CANCEL_OFFLINE_POST) {
            return array.filter((actionInArray) =>
              !compareEvents(actionInArray.payload, action.payload),
            )
          } else return [...array, action]
        },
      },
    }),
  ),
)
