import { localeConstants } from '../actions/localeActions'

const initialState = localeConstants.LOCALE_PT

const locale = (state = initialState, action) => {
  switch (action.type) {
    case localeConstants.LOCALE_CHANGE:
      return action.locale
    default:
      return state
  }
}

export default locale
