import React, { useEffect, useState } from 'react'

import FiltersEngine from '../../components/FiltersEngine/FiltersEngine'
import Container from 'react-bootstrap/Container'
import MatchesResult from '../../components/ResultsDisplay'
import { match } from '../../services/historyService'

import ContentLayout from '../../components/ContentLayout'
import style from './History.module.scss'

import moment from 'moment'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { setMinutes, setHours } from 'date-fns'

import pt from 'date-fns/locale/pt'
import en from 'date-fns/locale/en-GB'

import { useIntl } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import className from 'classnames'
import styles from '../SearchMatches/SearchMatches.module.scss'

registerLocale('pt-locale', pt)
registerLocale('en-locale', en)

function HistoryMatches() {
  const [results, setResults] = useState({})
  const { formatMessage: f } = useIntl()
  const selectedDate = new Date()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [show, setShow] = useState({})
  const [numberResults, setNumberResults] = useState(0)

  const update = (results) => {
    setResults(results)
    nResults(results)
  }

  useEffect(() => {
    const dateResults = () => {
      let arrNewRes = []

      for (const modality in results) {
        const gamesChosen = results[modality].filter((game) => moment(game.data_jogo).format('YYYY-MM-DD HH:mm:ss') >= moment(startDate).format('YYYY-MM-DD HH:mm:ss') && game.data_jogo <= moment(endDate).format('YYYY-MM-DD HH:mm:ss'))
        arrNewRes = [...arrNewRes, ...gamesChosen]
      }

      arrNewRes.sort((a, b) => moment(a.data_jogo) - moment(b.data_jogo))

      setShow({ games: arrNewRes })
    }

    if (startDate != null && endDate != null) {
      dateResults()
    } else {
      setShow(results)
      nResults(results)
    }
  }, [endDate, startDate, results])

  function deleteDateRange() {
    setStartDate(null)
    setEndDate(null)
    setShow(results)
  }

  function handleMaxTime(date) {
    if (date.getDate() === selectedDate.getDate() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getFullYear() === selectedDate.getFullYear()) {
      return (new Date())
    } else {
      return (setHours(setMinutes(new Date(), 0), 23))
    }
  }

  function compareDates(date, date2) {
    return date && date2 &&
      date.getDate() === date2.getDate() &&
      date.getMonth() === date2.getMonth() &&
      date.getFullYear() === date2.getFullYear()
  }

  function maxTimeStartDate() {
    if (compareDates(endDate, startDate)) {
      return endDate
    }
    if (startDate == null && endDate != null) {
      return endDate
    }
    if (startDate == null) {
      return new Date()
    } else {
      return handleMaxTime(startDate)
    }
  }

  function minTimeEndDate() {
    if (compareDates(startDate, endDate)) {
      return startDate
    }
    if (endDate == null && startDate != null) {
      return startDate
    } else {
      return setHours(setMinutes(new Date(), 0), 0)
    }
  }

  function maxTimeEndDate() {
    if (startDate != null && endDate == null && compareDates(startDate, new Date())) {
      return new Date()
    }
    if (startDate != null && endDate == null) {
      return setHours(setMinutes(new Date(), 0), 23)
    }
    if (endDate == null) {
      return new Date()
    } else {
      return handleMaxTime(endDate)
    }
  }

  const nResults = (games) => {
    let counter = 0
    for (const game in games) {
      for (let i = 0; i < games[game].length; i++) {
        counter++
      }
    }
    setNumberResults(counter)
  }
  return (
    <ContentLayout>

      <Container fluid className={className([styles.padContainers, styles.fieldsContainer])}>
        <Row>
          <Col className={styles.pageTitle} data-testid="page-title">
            {f({ id: 'pageTitle.history' })}
          </Col>
        </Row>
        <Row>
          <Col className={styles.autocompleteRow}>
            <FiltersEngine
              callback={update}
              service={match}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {results && Object.keys(results).length > 0 && (
              <div className={style.container}>
                <div className={style.color} data-testid="date-picker-start">
                  <DatePicker
                    locale={f({ id: 'datePicker.locale' })}
                    placeholderText={f({ id: 'datePicker.title.start' })}
                    className="form-control"
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date)
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}

                    showTimeSelect
                    timeFormat={f({ id: 'datePicker.timeFormat' })}
                    timeIntervals={60}
                    timeCaption={f({ id: 'datePicker.time' })}

                    dateFormat={f({ id: 'datePicker.dateFormat' })}
                    maxDate={endDate == null ? new Date() : endDate}
                    minTime={setHours(setMinutes(new Date(), 0), 0)}
                    maxTime={maxTimeStartDate()}
                  />
                </div>

                <div className={style.color} data-testid="date-picker-end">
                  <DatePicker
                    locale={f({ id: 'datePicker.locale' })}
                    placeholderText={f({ id: 'datePicker.title.end' })}
                    className="form-control"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}

                    showTimeSelect
                    timeFormat={f({ id: 'datePicker.timeFormat' })}
                    timeIntervals={60}
                    timeCaption={f({ id: 'datePicker.time' })}

                    dateFormat={f({ id: 'datePicker.dateFormat' })}
                    minTime={minTimeEndDate()}
                    maxTime={maxTimeEndDate()}

                  />
                </div>

                <button data-testid="date-picker-reset"
                  type="button"
                  onClick={deleteDateRange}
                  className={`${style.button} form-control`}
                  hidden={(startDate == null || endDate == null)}
                >
                  {f({ id: 'datePicker.resetDate' })}
                </button>

              </div>
            )}
          </Col>
        </Row>
      </Container>
      {Object.keys(results).length > 0 &&
      <Container className={styles.padContainers} fluid>
        <Row xs="auto" lg="auto" className={styles.numberResults}>
          <Col>
            {numberResults} {numberResults === 1 ? f({ id: 'search.oneResult' }) : f({ id: 'search.multipleResults' })}
          </Col>
        </Row>
      </Container>
      }
      <Container data-testid="results" fluid className={className([styles.padContainers, styles.results])}>
        {Object.keys(show).map((modality, index1) => {
          return show[modality].map((game, index) => {
            const key = index1 + Object.keys(show).length * index
            return <MatchesResult key={key} matchInfo={game}></MatchesResult>
          })
        })}
      </Container>

    </ContentLayout>
  )
}

export default HistoryMatches
