import axios from 'axios'
import { serviceUtils } from '../helpers/serviceUtils'

function matches() {
  return axios
    .get(serviceUtils.baseUrl(`getMatchesColab/Key/${localStorage.getItem('zos-cookie')}`))
    .then(
      response => {
        if (response.data.error_code !== '0') { return Promise.reject(new Error(response.data.error_text, { cause: response.data.error_code })) }
        localStorage.setItem('search-' + localStorage.getItem('zos-cookie'), JSON.stringify(response.data.data))
        return response.data.data
      })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const match = { matches }
