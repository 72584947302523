import { serviceUtils } from '../helpers/serviceUtils'
import moment from 'moment'

const fetchMatch = (matchId) => {
  const currentTime = moment().format('YYYY-MM-DD_HH:mm:ss')
  return serviceUtils.get(
    serviceUtils.baseUrl(`getMatchLiveInfo/MatchID/${matchId}/ClientTime/${currentTime}`),
    console.log('GET INFO'),
  )
}

/* const fetchTeam = (teamId) => {
  return serviceUtils.get(serviceUtils.baseUrl(`getTeamLive/TeamID/${teamId}`))
} */

// eslint-disable-next-line camelcase
const postEvent = (fk_jogo, fk_jogador = 0, minuto, texto, event_id, minuto_extra = 0) => {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    fk_jogo,
    fk_jogador,
    minuto,
    texto,
    event_id,
    minuto_extra,
  }

  return serviceUtils.post(
    serviceUtils.baseUrl('insertLiveEvent'),
    serviceUtils.authHeader,
    serviceUtils.formBody(details),
  )
}

function deleteEvent(eventID) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    eventID,
  }
  return serviceUtils.post(
    serviceUtils.baseUrl('deleteLiveEvent'),
    serviceUtils.authHeader,
    serviceUtils.formBody(details),
    { },
  )
}

// eslint-disable-next-line
function editEvent(eventID, fk_jogador = 0, minute = 0, texto = '', fk_live_tpevent, minute_extra = 0) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    eventID,
    fk_jogador,
    minute,
    texto,
    fk_live_tpevent,
    minute_extra,
  }

  return serviceUtils.post(
    serviceUtils.baseUrl('editLiveEvent'),
    serviceUtils.authHeader,
    serviceUtils.formBody(details),
    { },
  )
}

const editGameTime = (matchID, startTime) => {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    matchID,
    startTime,
  }
  return serviceUtils.post(
    serviceUtils.baseUrl('setStartTime'),
    {},
    serviceUtils.formBody(details),
    { },
  )
}

export const matchCommentaryService = {
  fetchMatch,
  // fetchTeam,
  postEvent,
  deleteEvent,
  editEvent,
  editGameTime,
}
