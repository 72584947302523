import { eventsService } from '../services/eventsService'

export const eventsConstants = {
  POST_EVENT: 'POST_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  POST_EVENT_COMMIT: 'POST_EVENT_COMMIT',
  POST_EVENT_ROLLBACK: 'POST_EVENT_ROLLBACK',
  EDIT_EVENT_COMMIT: 'EDIT_EVENT_COMMIT',
  EDIT_EVENT_ROLLBACK: 'EDIT_EVENT_ROLLBACK',
  DELETE_EVENT_COMMIT: 'DELETE_EVENT_COMMIT',
  DELETE_EVENT_ROLLBACK: 'DELETE_EVENT_ROLLBACK',
  SENT_NOTIFY: 'SENT_NOTIFY',
  CANCEL_OFFLINE_POST: 'CANCEL_OFFLINE_POST',
}

export const eventsActions = {
  postEvent,
  postFavouriteEvent,
  editEvent,
  editEventOrder,
  deleteEvent,
  retrieveFromQueue,
  confirmEvent,
}

function postEvent(event, callback) {
  const { url, method, body, headers } = eventsService.preparePostEvent(event)
  return {
    type: eventsConstants.POST_EVENT,
    payload: event,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.POST_EVENT_COMMIT, meta: event, callback: callback },
        rollback: { type: eventsConstants.POST_EVENT_ROLLBACK, meta: event },
      },
    },
  }
}

function postFavouriteEvent(event, active, callback) {
  const { url, method, body, headers } = eventsService.preparePostFavouriteEvent(event, active)
  return {
    type: eventsConstants.POST_EVENT,
    payload: event,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.POST_EVENT_COMMIT, meta: event, callback: callback },
        rollback: { type: eventsConstants.POST_EVENT_ROLLBACK, meta: event },
      },
    },
  }
}

function confirmEvent(event, callback) {
  const { url, method, body, headers } = eventsService.prepareConfirmEvent(event)
  return {
    type: eventsConstants.POST_EVENT,
    payload: event,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.POST_EVENT_COMMIT, meta: event, callback: callback },
        rollback: { type: eventsConstants.POST_EVENT_ROLLBACK, meta: event },
      },
    },
  }
}

function editEvent(event, callback) {
  const { url, method, body, headers } = eventsService.prepareEditEvent(event)
  return {
    type: eventsConstants.EDIT_EVENT,
    payload: event,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.EDIT_EVENT_COMMIT, meta: event, callback: callback },
        rollback: { type: eventsConstants.EDIT_EVENT_ROLLBACK, meta: event },
      },
    },
  }
}

function editEventOrder(eventID, type, callback) {
  const { url, method, body, headers } = eventsService.prepareEditEventOrder(eventID, type)
  return {
    type: eventsConstants.EDIT_EVENT,
    payload: type,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.EDIT_EVENT_COMMIT, meta: type, callback: callback },
        rollback: { type: eventsConstants.EDIT_EVENT_ROLLBACK, meta: type },
      },
    },
  }
}

function deleteEvent(event, callback) {
  const { url, method, body, headers } = eventsService.prepareDeleteEvent(event)
  return {
    type: eventsConstants.DELETE_EVENT,
    payload: event,
    meta: {
      offline: {
        effect: { url, method, body, headers },
        commit: { type: eventsConstants.DELETE_EVENT_COMMIT, meta: event, callback: callback },
        rollback: { type: eventsConstants.DELETE_EVENT_ROLLBACK, meta: event },
      },
    },
  }
}

function retrieveFromQueue(event) {
  return {
    type: eventsConstants.CANCEL_OFFLINE_POST,
    payload: event,
    meta: {
      offline: {
        effect: { url: '' },
      },
    },
  }
}
