function getDistanceHaversine(lat1, lon1, lat2, lon2) {
  // no game location provided
  if (!lat2 || !lon2) return -1

  // kmeters
  const R = 6371
  // degrees to radians
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  lat1 = deg2rad(lat1)
  lat2 = deg2rad(lat2)

  // Haversine formula
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}
function deg2rad(value) {
  return (value * Math.PI) / 180
}

export { getDistanceHaversine }
