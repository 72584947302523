import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import styles from './PlayerInfo.module.scss'

import { decodeHTML } from '../../helpers/htmlEntities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faBan } from '@fortawesome/free-solid-svg-icons'

const PlayerInfo = ({ meta, info, handleClick, onSaveNumber, type }) => {
  const { formatMessage: f } = useIntl()
  const [editingNumber, setEditingNumber] = useState(false)
  const [edited, setEdited] = useState(false)
  const [tmpNumber, setTmpNumber] = useState(info.number)
  const numberInput = useRef()

  const editNumber = (e) => {
    if (type !== 'setup') return
    const number = e.target.value
    if (tmpNumber !== number) {
      setEdited(true)
      setTmpNumber(number >= 0 ? (number <= 99 ? number : 99) : 0)
    }
  }

  const saveNumber = () => {
    if (type !== 'setup') return
    setEditingNumber(false)
    setEdited(false)
    if (edited && (meta.group === 'main' || meta.group === 'substitutes')) {
      onSaveNumber(meta.id, tmpNumber, meta.team)
    }
  }

  useEffect(() => {
    if (numberInput.current) {
      numberInput.current.focus()
    }
  })

  return (
    <div className={styles.playerInfoWrapper}>
      <div className={styles.playerInfoNumber}>
        {editingNumber && (
          <input
            ref={numberInput}
            type='number'
            value={tmpNumber}
            style={{ width: '3rem' }}
            onChange={editNumber}
            tabIndex={-1}
            onBlur={saveNumber}
          />
        )}
        {type === 'setup' && (
          <FontAwesomeIcon
            icon={faPencilAlt}
            size="xs"
            className={styles.icon}
            style={{ opacity: !editingNumber && (meta.group === 'main' || meta.group === 'substitutes') ? 1 : 0 }}
            onClick={() => setEditingNumber(true)}
          />
        )}
        {!editingNumber && (<p>{tmpNumber}</p>)}
      </div>
      <div className={styles.playerInfoPhotoWrapper}>
        {info.photo ? (
          <div
            className={styles.playerInfoPhoto}
            style={{ backgroundImage: `url(${info.photo})` }}
          />
        ) : (
          <div className={styles.placeholder} />
        )}
      </div>
      <div className={styles.playerInfoName}>
        <p>
          <strong><a href={`https://www.zerozero.pt/player.php?id=${meta.id}`} target='_blank' rel="noopener noreferrer">{decodeHTML(info.name)}</a></strong>
        </p>
        <div className={styles.playerInfoFlagPosition}>
          <img alt={'Flag'} src={info.flag} />
          <p>{info.position}</p>
          {info.absent && (
            <FontAwesomeIcon
              icon={faBan}
              size="xs"
              className='ml-1'
              color='red'
            />
          )}
        </div>
      </div>
      <div className={styles.buttonsAndProgressWrapper}>
        <div className={styles.playerInfoButtons}>
          {type === 'setup' ? (
            <>
              <button
                className={
                  meta.group === 'main' ? styles.playerInfoButtonsSelectedMain : styles.playerInfoButtonsMain
                }
                onClick={handleClick}
                id={meta.id}
                team={meta.team}
                origin={meta.group}
                destination="main"
                playername={decodeHTML(info.name)}
              >
                {f({ id: 'matchSetup.mainShort' })}
              </button>
              <button
                className={
                  meta.group === 'substitutes'
                    ? styles.playerInfoButtonsSelectedSub
                    : styles.playerInfoButtonsSub
                }
                onClick={handleClick}
                id={meta.id}
                team={meta.team}
                origin={meta.group}
                destination="substitutes"
                playername={decodeHTML(info.name)}
              >
                {f({ id: 'matchSetup.substitutesShort' })}
              </button>
              <button
                className={
                  meta.group === 'all' ? styles.playerInfoButtonsSelectedAll : styles.playerInfoButtonsAll
                }
                onClick={handleClick}
                id={meta.id}
                team={meta.team}
                origin={meta.group}
                destination="all"
                playername={decodeHTML(info.name)}
              >
                {f({ id: 'matchSetup.allShort' })}
              </button>
            </>) : (
            <button
              className={styles.playerInfoButtonsAdd}
              onClick={() => handleClick(info)}
              id={meta.id}
              team={meta.team}
              origin={meta.group}
              destination="place"
              playername={decodeHTML(info.name)}
            >
              {f({ id: 'matchSetup.lineupsShort' })}
            </button>
          )}
        </div>
        {type === 'setup' && (
          <div className={styles.progressBar}>
            <div
              className={styles.mainPercent}
              style={{ width: `${info.mainPercent * 100}%` }}
            />
            <div
              className={styles.benchPercent}
              style={{ width: `${info.benchPercent * 100}%` }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

PlayerInfo.defaultValues = {
  info: [],
  handleClick: () => { },
}

PlayerInfo.propTypes = {
  meta: PropTypes.shape({
    id: PropTypes.string,
    team: PropTypes.string,
    group: PropTypes.string,
    matchId: PropTypes.string,
  }),
  info: PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    flag: PropTypes.string,
    position: PropTypes.string,
    photo: PropTypes.string,
    mainPercent: PropTypes.number,
    benchPercent: PropTypes.number,
  }),
  onSaveNumber: PropTypes.func,
  handleClick: PropTypes.func,
}

export default PlayerInfo
