import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import { useIntl } from 'react-intl'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import style from './Autocomplete.module.scss'

import './form.css'
const Autocomplete = (props) => {
  const {
    id,
    selected,
    options,
  } = props

  const [opt, setOpt] = useState([])
  const [selection, setSelection] = useState([])
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    options.sort((a, b) => a.name.localeCompare(b.name))

    setOpt(options)
  }, [options])

  const configureSelected = (item) => {
    if (item.name) {
      setSelection([item])
    } else { setSelection([]) }

    selected(item)
  }

  return (
    <>
      <Typeahead
        id={`list-of-${id}`}
        className={style.autocomplete}
        labelKey="name"
        emptyLabel={f({ id: 'search.emptylabel' })}
        onChange={name =>
          configureSelected({
            type: id,
            ...name[0],
          })
        }
        options={opt}
        placeholder={f({ id: `search.${id}` })}
        selected={selection}
        data-testId={`area-of-${id}`}
      />
    </>
  )
}

Autocomplete.defaultProps = {
  options: [],
}

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

export default Autocomplete
