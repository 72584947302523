import axios from 'axios'
import { serviceUtils } from '../helpers/serviceUtils'

function getEvents(match_id) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: '',
  }
  const cookie = localStorage.getItem('zos-cookie')

  return axios
    .get(
      serviceUtils.baseUrl(`getTpEvents/MatchID/${match_id}/Key/${cookie}`),
      requestOptions.body,
      requestOptions.headers,
    )
    .then(res => {
      if (res.data.error_code !== '0') { return Promise.reject(res.data.error_text) }

      localStorage.setItem('events', JSON.stringify(res.data.data))

      return res.data.data
    })
    .catch(err => {
      return Promise.reject(err.message)
    })
}

function getGoalCatalog() {
  return serviceUtils.get(
    serviceUtils.baseUrl('getTpCatalogacao'),
  )
}

function preparePostEvent(event) {
  const comments = localStorage.getItem('comments')
  const current_comments = comments ? JSON.parse(comments) : []
  current_comments.push(event)
  localStorage.setItem('comments', JSON.stringify(current_comments))

  const cookie = localStorage.getItem('zos-cookie')
  event.cookie = cookie

  const formBody = Object.keys(event)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(event[key]),
    )
    .join('&')

  return {
    url: serviceUtils.baseUrl('insertLiveEvent'),
    method: 'POST',
    body: formBody,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function preparePostFavouriteEvent(event, active) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    eventId: event.event_id,
    action: active ? 'add' : 'delete',
  }
  const formBody = Object.keys(details)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

  return {
    url: serviceUtils.baseUrl('favEventHandle'),
    method: 'POST',
    body: formBody,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function prepareConfirmEvent(event) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    fk_jogo: event.fk_jogo,
    golos_casa: event.golos_casa,
    golos_fora: event.golos_fora,
    jogadores_n_casa: event.jogadores_n_casa.join(','),
    jogadores_n_fora: event.jogadores_n_fora.join(','),
  }

  return {
    url: serviceUtils.baseUrl('confirmResult'),
    method: 'POST',
    body: serviceUtils.formBody(details),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function prepareEditEvent(event) {
  const cookie = localStorage.getItem('zos-cookie')
  event.cookie = cookie

  return {
    url: serviceUtils.baseUrl('editLiveEvent'),
    method: 'POST',
    body: serviceUtils.formBody(event),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function prepareEditEventOrder(eventID, type) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    eventID: eventID,
    arrowClick: type,
  }

  return {
    url: serviceUtils.baseUrl('arrowLiveEvent'),
    method: 'POST',
    body: serviceUtils.formBody(details),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function prepareDeleteEvent(event) {
  const cookie = localStorage.getItem('zos-cookie')

  const details = {
    cookie,
    eventID: event.eventID,
  }

  return {
    url: serviceUtils.baseUrl('deleteLiveEvent'),
    method: 'POST',
    body: serviceUtils.formBody(details),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
}

function prepareUploadPhoto(matchID, photo) {
  const cookie = localStorage.getItem('zos-cookie')
  const formData = new FormData()
  formData.append('foto', photo)
  formData.append('cookie', cookie)
  formData.append('fk_jogo', matchID)

  return axios.post(
    `${process.env.REACT_APP_UPLOAD_SERVER}`,
    formData,
    {
      'content-type': 'multipart/form-data',
    },
  )
}

function postUploadPhoto(matchID, photoUrl) {
  const cookie = localStorage.getItem('zos-cookie')

  return axios.post(
    serviceUtils.baseUrl('uploadImage'),
    {
      cookie: cookie,
      fk_jogo: matchID,
      foto: photoUrl,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization-Key': cookie,
      },
    },
  )
}

function deleteUploadPhoto(matchID, path) {
  const cookie = localStorage.getItem('zos-cookie')
  const localPath = path.replace('https://zerozero.live/api/upload/', '')

  return axios.delete(
    `${process.env.REACT_APP_UPLOAD_SERVER}?path=${localPath}&cookie=${cookie}&fk_jogo=${matchID}`,
    {
      'content-type': 'multipart/form-data',
    },
  )
}

export const eventsService = {
  getEvents,
  getGoalCatalog,
  preparePostEvent,
  preparePostFavouriteEvent,
  prepareEditEvent,
  prepareEditEventOrder,
  prepareDeleteEvent,
  prepareConfirmEvent,
  prepareUploadPhoto,
  postUploadPhoto,
  deleteUploadPhoto,
}
