/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import ContentLayout from '../../components/ContentLayout'
import MatchActions from '../../components/MatchActions'
import TeamVersus from '../../components/TeamVersus'
import Team, { teamTypeConstants } from '../../components/Team'
import { pageTitleTranslationId } from '../../helpers/pageTitles'

import { matchSetupActions } from '../../actions/matchSetupActions'
import { liveStartService } from '../../services/liveStartService'
import { history } from '../../helpers/history'

import styles from './MatchSetup.module.scss'
import PlayerMoveAlert from './PlayerMoveAlert'
import { playerGroupConstants } from '../../components/PlayerGroup'
import Lineups from '../../components/Lineups/Lineups'
import LineupsSaveAlert from '../../components/Lineups/LineupsSaveAlert/LineupsSaveAlert'

const FOOTBALL_TACTICS = {
  '4-3-3(MD)': require('../../assets/lineups/tactics/football/4-3-3(MD).json'),
  '4-4-2(MD)': require('../../assets/lineups/tactics/football/4-4-2(MD).json'),
  '4-4-2': require('../../assets/lineups/tactics/football/4-4-2.json'),
  '3-4-3': require('../../assets/lineups/tactics/football/3-4-3.json'),
  '4-5-1': require('../../assets/lineups/tactics/football/4-5-1.json'),
  '5-3-2': require('../../assets/lineups/tactics/football/5-3-2.json'),
  '5-4-1': require('../../assets/lineups/tactics/football/5-4-1.json'),
  '3-5-2': require('../../assets/lineups/tactics/football/3-5-2.json'),
  '3-2-3-2': require('../../assets/lineups/tactics/football/3-2-3-2.json'),
  '4-2-3-1': require('../../assets/lineups/tactics/football/4-2-3-1.json'),
  '4-1-4-1': require('../../assets/lineups/tactics/football/4-1-4-1.json'),
  '4-1-3-2': require('../../assets/lineups/tactics/football/4-1-3-2.json'),
}

const FUTSAL_TACTICS = {
  '2-2': require('../../assets/lineups/tactics/futsal/2-2.json'),
  '1-2-1': require('../../assets/lineups/tactics/futsal/1-2-1.json'),
}

const HOQUEI_TACTICS = {
  '2-2': require('../../assets/lineups/tactics/hoquei/2-2.json'),
  '1-2-1': require('../../assets/lineups/tactics/hoquei/1-2-1.json'),
}

const MatchSetup = () => {
  const { id, lineup_type, formation_type } = useParams()
  const { formatMessage: f } = useIntl()
  const [selectedTeam, setSelectedTeam] = useState(formation_type ? '' : lineup_type)
  const [selectedFormation, setSelectedFormation] = useState(formation_type || '')
  const [playerMoveAlert, setPlayerMoveAlert] = useState({
    title: null,
    text: null,
  })
  const [playerMoveAlertTimeout, setPlayerMoveAlertTimeout] = useState(null)
  const dispatch = useDispatch()
  const { info, home, away, matchId } = useSelector((state) => state.matchSetup)
  const [userHasEditedHomeLineups, setUserHasEditedHomeLineups] = useState(false)
  const [userHasEditedAwayLineups, setUserHasEditedAwayLineups] = useState(false)
  const [userHasEditedHomePlayersNumbers, setUserHasEditedHomePlayersNumbers] = useState(false)
  const [userHasEditedAwayPlayersNumbers, setUserHasEditedAwayPlayersNumbers] = useState(false)
  const [userHasEditedFormations, setUserHasEditedFormations] = useState(false)
  const userHasEditedHomeFormation = useRef(formation_type === teamTypeConstants.HOME)
  const userHasEditedAwayFormation = useRef(formation_type === teamTypeConstants.AWAY)
  const [maxNrPlayers, setMaxNrPlayers] = useState(11)
  const [selectedPlayersHome, setSelectedPlayersHome] = useState([])
  const [selectedPlayersAway, setSelectedPlayersAway] = useState([])
  const [isHomeFormationFilled, setIsHomeFormationFilled] = useState(false)
  const [isAwayFormationFilled, setIsAwayFormationFilled] = useState(false)
  const [activeTacticHome, setActiveTacticHome] = useState('4-3-3(MD)')
  const [activeTacticAway, setActiveTacticAway] = useState('4-3-3(MD)')
  const [modalityTactics, setModalityTactics] = useState(FOOTBALL_TACTICS)
  const [homeOccupiedPositions, setHomeOccupiedPositions] = useState([])
  const [awayOccupiedPositions, setAwayOccupiedPositions] = useState([])
  const [needFormations, setNeedFormations] = useState(false)
  const [saveAlert, setSaveAlert] = useState({
    title: null,
    text: null,
  })
  const id1 = useRef()
  const id2 = useRef()

  const updatePlayerMoveAlert = (name, destination) => {
    setPlayerMoveAlert({
      title: name,
      text: `${f({ id: 'matchSetup.playerMove' })} ${f({
        id: `matchSetup.${destination}`,
      })}`,
    })
  }

  const clearPlayerMoveAlert = () => {
    setPlayerMoveAlert({ title: null, text: null })
  }

  const updateSaveAlert = () => {
    setSaveAlert({
      title: f({ id: 'lineups.saved.title' }),
      text: f({ id: 'lineups.saved.body' }),
    })
  }

  const clearSaveAlert = () => {
    setSaveAlert({ title: null, text: null })
  }

  const onSaveAlertDismiss = () => {
    setSaveAlert({ title: null, text: null })
  }

  const onPlayerMoveAlertDismiss = () => {
    setPlayerMoveAlert({ title: null, text: null })
  }

  const resetFormation = (type, partial = false) => {
    if (!needFormations) return
    let setSelectedPlayers = setSelectedPlayersHome
    let setOccupiedPositions = setHomeOccupiedPositions
    let team = home.main

    if (type === teamTypeConstants.AWAY) {
      setSelectedPlayers = setSelectedPlayersAway
      setOccupiedPositions = setAwayOccupiedPositions
      team = away.main
    }
    setSelectedPlayers([])
    setOccupiedPositions([])
    const setIsFilled = type === teamTypeConstants.HOME ? setIsHomeFormationFilled : setIsAwayFormationFilled
    setIsFilled(false)
    if (partial) {
      setUserHasEditedFormations(true)
    }
    team.forEach((player) => {
      if (!partial) {
        player.position_id = player.backup_position_id
        player.subposition_id = player.backup_subposition_id
      }
      player.selected = false
      player.positioned = false
    })
  }

  const handleClick = (e) => {
    if (
      e.target.getAttribute('origin') !== e.target.getAttribute('destination')
    ) {
      const team = e.target.getAttribute('team')
      const setUserHasEditedLineups = team === teamTypeConstants.HOME ? setUserHasEditedHomeLineups : setUserHasEditedAwayLineups
      setUserHasEditedLineups(true)
      let playerMoved
      const t =
        team === teamTypeConstants.HOME
          ? { ...home }
          : { ...away }

      const filterPlayer = (player) => {
        if (player.id === e.target.getAttribute('id')) {
          playerMoved = player
        }
        return player.id !== e.target.getAttribute('id')
      }

      if (
        e.target.getAttribute('destination') !== playerGroupConstants.MAIN ||
        (e.target.getAttribute('destination') === playerGroupConstants.MAIN &&
          t.main.length < maxNrPlayers)
      ) {
        t[e.target.getAttribute('origin')] = t[
          e.target.getAttribute('origin')
        ].filter(filterPlayer)
        t[e.target.getAttribute('destination')].push({ ...playerMoved })
        if (playerMoveAlertTimeout) {
          clearTimeout(playerMoveAlertTimeout)
        }

        updatePlayerMoveAlert(
          e.target.getAttribute('playername'),
          e.target.getAttribute('destination'),
        )
        setPlayerMoveAlertTimeout(
          setTimeout(
            clearPlayerMoveAlert,
            2000,
            e.target.getAttribute('playername'),
            e.target.getAttribute('destination'),
          ),
        )
      }

      dispatch(
        matchSetupActions.updateTeam(e.target.getAttribute('team'), { ...t }),
      )
    }
  }

  const handleTeamSelection = (e) => {
    if (userHasEditedFormations) {
      onSaveFormations()
    }
    e.preventDefault()
    const team = e.target.getAttribute('team')
    history.push(`/match/${id}/${team}`)
    setSelectedTeam(team)
    setSelectedFormation('')
  }

  const handleFormationSelection = (e) => {
    if (!needFormations) return
    if (userHasEditedFormations) {
      onSaveFormations()
    }
    e.preventDefault()
    handleChangedLineups(id, false, teamTypeConstants.HOME)
    handleChangedLineups(id, false, teamTypeConstants.AWAY)
    const team = e.target.getAttribute('formation')
    history.push(`/match/${id}/${team}/${team}`)
    const userHasEditedFormation = team === teamTypeConstants.HOME ? userHasEditedHomeFormation : userHasEditedAwayFormation
    userHasEditedFormation.current = true
    setSelectedFormation(team)
    setSelectedTeam('')
  }

  const handleSaveClick = (id) => {
    handleChangedLineups(id, !userHasEditedFormations || !needFormations, teamTypeConstants.HOME)
    handleChangedLineups(id, !userHasEditedFormations || !needFormations, teamTypeConstants.AWAY)
    if (userHasEditedFormations && needFormations) {
      const homePositions = handleSaveClickTeam(teamTypeConstants.HOME)
      const awayPositions = handleSaveClickTeam(teamTypeConstants.AWAY)
      handleSaveFormations(id, homePositions, awayPositions, true)
    }
    if (!userHasEditedHomeLineups && !userHasEditedAwayLineups && !userHasEditedFormations) {
      history.push(`/match/${matchId}/live`)
    }
  }

  const onSaveFormations = () => {
    if (needFormations) {
      info.tactic_board = '1'
      const homePositions = handleSaveClickTeam(teamTypeConstants.HOME)
      const awayPositions = handleSaveClickTeam(teamTypeConstants.AWAY)
      handleSaveFormations(id, homePositions, awayPositions, false)
      updateSaveAlert()
      setTimeout(
        clearSaveAlert,
        2000,
      )
    }
  }
  
  const onSavePlayerNumber = (playerId, number, teamType) => {
    const team = teamType === teamTypeConstants.HOME ? home : away
    const setUserHasEdited = teamType === teamTypeConstants.HOME ? setUserHasEditedHomePlayersNumbers : setUserHasEditedAwayPlayersNumbers
    const lineup = team.main.concat(team.substitutes)
    let player = lineup.find((p) => p.id === playerId)

    if (player) {
      player.number = number
      setUserHasEdited(true)
    }
  }

  const handleChangedLineups = (id, startLive, type) => {
    const userHasEditedLineups = type === teamTypeConstants.HOME ? userHasEditedHomeLineups : userHasEditedAwayLineups
    const userHasEditedPlayersNumbers = type === teamTypeConstants.HOME ? userHasEditedHomePlayersNumbers : userHasEditedAwayPlayersNumbers
    if (userHasEditedLineups) {
      resetFormation(type, true)
    }
    if (userHasEditedLineups || userHasEditedPlayersNumbers) {
      if (type === teamTypeConstants.HOME) {
        clearTimeout(id1.current)
      }
      else clearTimeout(id2.current)
      handleSaveLineups(id, startLive, type)
    }
  }

  const handleSaveLineups = (id, startLive, type) => {
    const team = type === teamTypeConstants.HOME ? home : away
    const request = matchSetupActions.sendTeams({
      match_id: id,
      fk_equipa: team.unfiltered.PROFILE.ID,
      starting_eleven: team.main.map((player) => {
        return {
          id: player.id,
          number: player.number
        }
      }),
      bench: team.substitutes.map((player) => {
        return {
          id: player.id,
          number: player.number
        }
      }),
    })
    request
      .then(() => {
        const setUserHasEditedLineups = type === teamTypeConstants.HOME ? setUserHasEditedHomeLineups : setUserHasEditedAwayLineups
        setUserHasEditedLineups(false)
        if (id && startLive) {
          history.push(`/match/${id}/live`)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSaveClickTeam = (type) => {
    const positions = []
    let selectedPlayers = []
    const userHasEditedFormation = type === teamTypeConstants.HOME ? userHasEditedHomeFormation : userHasEditedAwayFormation

    if (info.tactic_board === '1' && !userHasEditedFormation.current) {
      const starting_eleven = type === teamTypeConstants.HOME ? info.home_starting_eleven : info.away_starting_eleven
      selectedPlayers = starting_eleven.map((player) => ({
        ...player,
        position_id: player.posicao,
        subposition_id: player.subposicao,
      }))
    } else {
      selectedPlayers = type === teamTypeConstants.HOME ? selectedPlayersHome : selectedPlayersAway
    }
    selectedPlayers.forEach(player => {
      const subpositions = player.subposition_id.split('-')
      positions[player.id] = {
        position_id: player.position_id,
        subposition_id: subpositions[0],
      }
    })

    return positions
  }

  const handleSaveFormations = (id, homeLineup, awayLineup, startLive) => {
    if (!needFormations) return
    const request = matchSetupActions.sendFormations({
      match_id: id,
      home: {
        starting_eleven: home.main.map((player) => {
          if (!(player.id in homeLineup)) {
            homeLineup[player.id] = {
              position_id: '',
              subposition_id: '',
            }
          }
          return {
            id: player.id,
            position_id: homeLineup[player.id].position_id,
            subposition_id: homeLineup[player.id].position_id === homeLineup[player.id].subposition_id ? '' : homeLineup[player.id].subposition_id,
          }
        }),
      },
      away: {
        starting_eleven: away.main.map((player) => {
          if (!(player.id in awayLineup)) {
            awayLineup[player.id] = {
              position_id: '',
              subposition_id: '',
            }
          }
          return {
            id: player.id,
            position_id: awayLineup[player.id].position_id,
            subposition_id: awayLineup[player.id].position_id === awayLineup[player.id].subposition_id ? '' : awayLineup[player.id].subposition_id,
          }
        }),
      },
    })
    request
      .then(() => {
        setUserHasEditedFormations(false)
        if (startLive) history.push(`/match/${matchId}/live`)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // USE EFFECTS

  useEffect(() => {
    dispatch(matchSetupActions.resetMatch())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!matchId) {
      liveStartService.liveStart(id)
        .then(() => {
          dispatch(matchSetupActions.setMatchId(id))
          dispatch(matchSetupActions.requestInfo(id))
        })
    }
    // eslint-disable-next-line
  }, [matchId])

  useEffect(() => {
    if (matchId === id && info.id_away_team && info.id_home_team) {
      setNeedFormations(info.te_modalidade === '1' || info.te_modalidade === '3' || info.te_modalidade === '8')
      if (info.te_modalidade === '3' || info.te_modalidade === '8') {
        setActiveTacticHome('2-2')
        setActiveTacticAway('2-2')
        setModalityTactics(info.te_modalidade === '3' ? FUTSAL_TACTICS : HOQUEI_TACTICS)
      }
      setMaxNrPlayers(info.te_modalidade === '9' ? 16 : parseInt(info.numstartingplayers))
      dispatch(
        matchSetupActions.requestTeam(
          teamTypeConstants.AWAY,
          info.id_away_team,
          matchId,
        ),
      )
      dispatch(
        matchSetupActions.requestTeam(
          teamTypeConstants.HOME,
          info.id_home_team,
          matchId,
        ),
      )
    }
    // eslint-disable-next-line
  }, [info])

  useEffect(() => {
    if (userHasEditedHomeLineups || userHasEditedHomePlayersNumbers) {
      id1.current = setTimeout(() => handleSaveLineups(id, false, teamTypeConstants.HOME), 10000)
    }
    if (userHasEditedAwayLineups || userHasEditedAwayPlayersNumbers) {
      id2.current = setTimeout(() => handleSaveLineups(id, false, teamTypeConstants.AWAY), 10000)
    }
    return () => {
      clearTimeout(id1.current)
      clearTimeout(id2.current)
    }
    // eslint-disable-next-line
  }, [home, away])

  return (
    <ContentLayout title={pageTitleTranslationId.MATCH}>
      <div className={styles.headerWrapper}>
        <TeamVersus
          home={{
            name: home.unfiltered.PROFILE
              ? (home.unfiltered.PROFILE.ACRONYM ? home.unfiltered.PROFILE.ACRONYM : home.unfiltered.PROFILE.NAME)
              : ' ',
            logo: info.home_team_logo,
          }}
          away={{
            name: away.unfiltered.PROFILE
              ? (away.unfiltered.PROFILE.ACRONYM ? away.unfiltered.PROFILE.ACRONYM : away.unfiltered.PROFILE.NAME)
              : ' ',
            logo: info.away_team_logo,
          }}
          info={{
            fullTime: info.full_time === '1',
            homeGoals: info.home_goals,
            awayGoals: info.away_goals,
            edition: info.edition || '',
            showNamesInSmallScreen: home.unfiltered.PROFILE?.ACRONYM && away.unfiltered.PROFILE?.ACRONYM ? true : false,
          }}
        />
        <MatchActions
          // date={info.date && info.date.split(' ')[0]}
          selectedTeam={selectedTeam}
          selectedFormation={selectedFormation}
          handleTeamSelection={handleTeamSelection}
          handleSaveClick={handleSaveClick}
          handleFormationSelection={handleFormationSelection}
          needFormations={needFormations}
        />
      </div>
      {selectedTeam !== '' && (
        <div className={styles.matchWrapper}>
          <Team
            key={selectedTeam}
            type={selectedTeam}
            handleClick={handleClick}
            maxNrPlayers={maxNrPlayers}
            groupsConstants={[
              playerGroupConstants.MAIN,
              playerGroupConstants.SUBSTITUTES,
              playerGroupConstants.ALL,
            ]}
            onSaveNumber={onSavePlayerNumber}
            page={'setup'}
          />
        </div>
      )}
      {selectedFormation !== '' && needFormations && (
        <Lineups
          availableTactics={modalityTactics}
          modality={info.te_modalidade}
          activeTacticHome={activeTacticHome}
          activeTacticAway={activeTacticAway}
          setActiveTacticHome={setActiveTacticHome}
          setActiveTacticAway={setActiveTacticAway}
          selectedFormation={selectedFormation}
          maxNrPlayers={maxNrPlayers}
          selectedPlayersHome={selectedPlayersHome}
          selectedPlayersAway={selectedPlayersAway}
          setSelectedPlayersHome={setSelectedPlayersHome}
          setSelectedPlayersAway={setSelectedPlayersAway}
          isHomeFilled={isHomeFormationFilled}
          isAwayFilled={isAwayFormationFilled}
          setIsHomeFilled={setIsHomeFormationFilled}
          setIsAwayFilled={setIsAwayFormationFilled}
          resetFormation={resetFormation}
          homeOccupiedPositions={homeOccupiedPositions}
          awayOccupiedPositions={awayOccupiedPositions}
          setHomeOccupiedPositions={setHomeOccupiedPositions}
          setAwayOccupiedPositions={setAwayOccupiedPositions}
          userHasEditedFormations={userHasEditedFormations}
          setUserHasEditedFormations={setUserHasEditedFormations}
          onSave={onSaveFormations}
        />
      )}
      <PlayerMoveAlert
        title={playerMoveAlert.title}
        text={playerMoveAlert.text}
        onDismiss={onPlayerMoveAlertDismiss}
      />
      <LineupsSaveAlert
        title={saveAlert.title}
        text={saveAlert.text}
        onDismiss={onSaveAlertDismiss}
      />
    </ContentLayout>
  )
}

export default MatchSetup
