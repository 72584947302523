import React from 'react'
import { useIntl } from 'react-intl'
import styles from './PenaltySavedHandler.module.scss'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

const PenaltySavedHandler = ({ show, setShow, onClickYes }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Modal show={show} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'savedPenalty' })}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>{f({ id: 'no' })}</Button>
        <Button onClick={onClickYes}>{f({ id: 'yes' })}</Button>
      </Modal.Footer>
    </Modal>
  )
}

PenaltySavedHandler.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
}

export default PenaltySavedHandler
