export const loadingConstants = {
  ADD_REQUEST: 'ADD_REQUEST',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
}

const addRequest = () => {
  return { type: loadingConstants.ADD_REQUEST }
}

const removeRequest = () => {
  return { type: loadingConstants.REMOVE_REQUEST }
}

export const loadingActions = {
  addRequest,
  removeRequest,
}
