import React from 'react'
import { useIntl } from 'react-intl'
import { Spinner } from 'react-bootstrap'

import styles from './LoadingIndicator.module.scss'

const LoadingIndicator = () => {
  const { formatMessage: f } = useIntl()
  return (
    <div className={styles.loadingIndicatorContainer}>
      <p>{f({ id: 'loading' })}</p>
      <Spinner className={styles.spinner} animation="border" />
    </div>
  )
}

export default LoadingIndicator
