import axios from 'axios'
import { history } from '../helpers/history'

const baseUrl = (path) => {
  const lang = localStorage.getItem('lang-key')
  return `${process.env.REACT_APP_SERVER}/api/${path}/liveLang/${lang || 'pt'}`
}

const authHeader = () => {
  return { 'Authorization-Key': localStorage.getItem('zos-cookie') }
}

// eslint-disable-next-line
const formBody = (details) =>
  Object.keys(details)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
    )
    .join('&')

const handleError = (err) => {
  let retErr
  if (!err.response) {
    // not a network error
    retErr = err.message
  } else {
    retErr = err.response.data.error_text
    if (err?.response?.data.error_code === 401) {
      history.push('/')
    }
  }
  return Promise.reject(retErr)
}

const get = async (url, headers, params, customHandlers) => {
  const handler = (customHandlers ? customHandlers.error : null) || handleError

  let reqParams = {}
  if (params) {
    params.forEach((param) => (reqParams = { ...reqParams, param }))
  }

  const config = { headers, params: reqParams }

  return axios
    .get(url, config)
    .then((res) => {
      if (res.data.error_code !== '0') {
        throw new Error(res.data)
      }
      if (customHandlers && customHandlers.response) {
        customHandlers.response(res.data.data)
      }
      return res.data.data
    })
    .catch((err) => handler(err))
}

const post = async (url, headers, body, customHandlers, auth) => {
  const handler = customHandlers.error || handleError
  const requestBody = body
  if (auth && body) {
    body.cookie = localStorage.getItem('zos-cookie')
  }
  return axios
    .post(url, requestBody, { headers })
    .then((res) => {
      if (res.data.error_code !== '0') {
        throw new Error(res.data)
      }
      if (customHandlers && customHandlers.response) {
        customHandlers.response(res.data.data)
      }
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
      handler(err)
    })
}

const postForm = async (url, headers, body, customHandlers, auth) => {
  const handler = customHandlers.error || handleError
  if (auth && body) {
    body.cookie = localStorage.getItem('zos-cookie')
  }
  const requestBody = formBody(body)
  return axios
    .post(url, requestBody, { headers })
    .then((res) => {
      if (res.data.error_code !== '0') {
        throw new Error(res.data)
      }
      if (customHandlers && customHandlers.response) {
        customHandlers.response(res.data.data)
      }
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
      handler(err)
    })
}

export const serviceUtils = {
  baseUrl,
  authHeader,
  get,
  post,
  formBody,
  postForm,
}
