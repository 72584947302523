import React from 'react'
import { useSelector } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { history } from './helpers/history'

import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Login from './pages/Login'
import SearchMatches from './pages/SearchMatches'
import Homepage from './pages/Homepage'
import MatchSetup from './pages/MatchSetup'
import MatchComments from './pages/MatchComments'
import History from './pages/History'
import NotificationsContainer from './components/NotificationsContainer'

const AppRouter = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const cookie = useSelector((state) => state.auth.cookie)

  const searchParams = new URLSearchParams(window.location.search)
  const oauth_token_zz = searchParams.get('oauth_token_zz')
  const fk_jogo = searchParams.get('fk_jogo')

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/search" component={SearchMatches} />
        <PrivateRoute path="/history" component={History} />
        <PrivateRoute path="/match/:id/live" component={MatchComments} />
        <PrivateRoute path="/match/:id/:lineup_type/:formation_type?" component={MatchSetup} />
        <PrivateRoute exact path="/" component={Homepage} />
        <Route exact path="/login">
          {cookie && loggedIn ? <Redirect to={oauth_token_zz && fk_jogo ? `/match/${fk_jogo}/home` : '/'} /> : <Login />}
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
      <NotificationsContainer />
    </Router>
  )
}

export default AppRouter
