/*global FB*/
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Button,
  Col,
  InputGroup,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import { authActions } from '../../actions/authActions'

import styles from './Login.module.scss'
import logo from '../../assets/logo.svg'
import logo48 from '../../assets/logo48x48.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { authService } from '../../services/authService'
import { Link } from 'react-router-dom';

const Login = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  })

  const [errorMessage, setErrorMessage] = useState('')
  const googleScriptLoaded = useRef(false)
  const facebookScriptLoaded = useRef(false)
  const { username, password } = inputs
  const loggingIn = useSelector((state) => state.auth.loggingIn)
  const dispatch = useDispatch()

  const { formatMessage: f } = useIntl()
  const googleButton = useRef()

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget
    e.preventDefault()
    e.stopPropagation()

    if (form.checkValidity()) {
      dispatch(authActions.login(username, password, setErrorMessage))
    }
  }

  function prepareLoginTwitter() {
    authService.prepareLoginTwitter(process.env.REACT_APP_REDIRECT_URI + '/login')
  }

  function autoLoginZerozero() {
    window.location.href = `https://www.zerozero.pt/redir_zzlive_login.php?callback_uri=${process.env.REACT_APP_REDIRECT_URI}`
  }

  function loginZerozero() {
    sessionStorage.removeItem('auto_logged')
    window.location.reload()
  }

  useEffect(() => {
    /** Google */
    const initializeGsi = () => {
      if (!window.google || googleScriptLoaded.current) return
      googleScriptLoaded.current = true
      if (window.google?.accounts) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_CLIENT_ID_GOOGLE,
          callback: handleGoogleSignIn,
        })
        window.google.accounts.id.renderButton(
          googleButton.current,
          { theme: 'outline', width: 295 },
        )
      }
    }

    const loadGoogleScript = () => {
      const script = document.createElement("script")
      script.src = "https://accounts.google.com/gsi/client"
      script.onload = initializeGsi
      script.async = true
      script.defer = true
      script.id = "google-client-script"
      const body = document.querySelector("body")
      if (body) {
        body.appendChild(script)
      }
    }

    function handleGoogleSignIn(response) {
      dispatch(authActions.loginGoogle(response.credential, setErrorMessage))
    }

    if (!googleScriptLoaded.current) {
      loadGoogleScript()
    }

    // --------------------------------------------------------------------------------------------------------------

    /** Facebook */
    const initializeFB = () => {
      if (!FB || facebookScriptLoaded.current) return
      facebookScriptLoaded.current = true
      FB.init({
        appId: process.env.REACT_APP_CLIENT_ID_FACEBOOK,
        cookie: true,                     // Enable cookies to allow the server to access the session.
        xfbml: true,                     // Parse social plugins on this webpage.
        version: 'v15.0'           // Use this Graph API version for this call.
      })
      FB.Event.subscribe('auth.statusChange', handleFacebookSignIn);
    }

    const loadFacebookScript = () => {
      const script = document.createElement("script")
      script.src = "https://connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v15.0"
      script.onload = initializeFB
      script.async = true
      script.defer = true
      script.id = "facebook-client-script"
      script.crossOrigin = "anonymous"
      script.nonce = "l3W6geNY"
      const body = document.querySelector("body")
      if (body) {
        body.appendChild(script)
      }
    }

    function handleFacebookSignIn(response) {
      if (response.status === 'connected') {
        dispatch(authActions.loginFacebook(response.authResponse.accessToken, setErrorMessage))
      }
    }

    if (!facebookScriptLoaded.current) {
      loadFacebookScript()
    }

    // --------------------------------------------------------------------------------------------------------------

    return () => {
      // Cleanup function that runs when component unmounts
      if (window.google) {
        window.google.accounts.id.cancel()
      }
      const googleScript = document.getElementById("google-client-script")
      if (googleScript) {
        googleScript.remove()
      }
      const facebookScript = document.getElementById("facebook-client-script")
      if (facebookScript) {
        facebookScript.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function handleTwitterSignIn(twitterOAuthToken, twitterOAuthVerifier) {
      dispatch(authActions.loginTwitter(twitterOAuthToken, twitterOAuthVerifier, setErrorMessage))
    }

    function handleZerozeroSignIn(zzOAuthToken, fk_jogo) {
      dispatch(authActions.loginZerozero(zzOAuthToken, fk_jogo, setErrorMessage))
    }

    const searchParams = new URLSearchParams(window.location.search)
    const oauth_token = searchParams.get('oauth_token')
    const oauth_verifier = searchParams.get('oauth_verifier')
    if (oauth_token && oauth_verifier) {
      handleTwitterSignIn(oauth_token, oauth_verifier)
    }

    const oauth_token_zz = searchParams.get('oauth_token_zz')
    const fk_jogo = searchParams.get('fk_jogo')
    if (oauth_token_zz) {
      handleZerozeroSignIn(oauth_token_zz, fk_jogo)
    }
    else if (!sessionStorage.getItem('auto_logged')) {
      sessionStorage.setItem('auto_logged', true)
      autoLoginZerozero()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{'zerozero.live | Login'}</title>
        <body className={styles.login} />
      </Helmet>

      <div className={styles.bg}>
        <Link to="/">
          <img src={logo} className={styles.logo} alt='logo' />
        </Link>
        <Container className={styles.fullHeight} fluid>
          <Row className={classNames('align-items-center', styles.fullHeight)}>
            <Col xs={12} className="text-center">
              <div className={styles.title}>{f({ id: 'login.title' })}</div>
              <div className={styles.error}>
                {
                  errorMessage ? f({ id: 'login.error' }) : ''}
              </div>
              <Form
                onSubmit={handleSubmit}
                className="justify-content-center"
                data-testid="form"
              >
                {/*<Form.Row className="justify-content-center" >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="facebook"
                    className={styles.form}
                  >
                    <div id="facebookButton" className="fb-login-button" data-size="large" data-width="295" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                  </Form.Group>
                </Form.Row>*/}
                <Form.Row className="justify-content-center" >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="zerozero"
                    className={styles.form}
                  >
                    <div id="zzButton" className={styles.zzButton} onClick={() => loginZerozero()}>
                      <img src={logo48} alt='logo' />{f({ id: "login.zerozero" })}
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-center" >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="google"
                    className={styles.form}
                  >
                    <div id="googleButton" className="m-auto" ref={googleButton}></div>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-center" >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="twitter"
                    className={styles.form}
                  >
                    <Button className={styles.twitterButton} onClick={prepareLoginTwitter}>
                      <FontAwesomeIcon icon={faTwitter} className='mr-2' />
                      {f({ id: "login.twitter" })}
                    </Button>
                  </Form.Group>
                </Form.Row>
                <Row className='m-auto'>
                  <div className={`${styles.zzSeparatorText} ${styles.zzSeparatorTextLeft}`}></div>
                  <span className={styles.zzSeparatorTextSpan}>{f({ id: "login.orSeparator" })}</span>
                  <div className={`${styles.zzSeparatorText} ${styles.zzSeparatorTextRight}`}></div>
                </Row>
                <Form.Row className="justify-content-center" >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="username"
                    className={styles.form}
                  >
                    <InputGroup>
                      <Form.Control
                        className={styles.inputBox}
                        name="username"
                        type="text"
                        placeholder={f({ id: 'login.username' })}
                        onChange={handleChange}
                        required
                        data-testid="Username"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row
                  className={classNames(
                    'justify-content-center',
                    styles.password,
                  )}
                >
                  <Form.Group
                    as={Col}
                    xs="10"
                    sm="8"
                    controlId="password"
                    className={styles.form}
                  >
                    <InputGroup>
                      <Form.Control
                        className={styles.inputBox}
                        name="password"
                        type="password"
                        placeholder={f({ id: 'login.password' })}
                        onChange={handleChange}
                        required
                        data-testid="Password"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-center">
                  <a
                    href="https://www.zerozero.pt/registar.php?op=lost_pass"
                    className={styles.link}
                  >
                    {f({ id: 'login.lostPass' })}
                  </a>
                </Form.Row>

                <Form.Row className="justify-content-center">
                  <Form.Group>
                    <Button type="submit" className={styles.submit}>
                      {loggingIn ? (
                        <Spinner
                          data-testid="loading-indicator"
                          animation="border"
                          variant="light"
                          style={{ height: '20px', width: '20px' }}
                        />
                      ) : (
                        f({ id: 'login.submit' })
                      )}
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
          </Row>
          <div id="fb-root"></div>
        </Container>
      </div>
    </>
  )
}

export default Login
