import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import { Button, Modal, Row } from 'react-bootstrap'
import closeIcon from '../../assets/eventEditorIcons/closeIcon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize'

import styles from './FeedbackModal.module.scss'
import { feedbackActions } from '../../actions/feedbackActions'
import PropTypes from 'prop-types'

const FeedbackModal = ({ show, onHide }) => {
  const dispatch = useDispatch()
  const [feedbackTextBox, setFeedbackTextBox] = useState('')
  const [feedbackSent, setFeedbackSent] = useState(false)

  const { formatMessage: f } = useIntl()

  const handleSubmitFeedback = () => {
    if (feedbackTextBox === '') return
    dispatch(feedbackActions.postFeedback(feedbackTextBox, null))
    setFeedbackSent(true)
    setTimeout(() => {
      onHideModal()
      setFeedbackSent(false)
    }, 2000)
  }

  const onChangeFeedback = (e) => {
    setFeedbackTextBox(e.target.value)
  }

  const onHideModal = () => {
    setFeedbackTextBox('')
    onHide()
  }

  return (
    <Modal show={show} onHide={onHideModal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'feedback.title' })}
        </Modal.Title>
        <img
          data-testid="closeButton"
          alt='close button'
          src={closeIcon}
          className={classNames(
            styles.header_icon,
            styles.cursor_on,
          )}
          onClick={onHideModal}
        />
      </Modal.Header>
      <Modal.Body>
        {feedbackSent ? (
          <Row className='m-0'>
            <p className='m-0'>{f({ id: 'feedback.thanksMessage' })}</p>
            <FontAwesomeIcon icon={faCheck} color="green" className='ml-2 mt-auto mb-auto' />
          </Row>
        ) : (
          <>
            <p>{f({ id: 'feedback.content' })}</p>
            <TextareaAutosize
              data-testid="textArea"
              minRows={10}
              value={feedbackTextBox}
              id="message"
              className={classNames('form-control', styles.type_edit)}
              placeholder={f({ id: 'match.commentInitText' })}
              onChange={onChangeFeedback}
            />
          </>
        )}
      </Modal.Body>
      {!feedbackSent && (
        <Modal.Footer>
          <Button onClick={handleSubmitFeedback}>
            {f({ id: 'feedback.send' })}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

FeedbackModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default FeedbackModal
