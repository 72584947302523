import { serviceUtils } from '../helpers/serviceUtils'
import { history } from '../helpers/history'

const liveStart = (matchID, tries = 0) => {
  return serviceUtils.postForm(
    serviceUtils.baseUrl('startLive'),
    { 'Content-Type': 'application/x-www-form-urlencoded' },
    {
      matchID: matchID,
    },
    {
      error: (err) => {
        if (err?.response?.status === 500 && tries < 3) {
          setTimeout(() => liveStart(matchID, tries + 1), 1000)
        } else {
          history.push('/')
          throw err
        }
      },
    },
    true,
  )
}

export const liveStartService = {
  liveStart,
}
