import React from 'react'
import { Row } from 'react-bootstrap'
import style from './LivePenaltiesPanel.module.scss'
import scoredImg from '../../assets/penalties/goal-penalty.svg'
import missedImg from '../../assets/penalties/missed-penalty.svg'

function TeamPenalties({ penalties = '', showAll, lastPenEnabled, players = [] }) {
  const penaltiesArray = penalties ? penalties.match(/.{1,5}/g) : ['']
  const smallPenaltiesString = (penaltiesArray.length > 0 ? penaltiesArray[penaltiesArray.length - 1] : '').padEnd(5, ' ')

  const displayPens = (penaltiesString, size = 0) => {
    let pens = []
    const pensRow = []
    let firstRow = true
    let lastFilledIndex = -2

    for (let i = 0; i < penaltiesString.length; i++) {
      // Scored
      if (penaltiesString.charAt(i) === 'V') {
        pens.push(
          <div
            key={i}
            className={`${style.penaltyCircle} rounded-circle`}
            style={{
              background: `url(${scoredImg})`,
            }}
          >
            {players[i] ? (<div className={style.player_name}>{players[i]}</div>) : ''}
          </div>,
        )
        lastFilledIndex = i
      }
      // Failed
      else if (penaltiesString.charAt(i) === 'X') {
        pens.push(
          <div
            key={i}
            className={`${style.penaltyCircle} rounded-circle`}
            style={{
              background: `url(${missedImg})`,
            }}
          >
            {players[i] ? (<div className={style.player_name}>{players[i]}</div>) : ''}
          </div>,
        )
        lastFilledIndex = i
      }
      // Last penalty
      else if (size + i <= 5 || ((lastFilledIndex === i - 1 || penaltiesString.charAt(i) === '-') && lastPenEnabled)) {
        pens.push(<div
          key={i}
          className={`${style.penaltyCircle} rounded-circle`}
        ><p>{(size === 0 ? 1 : size) + i}</p></div>)
      }
      // Disabled penalties
      else {
        pens.push(<div
          key={i}
          className={`${style.penaltyCircleDisabled} rounded-circle`}
        />)
      }

      if (size === 0 && (((i + 1) % 5 === 0) || i === penaltiesString.length - 1)) {
        pensRow.push(
          <Row key={i} className={`mr-0 ml-0 ${firstRow ? '' : 'mt-2'} ${style.penalties} w-100`}>{pens}</Row>,
        )
        pens = []
        firstRow = false
      }
    }
    return size === 0 ? pensRow : pens
  }

  return (
    <Row
      className={`${style.penalties} m-0`}
    >
      {!showAll && displayPens(smallPenaltiesString, penalties.length - penaltiesArray[penaltiesArray.length - 1].length + 1)}
      {showAll && displayPens(penalties.padEnd(penalties.length + 5 - penalties.length % 5, ' '))}
    </Row>
  )
}

export default TeamPenalties
