import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
import styles from './Events.module.scss'
import { useIntl } from 'react-intl'

const ClockInput = ({ clock, setClock, extra, clockHalf, halfTimes, editorMode = false }) => {
  const [regularMinutes, setRegularMinutes] = useState(0)
  const [extraMinutes, setExtraMinutes] = useState(0)
  const [canTimeOut, setCanTimeOut] = useState(true)
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    setRegularMinutes(clock)
  }, [regularMinutes, clock])

  // useEffect(() => {
  //   setRegularMinutes(clock)
  //   // setExtraMinutes(extra > 0 ? extra : 0)
  // }, [regularMinutes, extraMinutes])

  if (canTimeOut) { setTimeout(() => { setRegularMinutes(clock); setExtraMinutes(extra > 0 ? extra : 0); setCanTimeOut(false) }, 0) }

  const handleClockReg = (e) => {
    setRegularMinutes(e.target.value)
    const limits = halfTimes[clockHalf - 1]
    let time = parseInt(e.target.value) || 0

    if (time > limits.end) { time = limits.end } else if (time < 0) { time = 0 }

    changeExtra(e.target.value, time, 0)
  }

  const canChangeMinuteExtra = (minutes) => {
    return minutes === halfTimes[0].end ||
    minutes === halfTimes[1].end ||
    minutes === halfTimes[2].end ||
    minutes === halfTimes[3].end ||
    minutes >= halfTimes[clockHalf - 1].end
  }

  const changeExtra = (minutes, minutesChange, extraChange) => {
    if (canChangeMinuteExtra(minutes)) { setClock(Number(minutesChange), extraChange >= 0 ? Number(extraChange) : 0) } else { setClock(Number(minutesChange), 0) }
  }

  const handleClockExtra = (e) => {
    if (e.target.value < 0 || !canChangeMinuteExtra(regularMinutes)) { setExtraMinutes(0) } else { setExtraMinutes(e.target.value) }

    changeExtra(regularMinutes, clock, e.target.value)
  }

  return (
    <Row className={styles.clockRow}>
      <div className={styles.clockInput}>
        <div className={styles.clockInputSub}>
          <p className={styles.clockOutText}>{f({ id: 'clockInput.regularTime' })} (0 - {halfTimes[clockHalf - 1].end + ')'} </p>
          <input
            className={editorMode ? styles.clockDisplayEditor : styles.clockDisplay}
            type="number"
            onChange={handleClockReg}
            value={regularMinutes}
          />
        </div>
        <div className={styles.clockInputSub}>
          <p className={styles.clockOutText}>{f({ id: 'clockInput.extraTime' })}</p>
          <input
            className={editorMode ? styles.clockDisplayEditor : styles.clockDisplay}
            type="number"
            onChange={handleClockExtra}
            value={extraMinutes}
          />
        </div>
      </div>
    </Row>
  )
}

ClockInput.propTypes = {
  clock: PropTypes.number.isRequired,
  setClock: PropTypes.func.isRequired,
  extra: PropTypes.number.isRequired,
  clockHalf: PropTypes.number.isRequired,
  editorMode: PropTypes.bool,
}

export default ClockInput
