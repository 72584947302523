import { serviceUtils } from '../helpers/serviceUtils'

const responseHandler = (data) => {
  if (!data || data.date === '') {
    throw new Error(data)
  }
}

const errorHandler = (err) => {
  // eslint-disable-next-line
  return Promise.reject(err)
}

const matches = () => {
  return serviceUtils.get(
    serviceUtils.baseUrl(`getColabHistory/Key/${localStorage.getItem('zos-cookie')}`),
    null,
    null,
    {
      response: responseHandler,
      error: errorHandler,
    },
  )
}

export const match = { matches }
