export const localeConstants = {
  LOCALE_CHANGE: 'LOCALE_CHANGE',

  LOCALE_EN: 'en',
  LOCALE_PT: 'pt',
}

export const localeActions = {
  changeLocale,
}

function changeLocale(locale) {
  return { type: localeConstants.LOCALE_CHANGE, locale }
}
