import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Card, Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import MatchInfo from '../../components/MatchInfo'
import Comments from '../../components/Comments'
import moment from 'moment'
import 'moment/locale/pt'
import CommentsForm from '../../components/CommentsForm'
import EventEditor from '../../components/EventEditor'
import styles from './MatchComments.module.scss'
import classNames from 'classnames'
import { matchCommentaryService } from '../../services/matchCommentaryService'
import { matchSetupService } from '../../services/matchSetupService'
import { liveStartService } from '../../services/liveStartService'

import { loadingActions } from '../../actions/loadingActions'
import { eventsService } from '../../services/eventsService'
import { eventsActions } from '../../actions/eventsActions'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { notificationsActions } from '../../actions/notificationsActions'
import ContentLayout from '../../components/ContentLayout'
import MatchTimeEditor from '../../components/MatchTimeEditor/MatchTimeEditor'
import ConflictsHandler from '../../components/ConflictsHandler'
import ConflictsHandlerEdit from '../../components/ConflictsHandlerEdit'
import ConfirmResult from '../../components/CommentsForm/ConfirmResult'
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal'
import EventsOrderConflictsHandler from '../../components/EventsOrderConflictsHandler/EventsOrderConflictsHandler'
import FormationsModal from '../../components/FormationsModal/FormationsModal'
import PenaltySavedHandler from '../../components/PenaltySavedHandler/PenaltySavedHandler'
import CryptoJS from 'crypto-js'

const defaultInfo = {
  id: '',
  stadium: '',
  awayTeam: '',
  homeTeam: '',
  referee: '',
  competition: '',
  edition: '',
  stage: '',
  matchFinished: '2',
  matchDate: '0',
  matchBegin: '0',
  clientTimeDif: '0',
  estadoDesc: '',
  awayAbsences: [],
  homeAbsences: [],
}

const COLLISION_EVENTS = [
  '16',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '56',
  '57',
  '1',
  '7',
  '17',
  '18',
  '23',
  '35',
  '84',
  '34',
  '2',
  '4',
  '6',
  '7',
  '17',
  '18',
  '23',
  '35',
  '44',
  '53',
  '67',
  '68',
  '84',
  '34',
  '5',
]

const TIMER_EVENTS = [
  '9',
  '10',
  '11',
  '12',
  '14',
  '56',
  '57',
  '15',
]

const assistEventId = "84"
const redCardEventId = "4"
const oddsEventId = "110"

const CONFLICTS_STATE = {
  Collision: 0,
  MissingUniforms: 1,
  MissingTimers: 2,
}

const MatchComments = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()
  const [showLivePenalties, setShowLivePenalties] = useState(false)
  const [temp, setTemp] = useState(0)
  const [scrollingDown, setscrollingDown] = useState(false)
  const [matchInfo, setMatchInfo] = useState({ ...defaultInfo })
  const [matchInfoRequest, setMatchInfoRequest] = useState(null)
  const [homeCoach, setHomeCoach] = useState()
  const [awayCoach, setAwayCoach] = useState()
  const [comments, setComments] = useState([])
  const [replyCache, setReplyCache] = useState([])
  const [editComment, setEditComment] = useState({})
  const [editId, setEditId] = useState(0)
  const [homeTeam, setHomeTeam] = useState({
    name: '',
    logo: '',
    score: '',
  })
  const [acroHomeTeam, setAcroHomeTeam] = useState('')
  const [acroAwayTeam, setAcroAwayTeam] = useState('')
  const [hasHomeAcronym, setHasHomeAcronym] = useState(false)
  const [hasAwayAcronym, setHasAwayAcronym] = useState(false)
  const [awayTeam, setAwayTeam] = useState({
    name: '',
    logo: '',
    score: '',
  })
  const [clock, setClock] = useState({
    minutes: 0,
    seconds: 0,
    minutos: 0,
  })
  const [eventForcedMinutes, setEventForcedMinutes] = useState({
    '9': { minute: 0, type: 'init', order: 0 },
    '10': { minute: 0, type: 'end', order: 1 },
    '11': { minute: 0, type: 'init', order: 2 },
    '12': { minute: 0, type: 'end', order: 3 },
    '14': { minute: 0, type: 'init', order: 4 },
    '56': { minute: 0, type: 'end', order: 5 },
    '57': { minute: 0, type: 'init', order: 6 },
    '15': { minute: 0, type: 'end', order: 7 },
  })
  const [extraTime, setExtraTime] = useState(0)
  const [showEvents, setShowEvents] = useState(false)
  const [catalogOptions, setCatalogOptions] = useState({})
  const [eventsList, setEventsList] = useState([])
  const [eventsCategories, setEventsCategories] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [conflictsModalShow, setConflictsModalShow] = useState(false)
  const [conflictsModalEditShow, setConflictsModalEditShow] = useState(false)
  const [matchStartConflictsModalShow, setMatchStartConflictsModalShow] = useState(false)
  const [timersConflictsModalShow, setTimersConflictsModalShow] = useState(false)
  const [conflictingCommentsOrder, setConflictingCommentsOrder] = useState([])
  const [confirmResultShow, setConfirmResultShow] = useState(false)
  const [feedbackModalShow, setFeedbackModalShow] = useState(false)
  const [formationsModalShow, setFormationsModalShow] = useState(false)
  const [conflictingComments, setConflictingComments] = useState([])
  const [missingTimerEvents, setMissingTimerEvents] = useState([])

  const [clockInterval, setClockInterval] = useState(null)
  const [eventsOpen, setEventsOpen] = useState(false)
  const [matchTimeEditorShow, setMatchTimeEditorShow] = useState(false)
  // const [questionnaireNotifShownTimes, setQuestionnaireNotifShownTimes] = useState(0)

  const [tempEvent, setTempEvent] = useState(null)
  const [tempEventEdit, setTempEventEdit] = useState(null)
  const [tempOperation, setTempOperation] = useState('')
  const [canUpdate, setCanUpdate] = useState(false)
  const [duplicate, setDuplicateShow] = useState(false)
  const [needFormations, setNeedFormations] = useState(false)
  const [showSavedPenaltyModal, setShowSavedPenaltyModal] = useState(false)
  const HALFTIMES = [
    {
      start: 0,
      end: matchInfo.duracao_int,
    },
    {
      start: matchInfo.duracao_int,
      end: matchInfo.duracao,
    },
    {
      start: matchInfo.duracao,
      end: matchInfo.duracao + matchInfo.duracao_prolong,
    },
    {
      start: matchInfo.duracao + matchInfo.duracao_prolong,
      end: matchInfo.duracao + 2 * matchInfo.duracao_prolong,
    },
    {
      start: matchInfo.duracao + 2 * matchInfo.duracao_prolong,
      end: matchInfo.duracao + 2 * matchInfo.duracao_prolong,
    },
  ]
  const HALFS = [
    matchInfo.duracao_int,
    matchInfo.duracao,
    matchInfo.duracao + matchInfo.duracao_prolong,
    matchInfo.duracao + 2 * matchInfo.duracao_prolong,
  ]
  const locale = useSelector((state) => state.locale)
  const uploaderEventsIds = [96, 97]
  const [eventInfo, setEventInfo] = useState({
    minute: clock.minutos + ((clock.seconds > 0 && extraTime === 0) ? 1 : 0),
    minuteExtra: extraTime + ((clock.seconds > 0 && extraTime !== 0) ? 1 : 0),
    selectedEvent: null,
    selectedTeam: null,
    selectedPlayer: null,
    selectedPlayerOut: null,
    selectedUniformId: null,
    ignoreMinute: false,
    createPlaymakerStats: false,
    forceMinute: null,
    vodafoneTimestampMinute: '',
    vodafoneTimestampSecond: '',
    hasSelectedCoach: false,
  })


  // useEffect(() => {
  //   if (clock.minutos === 10 && questionnaireNotifShownTimes < 1) {
  //     dispatch(
  //       notificationsActions.addNotification(
  //           `${Date.now() + Math.random()}`,
  //           'info',
  //           'Assim que possível (intervalo ou fim do jogo), por favor clique no botão (?) em cima para preencher um questionário (1 min apenas) para nos ajudar a melhorar a aplicação.',
  //           'info',
  //       ),
  //     )
  //     setQuestionnaireNotifShownTimes(1)
  //   }
  //   if (clock.minutos === 30 && questionnaireNotifShownTimes < 2) {
  //     dispatch(
  //       notificationsActions.addNotification(
  //           `${Date.now() + Math.random()}`,
  //           'info',
  //           'Assim que possível (intervalo ou fim do jogo), por favor clique no botão (?) em cima para preencher um questionário (1 min apenas) para nos ajudar a melhorar a aplicação.',
  //           'info',
  //       ),
  //     )
  //     setQuestionnaireNotifShownTimes(2)
  //   }
  //   if (clock.minutos === 45 && questionnaireNotifShownTimes < 3) {
  //     dispatch(
  //       notificationsActions.addNotification(
  //           `${Date.now() + Math.random()}`,
  //           'info',
  //           'Assim que possível (intervalo ou fim do jogo), por favor clique no botão (?) em cima para preencher um questionário (1 min apenas) para nos ajudar a melhorar a aplicação.',
  //           'info',
  //       ),
  //     )
  //     setQuestionnaireNotifShownTimes(3)
  //   }
  //   if (clock.minutos === 75 && questionnaireNotifShownTimes < 4) {
  //     dispatch(
  //       notificationsActions.addNotification(
  //           `${Date.now() + Math.random()}`,
  //           'info',
  //           'Assim que possível (intervalo ou fim do jogo), por favor clique no botão (?) em cima para preencher um questionário (1 min apenas) para nos ajudar a melhorar a aplicação.',
  //           'info',
  //       ),
  //     )
  //     setQuestionnaireNotifShownTimes(4)
  //   }
  //   if (clock.minutos === 89 && questionnaireNotifShownTimes < 5) {
  //     dispatch(
  //       notificationsActions.addNotification(
  //           `${Date.now() + Math.random()}`,
  //           'info',
  //           'Assim que possível (intervalo ou fim do jogo), por favor clique no botão (?) em cima para preencher um questionário (1 min apenas) para nos ajudar a melhorar a aplicação.',
  //           'info',
  //       ),
  //     )
  //     setQuestionnaireNotifShownTimes(5)
  //   }
  // }, [clock.minutos, dispatch, questionnaireNotifShownTimes])

  const sortPlayers = (p1, p2) => {
    const p1Pos = p1?.posicao ? p1.posicao : -1
    const p2Pos = p2?.posicao ? p2.posicao : -1
    return parseInt(p1Pos) - parseInt(p2Pos)
  }

  const setupMatch = (match, prevEvents, newComments) => {
    if (!match.home_starting_eleven) {
      match.home_starting_eleven = []
    }
    if (!match.away_starting_eleven) {
      match.away_starting_eleven = []
    }
    if (!match.home_bench) {
      match.home_bench = []
    }
    if (!match.away_bench) {
      match.away_bench = []
    }

    if (match.events['']) {
      match.events[0] = match.events['']
      delete match.events['']
    }

    const events = Object.values(match.events)

    const lastTimeStampEvent = events.find(
      (elem) =>
        elem.tipo === 'apitoinicial' ||
        elem.tipo === 'fim1parte' ||
        elem.tipo === 'inicio2parte',
    )

    let homePlayers = matchInfo.homePlayers
      ? matchInfo.homePlayers
      : match.home_starting_eleven.concat(match.home_bench.map((player) => {
        return {
          ...player,
          benched: true,
        }
      }))
    let awayPlayers = matchInfo.awayPlayers
      ? matchInfo.awayPlayers
      : match.away_starting_eleven.concat(match.away_bench.map((player) => {
        return {
          ...player,
          benched: true,
        }
      }))

    homePlayers = homePlayers.map((elem) => {
      const starting = match.home_starting_eleven.find(
        (other) => elem.id === other.id,
      )
      const out = match.home_players_out.find((other) => elem.id === other.id)
      const entered = match.home_players_in.find(
        (other) => elem.id === other.id,
      )
      const is_out = out ? true : !(entered || starting)

      return {
        ...elem,
        out: is_out,
        can_enter: !(starting || entered) && is_out,
        starter: starting ? true : false,
        checked: true,
      }
    }).sort(sortPlayers)

    awayPlayers = awayPlayers.map((elem) => {
      const starting = match.away_starting_eleven.find(
        (other) => elem.id === other.id,
      )
      const out = match.away_players_out.find((other) => elem.id === other.id)
      const entered = match.away_players_in.find(
        (other) => elem.id === other.id,
      )
      const is_out = out ? true : !(entered || starting)

      return {
        ...elem,
        out: is_out,
        can_enter: !(starting || entered) && is_out,
        starter: starting ? true : false,
        checked: true,
      }
    }).sort(sortPlayers)
    
    setMatchInfo({
      ...matchInfo,
      id: match.id,
      homeID: match.id_home_team,
      homeTeam: match.name_home_team,
      homeIcon: match.home_team_logo,
      homePlayers: homePlayers,
      homeArticle: match.home_article[locale],
      awayID: match.id_away_team,
      awayTeam: match.name_away_team,
      awayIcon: match.away_team_logo,
      awayPlayers: awayPlayers,
      awayArticle: match.away_article[locale],
      stadium: match.stadium,
      referee: match.referee,
      stage: match.name_stage,
      competition: match.competition,
      edition: match.edition,
      matchFinished: match.full_time,
      matchDate: match.date,
      matchBegin: match.in_progress || '0',
      half: match.half,
      lastTimeStampEvent: lastTimeStampEvent,
      duracao: parseInt(match.duracao),
      duracao_int: parseInt(match.duracao_int),
      duracao_prolong: parseInt(match.duracao_prolong) / 2,
      estadoDesc: match.estado_descr,
      startTimeLastPeriod: match.startTime_last_period,
      isVodafoneEnabled: match.vodafone_jogo === '1',
      clientTimeDif: match.clientTimeDif,
      te_modalidade: match.te_modalidade,
      no_clock: match.no_clock === '1',
      allplayers_enable: match.allplayers_enable === '1',
      penaltiesString: match.penaltiesString,
      awayTeamClassif: match.away_classif,
      homeTeamClassif: match.home_classif,
      awayAbsences: match.away_absences,
      homeAbsences: match.home_absences,
      awayShirts: match.away_shirts,
      homeShirts: match.home_shirts,
    })
    setNeedFormations(
      (match.te_modalidade === '1' || match.te_modalidade === '3' || match.te_modalidade === '8') &&
      match.tactic_board === '0' &&
      (match.away_starting_eleven.length > 0 || match.home_starting_eleven.length > 0)
    )

    setEventForcedMinutes({
      '9': { minute: 1, type: 'init', order: 0 },
      '10': { minute: parseInt(match.duracao_int), type: 'end', order: 1 },
      '11': { minute: parseInt(match.duracao_int) + 1, type: 'init', order: 2 },
      '12': { minute: 2 * parseInt(match.duracao_int), type: 'end', order: 3 },
      '14': { minute: (2 * parseInt(match.duracao_int)) + 1, type: 'init', order: 4 },
      '56': { minute: 2 * parseInt(match.duracao_int) + matchInfo.duracao_prolong, type: 'end', order: 5 },
      '57': { minute: (2 * parseInt(match.duracao_int)) + matchInfo.duracao_prolong + 1, type: 'init', order: 6 },
      '15': { minute: 2 * parseInt(match.duracao_int) + 2 * matchInfo.duracao_prolong, type: 'end', order: 7 },
    })

    events.reverse()

    let newEvents = []
    if (newComments) { newEvents = events.concat(newComments) } else { newEvents = events }

    if (temp === 0 || (JSON.stringify(prevEvents) !== JSON.stringify(match.events))) {
      setComments(newEvents)
      if (newEvents.length > 0 && match.no_clock === '1') {
        const minutes = Math.max(parseInt(newEvents[newEvents.length - 1].minute), clock.minutos)
        setClock({ ...clock, minutos: minutes })
      }
    }

    setHomeTeam({
      ...homeTeam,
      name: match.name_home_team,
      logo: match.home_team_logo,
      score: match.live_home_goals || match.home_goals,
      penScore: match.live_home_pen_goals || match.home_pen_goals,
    })

    setAwayTeam({
      ...awayTeam,
      name: match.name_away_team,
      logo: match.away_team_logo,
      score: match.live_away_goals || match.away_goals,
      penScore: match.live_away_pen_goals || match.away_pen_goals,
    })
  }

  const isZZMember = useSelector((state) => state.auth.zz_member)
  const isVodafoneEnabled = matchInfo.isVodafoneEnabled && isZZMember && locale === 'pt'

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  const preparePendingComments = (eventsResponse, newComments) => {
    const comments = localStorage.getItem('comments');
    let pending_comments = comments ? JSON.parse(comments) : [];
    let tmp_pending_comments = [];
    if (pending_comments && pending_comments.length > 0) {
      for (let j = 0; j < pending_comments.length; j++) {
        let sliced = false;
        for (let i = 0; i < eventsResponse.length; i++) {
          if (pending_comments[j].hash.toString() === eventsResponse[i]?.hash || eventsResponse[i]?.hash === '') {
            sliced = true;
            break;
          }
        }
        if (!sliced) {
          tmp_pending_comments.push(pending_comments[j]);
        }
      }
      localStorage.setItem('comments', JSON.stringify(tmp_pending_comments));

      if (tmp_pending_comments) {
        let ordem = 10 * eventsResponse.length;
        tmp_pending_comments.forEach((element) => {
          if (element.fk_jogo === id) {
            ordem += 10;
            const newEvent = {};
            newEvent.fk_equipa = element.equipa;
            newEvent.fk_jogador = element.fk_jogador;
            newEvent.fk_jogador_out = element.fk_jogador_out;
            newEvent.fk_live_tpevent = element.event_id;
            newEvent.fk_user = element.fk_user;
            newEvent.hash = element.hash;
            newEvent.id = '';
            newEvent.ignore_minute = element.ignore_minuto;
            newEvent.minute = element.minuto;
            newEvent.minute_extra = element.minuto_extra;
            newEvent.ordem = ordem.toString();
            newEvent.text = element.texto;
            newEvent.timestamp = formatDate(new Date());
            newEvent.tipo = "";
            newEvent.vodafone_clock_time = element.vodafone_clock_time;
            newComments.push(newEvent);
          }
        });
      }
    }

    const currentDeletedComments = localStorage.getItem('deletedComments');
    let deleted_comments = currentDeletedComments ? JSON.parse(currentDeletedComments) : [];
    if (deleted_comments && deleted_comments.length > 0) {
      let new_deleted_comments = [];
      for (let j = 0; j < deleted_comments.length; j++) {
        for (let i = 0; i < eventsResponse.length; i++) {
          if (deleted_comments[j].toString() === eventsResponse[i]?.id) {
            new_deleted_comments.push(deleted_comments[j]);
          }
        }
      }
      localStorage.setItem('deletedComments', JSON.stringify(new_deleted_comments));
    }
  }

  const callFetchMatch = (matchId, activeLoad = false) => {
    if (temp === 0 && activeLoad) {
      dispatch(loadingActions.addRequest())
    }
    const newComments = [];
    matchCommentaryService
      .fetchMatch(matchId)
      .then((res) => {
        let eventsResponse = Object.values(res.events);
        if (eventsResponse) {
          preparePendingComments(eventsResponse, newComments)
        }

        if (temp !== 0) {
          if (JSON.stringify(matchInfoRequest) === JSON.stringify(res)) {
            setMatchInfoRequest(res)
          } else {
            setMatchInfoRequest(res)
            setupMatch(res, matchInfoRequest.events, newComments)
          }
        } else {
          setMatchInfoRequest(res)
          setupMatch(res, null, newComments)
        }

        const tmpEvents = { ...res.events };
        for (let i = 0; i < eventsResponse.length; i++) {
          res.events[i + newComments.length] = tmpEvents[i];
        }
        res.events = Object.assign({}, res.events, newComments.slice().reverse())
        localStorage.setItem('currentMatch', JSON.stringify(res))

        if (temp === 0 && activeLoad) {
          dispatch(loadingActions.removeRequest())
        }
      })
      .catch(() => {
        const localMatchInfo = JSON.parse(localStorage.getItem('currentMatch'))
        if (localMatchInfo !== null) {
          if (localMatchInfo.id === id) {
            setMatchInfoRequest(localMatchInfo)
            setupMatch(localMatchInfo, null)
          }
        }

        /*const errorId = `${Date.now() + Math.random()}`

        dispatch(
          notificationsActions.addNotification(
            errorId,
            'error',
            f({ id: 'match.couldNotLoadMatchInfo' }),
            'error',
          ),
        )
        setTimeout(
          (errorId) => {
            dispatch(notificationsActions.removeNotification(errorId))
          },
          4000,
          errorId,
        )*/
        if (temp === 0 && activeLoad) {
          dispatch(loadingActions.removeRequest())
        }
      })
  }

  useEffect(() => {
    liveStartService.liveStart(id)
  }, [id])

  useEffect(() => {
    callFetchMatch(id, true)
    const intervalId = setInterval(() => callFetchMatch(id), 60000);

    return () => {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setInterval(() => {
      setTemp((prevTemp) => prevTemp + 1)
    }, 30000)
  }, [])

  useEffect(() => {
    if (temp === 0) {
      setscrollingDown(true)
    }

    if (matchInfo.homeID) {
      matchSetupService
        .fetchTeam(matchInfo.homeID, id)
        .then((res) => {
          const players = matchInfo.homePlayers
          if (!res.PLAYERS) {
            return
          }
          res.PLAYERS.forEach((player) => {
            players.forEach((homePlayer) => {
              if (player.player_id === homePlayer.id) {
                homePlayer.icon = player.img_path
              }
            })
          })
          setMatchInfo({
            ...matchInfo,
            homePlayers: players,
          })
          if (res.PROFILE.COACH_ID) {
            setHomeCoach({
              id: res.PROFILE.COACH_ID,
              player_name: res.PROFILE.COACH,
              icon: res.PROFILE.COACH_IMAGE,
            })
          }

          setAcroHomeTeam(res.PROFILE.ACRONYM ? res.PROFILE.ACRONYM : res.PROFILE.NAME)
          setHasHomeAcronym(res.PROFILE.ACRONYM ? true : false)
        })
        .catch(() => {
          const localHomeTeam = JSON.parse(localStorage.getItem('home'))

          if (localHomeTeam !== null) {
            if (matchInfo.homeID === localHomeTeam.PROFILE.ID) {
              const players = matchInfo.homePlayers
              if (!localHomeTeam.PLAYERS) {
                return
              }
              localHomeTeam.PLAYERS.forEach((player) => {
                players.forEach((homePlayer) => {
                  if (player.player_id === homePlayer.id) {
                    homePlayer.icon = player.img_path
                  }
                })
              })
              setMatchInfo({
                ...matchInfo,
                homePlayers: players,
              })
              if (localHomeTeam.PROFILE.COACH_ID) {
                setHomeCoach({
                  id: localHomeTeam.PROFILE.COACH_ID,
                  player_name: localHomeTeam.PROFILE.COACH,
                  icon: localHomeTeam.PROFILE.COACH_IMAGE,
                })
              }

              setAcroHomeTeam(localHomeTeam.PROFILE.ACRONYM ? localHomeTeam.PROFILE.ACRONYM : localHomeTeam.PROFILE.NAME)
              setHasHomeAcronym(localHomeTeam.PROFILE.ACRONYM ? true : false)
            }
          }

          const errorId = `${Date.now() + Math.random()}`
          dispatch(
            notificationsActions.addNotification(
              errorId,
              'error',
              f({ id: 'match.couldNotLoadMatchInfo' }),
              'error',
            ),
          )
          setTimeout(
            (errorId) => {
              dispatch(notificationsActions.removeNotification(errorId))
            },
            4000,
            errorId,
          )
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchInfo.homeID])

  useEffect(() => {
    if (matchInfo.awayID) {
      matchSetupService
        .fetchTeam(matchInfo.awayID, id)
        .then((res) => {
          const players = matchInfo.awayPlayers
          if (!res.PLAYERS) {
            return
          }
          res.PLAYERS.forEach((player) => {
            players.forEach((awayPlayer) => {
              if (player.player_id === awayPlayer.id) {
                awayPlayer.icon = player.img_path
              }
            })
          })
          setMatchInfo({
            ...matchInfo,
            awayPlayers: players,
          })
          if (res.PROFILE.COACH_ID) {
            setAwayCoach({
              id: res.PROFILE.COACH_ID,
              player_name: res.PROFILE.COACH,
              icon: res.PROFILE.COACH_IMAGE,
            })
          }

          setAcroAwayTeam(res.PROFILE.ACRONYM ? res.PROFILE.ACRONYM : res.PROFILE.NAME)
          setHasAwayAcronym(res.PROFILE.ACRONYM ? true : false)
        })
        .catch(() => {
          const localAwayTeam = JSON.parse(localStorage.getItem('away'))

          if (localAwayTeam !== null) {
            if (matchInfo.awayID === localAwayTeam.PROFILE.ID) {
              const players = matchInfo.awayPlayers
              if (!localAwayTeam.PLAYERS) {
                return
              }
              localAwayTeam.PLAYERS.forEach((player) => {
                players.forEach((awayPlayer) => {
                  if (player.player_id === awayPlayer.id) {
                    awayPlayer.icon = player.img_path
                  }
                })
              })
              setMatchInfo({
                ...matchInfo,
                awayPlayers: players,
              })
              if (localAwayTeam.PROFILE.COACH_ID) {
                setAwayCoach({
                  id: localAwayTeam.PROFILE.COACH_ID,
                  player_name: localAwayTeam.PROFILE.COACH,
                  icon: localAwayTeam.PROFILE.COACH_IMAGE,
                })
              }

              setAcroAwayTeam(localAwayTeam.PROFILE.ACRONYM ? localAwayTeam.PROFILE.ACRONYM : localAwayTeam.PROFILE.NAME)
              setHasAwayAcronym(localAwayTeam.PROFILE.ACRONYM ? true : false)
            }
          }

          const errorId = `${Date.now() + Math.random()}`
          dispatch(
            notificationsActions.addNotification(
              errorId,
              'error',
              f({ id: 'match.couldNotLoadMatchInfo' }),
              'error',
            ),
          )
          setTimeout(
            (errorId) => {
              dispatch(notificationsActions.removeNotification(errorId))
            },
            4000,
            errorId,
          )
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchInfo.awayID])

  const parseEvents = (res) => {
    const categories = res.categoria.map((category) => {
      return {
        ...category,
        events: [],
      }
    })
    res.events.forEach((item) => {
      const category = categories.find((elem) => elem.id === item.categoria)

      if (category) {
        category.events.push(item)
      }
    })
    setEventsList(res.events)
    setEventsCategories(categories)
  }

  useEffect(() => {
    eventsService
      .getEvents(id)
      .then((res) => {
        parseEvents(res)
      })
      .catch(() => {
        const id = `${Date.now() + Math.random()}`
        dispatch(
          notificationsActions.addNotification(
            id,
            'error',
            f({ id: 'events.couldNotLoadEvents' }),
            'error',
          ),
        )
        setTimeout(
          (id) => {
            dispatch(notificationsActions.removeNotification(id))
          },
          4000,
          id,
        )

        const events = localStorage.getItem('events')

        if (events) {
          parseEvents(JSON.parse(events))
        }
      })

    eventsService
      .getGoalCatalog()
      .then((res) => {
        setCatalogOptions(res)
      })
      .catch(() => {
        const id = `${Date.now() + Math.random()}`
        dispatch(
          notificationsActions.addNotification(
            id,
            'error',
            f({ id: 'catalogGoals.couldNotLoadOptions' }),
            'error',
          ),
        )
        setTimeout(
          (id) => {
            dispatch(notificationsActions.removeNotification(id))
          },
          4000,
          id,
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleClock()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchInfo])

  useEffect(() => {
    if (replyCache.length !== 0) {
      const comment = findCommentInArray(replyCache[0])
      if (comment) {
        substInCommentsArray(replyCache[0].data.Data)
        setReplyCache(replyCache.slice(1))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyCache, comments])

  const handleDelete = (commentId, update = true) => {
    setscrollingDown(false)
    if (comments[commentId].id) {
      dispatch(
        eventsActions.deleteEvent(
          {
            eventID: comments[commentId].id,
          },
          (res) => {
            if (update) callFetchMatch(id)
          }),
      )

      const currentDeletedComments = localStorage.getItem('deletedComments');
      localStorage.setItem('deletedComments', currentDeletedComments ? JSON.stringify([...JSON.parse(currentDeletedComments), comments[commentId].id]) : JSON.stringify([comments[commentId].id]))
    } else {
      dispatch(eventsActions.retrieveFromQueue(comments[commentId]))
    }

    const current = JSON.parse(localStorage.getItem('currentMatch'))
    const newComments1 = comments.filter((comment, i) => i !== commentId)
    const newComments = newComments1.slice();

    setComments(newComments)

    current.events = Object.assign({}, newComments1.reverse())
    localStorage.setItem('currentMatch', JSON.stringify(current))

    setscrollingDown(false)
  }

  const handlePrepareEdit = (eventToEdit, newCommentText, newEventTime, newEventExtraTime = '0', vodafoneTimestamp = '', ignoreMinute, goalCatalog, penCatalog) => {
    if (eventToEdit.id) {
      const current = JSON.parse(localStorage.getItem('currentMatch'))

      const newComments = []

      comments.forEach((comment) => {
        if (comment.id === eventToEdit.id && comment.minute === eventToEdit.minute) {
          const newComment = { ...comment, id: '', text: newCommentText, minute: newEventTime, minute_extra: newEventExtraTime, vodafone_clock_time: vodafoneTimestamp, ignore_minute: ignoreMinute }
          newComments.push(newComment)
          setTempEventEdit(newComment)
        } else {
          newComments.push(comment)
        }
      })

      // const newComments = comments.map((item) =>
      //   item.id === eventToEdit.id && item.minute === eventToEdit.minute
      //     ? { ...item, id: '', text: newCommentText, minute: newEventTime, minute_extra: newEventExtraTime, vodafone_clock_time: vodafoneTimestamp, ignore_minute: ignoreMinute }
      //     : item,
      // )

      // newComments.sort((a, b) => (a.minute > b.minute) ? 1 : (a.minute === b.minute) ? ((a.minute_extra > b.minute_extra) ? 1 : (a.minute === b.minute && a.minute_extra === b.minute_extra) ? 1 : -1) : -1 )

      setComments(newComments)
      current.events = Object.assign({}, newComments)
      localStorage.setItem('currentMatch', JSON.stringify(current))

      setTempEvent({
        eventID: eventToEdit.id,
        fk_equipa: eventToEdit.fk_equipa,
        fk_jogo: id,
        fk_jogador: eventToEdit.fk_jogador ? eventToEdit.fk_jogador : 0,
        fk_jogador_out: eventToEdit.fk_jogador_out ? eventToEdit.fk_jogador_out : 0,
        minute: newEventTime,
        minute_extra: newEventExtraTime,
        texto: newCommentText,
        fk_live_tpevent: eventToEdit.fk_live_tpevent,
        vodafone_clock_time: vodafoneTimestamp,
        ignore_minute: ignoreMinute,
        origem: goalCatalog.origin || '0',
        zona: goalCatalog.zone || '0',
        corpo: goalCatalog.bodyPart || '0',
        fk_jogador_assist: goalCatalog.playerAssist || '0',
        fk_jogador_sp: penCatalog.playerSuffered || '0',
        fk_jogador_cp: penCatalog.playerCommited || '0',
        hash: eventToEdit.hash
      })

      setTempOperation('edit')

      // TODO: mudar para 3000 se nao funcionar
      setTimeout(() => { setCanUpdate(true) }, 1000)
    }
  }

  const handleEdit = () => {
    dispatch(
      eventsActions.editEvent({
        eventID: tempEvent.eventID,
        fk_jogo: tempEvent.fk_jogo,
        fk_jogador: tempEvent.fk_jogador,
        fk_jogador_out: tempEvent.fk_jogador_out,
        fk_equipa: tempEvent.fk_equipa,
        minuto: tempEvent.minute,
        minuto_extra: tempEvent.minute_extra,
        texto: tempEvent.texto,
        fk_live_tpevent: tempEvent.fk_live_tpevent,
        ignore_minuto: tempEvent.ignore_minute,
        vodafone_clock_time: tempEvent.vodafone_clock_time,
        origem: tempEvent.origem,
        zona: tempEvent.zona,
        corpo: tempEvent.corpo,
        fk_jogador_assist: tempEvent.fk_jogador_assist,
        fk_jogador_sp: tempEvent.fk_jogador_sp,
        fk_jogador_cp: tempEvent.fk_jogador_cp,
      },
        (res) => {
          callFetchMatch(id)
        }),
    )

    // Add assist if it is a goal
    if (tempEvent.fk_jogador_assist !== '0') {
      const teamType = tempEvent.fk_equipa === matchInfo.homeID ? 'home' : 'away'
      const teamName = matchInfo[`${teamType}Team`]
      const playerName = matchInfo[`${teamType}Players`].find((p) => p.id === tempEvent.fk_jogador_assist)?.player_name

      dispatch(
        eventsActions.postEvent(
          {
            fk_jogo: tempEvent.fk_jogo,
            equipa: tempEvent.fk_equipa || 0,
            fk_user: localStorage.getItem('user-id').toString(),
            fk_jogador: tempEvent.fk_jogador_assist || 0,
            minuto: tempEvent.minute || 0,
            texto: eventsList.find((event) => event.id === assistEventId)?.texto
              .replace('{jog1}', playerName)
              .replace('{eq}', teamName) || '',
            event_id: assistEventId,
            minuto_extra: tempEvent.minute_extra || 0,
            ignore_minuto: tempEvent.ignore_minute,
            vodafone_clock_time: tempEvent.vodafone_clock_time,
            hash: CryptoJS.SHA256(localStorage.getItem('user-id').toString() + '_' + Date.now()).toString(),
          },
          (res) => {
            callFetchMatch(id)
          },
        ),
      )
    }
  }

  const handleSetupEdit = (commentId) => {
    setEditComment(comments[commentId])
    setModalShow(true)
    setscrollingDown(false)
    setEditId(commentId)
  }

  const handleChangeCommentsOrder = (eventID, type) => {
    dispatch(
      eventsActions.editEventOrder(eventID, type, (res) => {
        callFetchMatch(id)
      }),
    )
  }

  // -------------------------------------------------------------------------------------------------------------------
  // CONFLICTS START
  // -------------------------------------------------------------------------------------------------------------------

  const checkIfEventCollision = (id) => {
    return COLLISION_EVENTS.some((element) => { return element === id })
  }

  const handlePrepareInsert = (
    commentText,
    event,
    equipa,
    player,
    playerOut,
    clockMinute,
    clockMinuteExtra = 0,
    ignoreMinute,
    createPlaymakerStats,
    vodafoneTimestamp,
    is_coach,
    uniform,
  ) => {
    const userId = localStorage.getItem('user-id')

    const newComment = {
      fk_live_tpevent: event.id,
      fk_user: userId,
      minute: clockMinute ? clockMinute.toString() : 0,
      minute_extra: clockMinuteExtra + '',
      text: commentText,
      tipo: event.abrev,
      id: '',
      ignore_minute: ignoreMinute ? '1' : '0',
      vodafone_clock_time: vodafoneTimestamp,
      fk_equipa: (equipa || 0).toString(),
      hash: CryptoJS.SHA256(userId + '_' + Date.now()).toString()
    }
    const newComments = comments.concat(newComment)

    setComments(newComments)
    const current = JSON.parse(localStorage.getItem('currentMatch'))
    current.events = Object.assign({}, newComments.slice().reverse())
    localStorage.setItem('currentMatch', JSON.stringify(current))

    setscrollingDown(true)

    setTempEvent({
      fk_jogo: id.toString(),
      equipa: (equipa || 0).toString(),
      fk_uniform: (uniform || 0).toString(),
      fk_user: userId.toString(),
      fk_jogador: !is_coach ? (player || 0).toString() : 0,
      fk_treinador: is_coach ? (player || 0).toString() : 0,
      fk_jogador_out: (playerOut || 0).toString(),
      minuto: clockMinute ? clockMinute.toString() : 0,
      texto: commentText,
      event_id: event.id,
      minuto_extra: clockMinuteExtra.toString(),
      ignore_minute: ignoreMinute ? '1' : '0',
      criar_playmaker_stats: createPlaymakerStats ? '1' : '0',
      vodafone_clock_time: vodafoneTimestamp,
      hash: newComment.hash,
    })

    if (matchInfo.no_clock) {
      setClock({ minutos: parseInt(clockMinute ? clockMinute.toString() : 0), seconds: 0 })
    }

    setTempOperation('insert')

    // TODO: mudar para 3000 se nao funcionar
    setTimeout(() => { setCanUpdate(true) }, 1000)
  }

  const handleInsertRedCard = () => {
    const teamType = tempEvent.equipa === matchInfo.homeID ? 'home' : 'away'
    const teamName = matchInfo[`${teamType}Team`]
    const playerId = tempEvent.fk_jogador ? tempEvent.fk_jogador : (tempEvent.fk_jogador_out ? tempEvent.fk_jogador_out : (tempEvent.fk_treinador ? tempEvent.fk_treinador : "0"))
    let person = matchInfo[`${teamType}Players`].find((p) => p.id === playerId)
    person = person || (teamType === 'home' ? homeCoach : awayCoach)

    setTimeout(() => {
      dispatch(
        eventsActions.postEvent(
          {
            fk_jogo: tempEvent.fk_jogo,
            equipa: tempEvent.equipa || 0,
            fk_user: tempEvent.fk_user,
            fk_jogador: tempEvent.fk_jogador || 0,
            fk_treinador: tempEvent.fk_treinador || 0,
            fk_jogador_out: tempEvent.fk_jogador_out || 0,
            minuto: tempEvent.minuto || 0,
            texto: eventsList.find((event) => event.id === redCardEventId)?.texto
              .replace('{jog1}', person.player_name)
              .replace('{eq}', teamName) || '',
            event_id: redCardEventId,
            minuto_extra: tempEvent.minuto_extra || 0,
            ignore_minuto: tempEvent.ignore_minute,
            criar_playmaker_stats: tempEvent.criar_playmaker_stats || 0,
            vodafone_clock_time: tempEvent.vodafone_clock_time,
            hash: CryptoJS.SHA256(tempEvent.fk_user + '_' + Date.now()).toString(),
          },
          (res) => {
            callFetchMatch(id)
          },
        ),
      )
    }, 500)
  }

  const handleInsertOdds = () => {
    setTimeout(() => {
      dispatch(
        eventsActions.postEvent(
          {
            fk_jogo: tempEvent.fk_jogo,
            equipa: tempEvent.equipa || 0,
            fk_user: tempEvent.fk_user,
            fk_jogador: tempEvent.fk_jogador || 0,
            fk_treinador: tempEvent.fk_treinador || 0,
            fk_jogador_out: tempEvent.fk_jogador_out || 0,
            minuto: tempEvent.minuto || 0,
            texto: eventsList.find((event) => event.id === oddsEventId)?.texto,
            event_id: oddsEventId,
            minuto_extra: tempEvent.minuto_extra || 0,
            ignore_minuto: tempEvent.ignore_minute,
            criar_playmaker_stats: tempEvent.criar_playmaker_stats || 0,
            vodafone_clock_time: tempEvent.vodafone_clock_time,
            hash: CryptoJS.SHA256(tempEvent.fk_user + '_' + Date.now()).toString(),
          },
          (res) => {
            callFetchMatch(id)
          },
        ),
      )
    }, 500)
  }

  const handleInsert = () => {
    let waitingTime = 1000
    let hasMissingTimerEvents = false
    if (missingTimerEvents.length > 0) {
      hasMissingTimerEvents = true
      missingTimerEvents.forEach((missingEvent) => {
        waitingTime += 500
        dispatch(
          eventsActions.postEvent(
            {
              fk_jogo: tempEvent.fk_jogo,
              fk_user: tempEvent.fk_user,
              minuto: eventForcedMinutes[missingEvent].minute || 0,
              texto: eventsList.find((event) => event.id === missingEvent)?.texto || '',
              event_id: missingEvent,
              minuto_extra: '0',
              ignore_minuto: '0',
              criar_playmaker_stats: '0',
              vodafone_clock_time: '0',
              hash: CryptoJS.SHA256(tempEvent.fk_user + '_' + Date.now()).toString(),
            },
            (res) => {
              callFetchMatch(id)
            },
          ),
        )
      })
    }
    setMissingTimerEvents([])

    dispatch(
      eventsActions.postEvent(
        {
          fk_jogo: tempEvent.fk_jogo,
          equipa: tempEvent.equipa || 0,
          fk_uniform: tempEvent.fk_uniform || 0,
          fk_user: tempEvent.fk_user,
          fk_jogador: tempEvent.fk_jogador || 0,
          fk_treinador: tempEvent.fk_treinador || 0,
          fk_jogador_out: tempEvent.fk_jogador_out || 0,
          minuto: tempEvent.minuto || 0,
          texto: tempEvent.texto || '',
          event_id: tempEvent.event_id,
          minuto_extra: tempEvent.minuto_extra || 0,
          ignore_minuto: tempEvent.ignore_minute,
          criar_playmaker_stats: tempEvent.criar_playmaker_stats || 0,
          vodafone_clock_time: tempEvent.vodafone_clock_time,
          hash: tempEvent.hash,
        },
        (res) => {
          callFetchMatch(id)
          const isRedCard = res?.data?.Data?.insereVermelho === '1'
          if (isRedCard) {
            handleInsertRedCard()
          }
          const isOdds = res?.data?.Data?.insereOdds === '1'
          if (isOdds) {
            handleInsertOdds()
          }
        },
      ),
    )

    if (tempEvent.event_id === '18') {
      setShowSavedPenaltyModal(true);
    }

    if (tempEvent.event_id === '15') {
      if (!showLivePenalties) {
        setShowLivePenalties(true)
      }
    }

    setTimeout(() => {
      // Apito final
      if (tempEvent.event_id === '13') {
        setConfirmResultShow(true)
      }
      // Intervalo
      else if (tempEvent.event_id === '10') {
        setFormationsModalShow(needFormations)
      }
    }, waitingTime)

    if (hasMissingTimerEvents) {
      setTimeout(() => { editGameTime(id, `${parseInt(tempEvent.minuto) + parseInt(tempEvent.minuto_extra)}:${0}`) }, waitingTime)
    }
  }

  const handlePenaltySaved = () => {
    setShowSavedPenaltyModal(false);
    setEventInfo({
      forceMinute: null,
      hasSelectedCoach: false,
      minute: tempEvent.minuto,
      minuteExtra: tempEvent.minuto_extra,
      selectedEvent: 87,
      selectedPlayer: null,
      selectedPlayerOut: null,
      selectedTeam: parseInt(tempEvent.equipa === matchInfo.homeID ? matchInfo.awayID : matchInfo.homeID),
    })
    setShowEvents(true);
  }

  const postEventResCallback = (payload) => {
    callFetchMatch(id)
    let comment = findCommentInArray(payload)

    if (comment) {
      comment = payload.data.Data
    } else {
      setTimeout(() => {
        setReplyCache((replyCache || []).concat([payload]))
      }, 500)
    }
  }

  const handleConfirmResult = (homeScore, awayScore, playedNotPlayed = { home: [], away: [] }) => {
    dispatch(
      eventsActions.confirmEvent({
        fk_jogo: tempEvent.fk_jogo,
        golos_casa: homeScore,
        golos_fora: awayScore,
        jogadores_n_casa: playedNotPlayed.home,
        jogadores_n_fora: playedNotPlayed.away,
      },),
      postEventResCallback,
    )
  }

  const getCommentHalf = (commentTime) => {
    for (var i = 0; i < HALFS.length; i++) {
      if (commentTime <= HALFS[i]) { return i + 1 }
    }
    return -1
  }

  const checkConflicts = (tolerance, state) => {
    const event = tempEvent
    const user_id = localStorage.getItem('user-id')
    let eventKey = ''
    let eventMinute = ''
    let eventMinuteExtra = ''

    if (tempOperation === 'insert') {
      eventKey = event.event_id
      eventMinute = event.minuto
      eventMinuteExtra = event.minuto_extra
    } else if (tempOperation === 'edit') {
      eventKey = event.fk_live_tpevent
      eventMinute = event.minute
      eventMinuteExtra = event.minute_extra
    }

    // Repeated comment
    if (eventsList.some((element) => {
      return element.id === eventKey && element.evento_unico === '1'
    })) {
      if (comments.some((element) => {
        return (element.id !== '' &&
          element.fk_live_tpevent === eventKey)
      })) {
        setDuplicateShow(true)
        comments.pop()
        return
      }
    }

    // Collision of comments
    if (state <= CONFLICTS_STATE.Collision) {
      if (checkIfEventCollision(eventKey)) {
        const sameTimeComments = comments.filter((element) => {
          return ((parseInt(element.minute) + parseInt(element.minute_extra) <= (parseInt(eventMinute) + parseInt(eventMinuteExtra) + tolerance) && parseInt(element.minute) + parseInt(element.minute_extra) >= (parseInt(eventMinute) + parseInt(eventMinuteExtra) - tolerance)) &&
            getCommentHalf(element.minute) === getCommentHalf(eventMinute) &&
            checkIfEventCollision(element.fk_live_tpevent) &&
            element.id !== '' &&
            element.fk_live_tpevent === eventKey &&
            element.fk_user !== user_id)
        })

        if (sameTimeComments.length > 0) {
          if (tempOperation === 'insert') {
            setConflictingComments(sameTimeComments.concat(comments[comments.length - 1]))
            setConflictsModalShow(true)
          } else if (tempOperation === 'edit') {
            setConflictingComments(sameTimeComments.concat(tempEventEdit))
            setConflictsModalEditShow(true)
          }
          return
        }
      }
    }

    // Is ZZ worker and did not set uniform
    if (state <= CONFLICTS_STATE.MissingUniforms && matchInfo.te_modalidade === "1") {
      if (isZZMember && eventKey === '9') {
        const hasUniform = comments.some((element) => {
          return element.fk_live_tpevent === '109';
        })
        if (!hasUniform) {
          setConflictingCommentsOrder([comments[comments.length - 1], '109', CONFLICTS_STATE.MissingUniforms])
          setTimersConflictsModalShow(true)
          return
        }
      }
    }

    // Missing timers
    if (state <= CONFLICTS_STATE.MissingTimers) {
      if (eventKey !== '13' && tempOperation === 'insert') {
        const minute = parseInt(eventMinute)
        const missingTimers = TIMER_EVENTS.filter((timerId) =>
          timerId !== eventKey && eventForcedMinutes.hasOwnProperty(timerId) &&
          ((eventForcedMinutes.hasOwnProperty(eventKey) && eventForcedMinutes[timerId].order <= eventForcedMinutes[eventKey].order) ||
            (!eventForcedMinutes.hasOwnProperty(eventKey) && eventForcedMinutes[timerId].minute < minute)))
        for (let i = 0; i < comments.length - 1; i++) {
          if (missingTimers.length === 0) break
          if (missingTimers.includes(comments[i].fk_live_tpevent)) {
            missingTimers.splice(0, missingTimers.indexOf(comments[i].fk_live_tpevent) + 1)
          }
        }
        if (missingTimers.length > 0) {
          const newMissingTimers = missingTimers.filter((timerId) => eventForcedMinutes[timerId].minute <= minute)
          setMissingTimerEvents(newMissingTimers)
          setConflictingCommentsOrder([comments[comments.length - 1], missingTimers[0], CONFLICTS_STATE.MissingTimers])
          setTimersConflictsModalShow(true)
          return
        }
      }
    }

    // No collisions
    if (tempOperation === 'insert') {
      handleInsert()
    } else if (tempOperation === 'edit') {
      handleEdit()
    }
  }

  if (canUpdate) {
    checkConflicts(1, CONFLICTS_STATE.Collision)
    setCanUpdate(false)
  }

  const handleInsertAnyway = (state) => {
    checkConflicts(1, state + 1)
  }

  const handleSelectAllWinners = () => {
    checkConflicts(1, CONFLICTS_STATE.MissingTimers)
  }

  const handleEditEvent = () => {
    handleEdit()
    setTempEventEdit(null)
  }

  const handleCancelEvent = () => {
    if (missingTimerEvents.length > 0) {
      setMissingTimerEvents([])
    }
    comments.pop()
  }

  const handleCancelEdit = () => {
    setTimeout(() => { callFetchMatch(id) }, 1000) // update match info
    setTempEventEdit(null)
  }

  const handleConflictDelete = (idx) => {
    const commentId = conflictingComments[idx].id
    const commentIndex = comments.findIndex((element) => {
      return element.id === commentId
    })
    conflictingComments.splice(idx, 1)
    handleDelete(commentIndex, false)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // CONFLICTS END
  // -------------------------------------------------------------------------------------------------------------------

  const substInCommentsArray = (newItem) => {
    const userId = localStorage.getItem('user-id')
    const current = JSON.parse(localStorage.getItem('currentMatch'))
    newItem.fk_user = userId
    const newComments1 = comments.map((item) =>
      item.minute === newItem.minute &&
        item.text === newItem.text &&
        item.fk_live_tpevent === newItem.fk_live_tpevent
        ? newItem
        : item,
    )
    const newComments = comments.map((item) =>
      item.minute === newItem.minute &&
        item.text === newItem.text &&
        item.fk_live_tpevent === newItem.fk_live_tpevent
        ? newItem
        : item,
    )
    setComments(newComments)

    current.events = Object.assign({}, newComments1.reverse())
    localStorage.setItem('currentMatch', JSON.stringify(current))
  }

  const findCommentInArray = (event) => {
    return comments.find(
      (element) =>
        element.minute === event.data.Data.minute &&
        element.text === event.data.Data.text &&
        element.fk_live_tpevent === event.data.Data.fk_live_tpevent,
    )
  }

  const calcTimePassedUntilCheckpoint = useCallback(
    (state, totalDuration, extraTimePartDuration) => {
      const deltas = {
        parte1: 0,
        int: totalDuration / 2,
        parte2: totalDuration / 2,
        int2p: totalDuration,
        extra_parte1: totalDuration,
        intet: totalDuration + extraTimePartDuration,
        extra_parte2: totalDuration + extraTimePartDuration,
        fin: totalDuration + (extraTimePartDuration * 2),
        fin_et: totalDuration + (extraTimePartDuration * 2),
      }

      return deltas[state] || 0
    },
    [],
  )

  const getMatchHalf = useCallback(
    (state) => {
      switch (state) {
        case '': return 1
        case 'parte1': return 1
        case 'int': return 1
        case 'parte2': return 2
        case 'int2p': return 3
        case 'extra_parte1': return 3
        case 'intet': return 4
        case 'extra_parte2': return 4
        case 'fin_et': return 5
        case 'fin': return 2
        default: break
      }
    },
    [],
  )

  const handleClock = () => {
    if (clockInterval) {
      clearInterval(clockInterval)
    }

    setClockInterval(
      setInterval(() => {
        // if (!matchInfo.startTimeLastPeriod) return
        // if (!matchInfo.estadoDesc) return
        if (matchInfo.estadoDesc === '') {
          if (clock.minutos > 0 || clock.seconds > 0) {
            setClock({ minutos: 0, seconds: 0 })
          }
          if (extraTime > 0) {
            setExtraTime(0)
          }
        }

        if (matchInfo.estadoDesc === 'fin' || matchInfo.estadoDesc === undefined) {
          if (matchInfo.penaltiesString?.home || matchInfo.penaltiesString?.away) {
            if (!showLivePenalties) setShowLivePenalties(true)
          }
          else if (showLivePenalties) setShowLivePenalties(false)
          if (comments !== undefined && comments.length > 0) {
            setClock({ minutos: parseInt(comments[comments.length - 1].minute), seconds: 0 })
            setExtraTime(parseInt(comments[comments.length - 1].minute_extra))
          }
          return
        }

        if (matchInfo.estadoDesc !== 'fin_et' && showLivePenalties) {
          setShowLivePenalties(false)
        }

        if (matchInfo.estadoDesc === 'int') {
          setClock({ minutos: parseInt(matchInfo.duracao) / 2, seconds: 1 }) // Forces minute to the next (by having this extra second)
          setExtraTime(0)
          return
        }

        if (matchInfo.estadoDesc === 'int2p') {
          setClock({ minutos: parseInt(matchInfo.duracao), seconds: 1 }) // Forces minute to the next (by having this extra second)
          setExtraTime(0)
          return
        }

        if (matchInfo.estadoDesc === 'intet') {
          setClock({ minutos: parseInt(matchInfo.duracao) + matchInfo.duracao_prolong, seconds: 1 }) // Forces minute to the next (by having this extra second)
          setExtraTime(0)
          return
        }

        if (matchInfo.estadoDesc === 'fin_et') {
          setClock({ minutos: parseInt(matchInfo.duracao) + matchInfo.duracao_prolong * 2, seconds: 1 }) // Forces minute to the next (by having this extra second)
          if (!showLivePenalties) setShowLivePenalties(true)
          setExtraTime(0)
          return
        }

        if (matchInfo.no_clock) {
          return
        }

        const timePassed = calcTimePassedUntilCheckpoint(matchInfo.estadoDesc, parseInt(matchInfo.duracao), matchInfo.duracao_prolong)
        const now = moment().add(parseInt(matchInfo.clientTimeDif), 's').format('YYYY-MM-DD HH:mm:ss')
        const diff = moment(now).diff(moment(matchInfo.startTimeLastPeriod))

        const minutos = Number(Math.floor(diff / 60000)) + timePassed
        const seconds = Number(((diff % 60000) / 1000).toFixed(0))

        const matchHalf = getMatchHalf(matchInfo.estadoDesc)
        const minTime = HALFTIMES[matchHalf - 1].start
        const maxTime = HALFTIMES[matchHalf - 1].end
        const actualMinutes = Math.min(Math.max(minTime, minutos), maxTime)
        let actualExtraTime = minutos - actualMinutes

        if (actualMinutes === maxTime && seconds > 0) { actualExtraTime += 1 }

        if (minutos > 0 || seconds > 0) {
          setClock({ minutos: actualMinutes, seconds: seconds })
          setExtraTime(actualExtraTime)
        }
      }, 1000),
    )
  }

  const editGameTime = (matchId, time) => {
    matchCommentaryService
      .editGameTime(matchId, time)
      .then(() => {
        callFetchMatch(matchId)
      })
      .catch((err) => {
        console.log(err)
        const errorId = `${Date.now() + Math.random()}`

        dispatch(
          notificationsActions.addNotification(
            errorId,
            'error',
            f({ id: 'match.couldNotChangeTime' }),
            'error',
          ),
        )
        setTimeout(
          (errorId) => {
            dispatch(notificationsActions.removeNotification(errorId))
          },
          4000,
          errorId,
        )
      })
  }

  const setTime = (time) => {
    editGameTime(id, time)
  }

  const onHideConfirmResult = () => {
    setConfirmResultShow(false)
    if (!isZZMember) {
      setFeedbackModalShow(true)
    }
    else setFormationsModalShow(needFormations)
  }

  const onHideFeedbackModal = () => {
    setFeedbackModalShow(false)
    setFormationsModalShow(needFormations)
  }

  const onHideFormationsModal = () => {
    setFormationsModalShow(false)
  }

  return (
    <ContentLayout>
      <Container fluid className={classNames(styles.exterior_container)}>
        <MatchInfo
          matchId={id}
          matchDate={matchInfo.matchDate}
          matchBegin={matchInfo.matchBegin}
          matchFinished={matchInfo.matchFinished}
          teamRight={awayTeam}
          acroTeamRight={acroAwayTeam}
          teamLeft={homeTeam}
          acroTeamLeft={acroHomeTeam}
          clock={clock}
          extra={extraTime}
          state={matchInfo.estadoDesc}
          showMatchTimeEditor={() => { setMatchTimeEditorShow(true) }}
          showFormationsEditor={matchInfo.te_modalidade === '1' || matchInfo.te_modalidade === '3' || matchInfo.te_modalidade === '8'}
          showLivePenalties={showLivePenalties}
          livePenalties={matchInfo.penaltiesString}
          showInSmallScreen={hasHomeAcronym && hasAwayAcronym}
          edition={matchInfo.edition}
          setTime={setTime}
          setClock={setClock}
          noClock={matchInfo.no_clock}
          totalDuration={parseInt(matchInfo.duracao)}
          extraTimePartDuration={matchInfo.duracao_prolong}
        />
        <Card
          className={classNames(
            'justify-content-center',
            styles.cmts_container,
            styles.card,
            eventsOpen ? styles.eventsOpen : '',
          )}
        >
          <Comments
            eventsOpen={eventsOpen}
            matchInfo={matchInfo}
            matchBegin={matchInfo.matchBegin}
            matchFinished={matchInfo.matchFinished}
            comments={comments}
            events={eventsList}
            scrollingDown={scrollingDown}
            setupEdit={handleSetupEdit}
            onChangeOrder={handleChangeCommentsOrder}
          />
          <CommentsForm
            eventInfo={eventInfo}
            setEventInfo={setEventInfo}
            showEvents={showEvents}
            setShowEvents={setShowEvents}
            setEventsOpen={setEventsOpen}
            events={eventsList}
            setEvents={setEventsList}
            eventsCategories={eventsCategories}
            handleInsert={handlePrepareInsert}
            matchInfo={matchInfo}
            homeCoach={homeCoach}
            awayCoach={awayCoach}
            clock={clock}
            editComment={editComment}
            extra={extraTime}
            acronyms={[acroHomeTeam, acroAwayTeam]}
            isVodafoneEnabled={isVodafoneEnabled}
            halfTimes={HALFTIMES}
            halfs={HALFS}
            uploaderEventsIds={uploaderEventsIds}
            canOpenTab={matchInfo.estadoDesc === undefined || matchInfo.estadoDesc === '' || clock.minutos !== 0 || clock.seconds !== 0}
          />

          {matchTimeEditorShow && !matchInfo.no_clock &&
            <MatchTimeEditor
              show
              onHide={() => setMatchTimeEditorShow(false)}
              clock={clock}
              setTime={setTime}
              state={matchInfo.estadoDesc}
              totalDuration={parseInt(matchInfo.duracao)}
              extraTimePartDuration={matchInfo.duracao_prolong}
            />
          }

          <EventEditor
            show={modalShow}
            onHide={() => setModalShow(false)}
            id={editId}
            event={editComment}
            handleDelete={handleDelete}
            handleEdit={handlePrepareEdit}
            matchInfo={matchInfo}
            events={eventsList}
            extra={extraTime}
            clock={clock}
            isVodafoneEnabled={isVodafoneEnabled}
            halfTimes={HALFTIMES}
            halfs={HALFS}
            catalogOptions={catalogOptions}
            uploaderEventsIds={uploaderEventsIds}
          />

          <ConflictsHandler
            show={conflictsModalShow}
            matchInfo={matchInfo}
            onHide={() => setConflictsModalShow(false)}
            conflicts={conflictingComments}
            // handleSelectWinnerRevision={handleSelectWinnerRevision}
            handleSelectAllWinners={handleSelectAllWinners}
            events={eventsList}
            handleCancelEvent={handleCancelEvent}
            handleDelete={handleConflictDelete}
          />

          <ConflictsHandlerEdit
            show={conflictsModalEditShow}
            matchInfo={matchInfo}
            onHide={() => setConflictsModalEditShow(false)}
            conflicts={conflictingComments}
            // handleSelectWinnerRevision={handleSelectWinnerRevision}
            handleEditEvent={handleEditEvent}
            events={eventsList}
            handleCancelEdit={handleCancelEdit}
            handleDelete={handleConflictDelete}
          />

          <EventsOrderConflictsHandler
            show={timersConflictsModalShow || matchStartConflictsModalShow}
            matchInfo={matchInfo}
            onHide={() => {
              if (timersConflictsModalShow) {
                setTimersConflictsModalShow(false)
              }
              if (matchStartConflictsModalShow) {
                setMatchStartConflictsModalShow(false)
              }
            }}
            conflicts={conflictingCommentsOrder}
            events={eventsList}
            handleCancelEvent={handleCancelEvent}
            handleDelete={handleConflictDelete}
            handleInsertAnyway={handleInsertAnyway}
          />

          <PenaltySavedHandler
            show={showSavedPenaltyModal}
            setShow={setShowSavedPenaltyModal}
            onClickYes={handlePenaltySaved}
          />

          <ConfirmResult
            show={confirmResultShow}
            matchInfo={matchInfo}
            onHide={onHideConfirmResult}
            handleConfirmResult={handleConfirmResult}
            teamRight={awayTeam}
            teamLeft={homeTeam}
            handleDelete={handleConflictDelete}
          />
          <FeedbackModal
            show={feedbackModalShow}
            onHide={onHideFeedbackModal}
          />
          <FormationsModal
            show={formationsModalShow}
            onHide={onHideFormationsModal}
            matchId={matchInfo.id}
          />
          <Modal show={duplicate} onHide={() => setDuplicateShow(false)} animation={true} className={styles.modal}>
            <Modal.Header>
              <Modal.Title className={classNames(styles.header_title)}>
                {f({ id: 'match.duplicateEvent.title' })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.confirmModal}>
                <p>{f({ id: 'match.duplicateEvent.body' })}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setDuplicateShow(false)}>{f({ id: 'close' })}</Button>
            </Modal.Footer>
          </Modal>
        </Card>
      </Container>
    </ContentLayout>
  )
}

export default MatchComments
