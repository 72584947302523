import { matchSetupConstants } from '../actions/matchSetupActions'

import { teamTypeConstants } from '../components/Team'

const initialState = {
  matchId: '',
  formations: [],
  info: '',
  requestInfo: false,
  home: {
    name: '',
    unfiltered: {},
    main: [],
    substitutes: [],
    all: [],
  },
  requestHome: false,
  away: {
    name: '',
    unfiltered: {},
    main: [],
    substitutes: [],
    all: [],
  },
  requestAway: false,
  error: false,
}

const playerInGame = (id, teamType, state) => {
  if (!state[teamType].main && !state[teamType].substitutes) {
    return false
  }

  return state[teamType].main
    .concat(state[teamType].substitutes)
    .find((player) => player.id === id)
}

const filterPlayersAll = (team, teamType, state, unfiltered) => {
  if (team) {
    return team
      .filter(
        (toFilter) =>
          !playerInGame(toFilter.player_id, teamType, state) &&
          toFilter.INTEAM === '1',
      )
      .map((player) => {
        const position_id = (!('player_last_position_id' in player) || player.player_last_position_id === '0') ? player.player_position_id : player.player_last_position_id
        const subposition_id = (!('player_last_sub_position_id' in player) || player.player_last_sub_position_id === '0') ? player.player_sub_position_id : player.player_last_sub_position_id
        return ({
          id: player.player_id,
          name: player.player_name,
          number: player.player_number,
          position: player.player_position_sigla,
          position_id: position_id,
          subposition_id: subposition_id,
          backup_position_id: position_id,
          backup_subposition_id: subposition_id,
          nationality: player.player_nationality_sigla,
          flag: player.player_flag,
          photo: player.img_path,
          mainPercent:
          unfiltered.PROFILE && unfiltered.PROFILE.numTotalJogos !== '0'
            ? player.titular / unfiltered.PROFILE.numTotalJogos
            : '0',
          benchPercent:
          unfiltered.PROFILE && unfiltered.PROFILE.numTotalJogos !== '0'
            ? (player.suplente + player.suplente_util) /
            unfiltered.PROFILE.numTotalJogos
            : '0',
        })
      })
  }
}

const filterPlayersInGame = (team, is_tactic_set) => {
  if (team) {
    if (is_tactic_set) {
      return team.map((player) => ({
        name: player.player_name,
        number: player.number,
        id: player.id,
        position_id: player.posicao,
        subposition_id: player.subposicao,
        backup_position_id: player.posicao,
        backup_subposition_id: player.subposicao,
      }))
    }
    return team.map((player) => ({
      name: player.player_name,
      number: player.number,
      id: player.id,
    }))
  }
  return []
}

const updatePlayersInGame = (team, all, unfiltered) => {
  return team.map((player) => {
    let updatedPlayer = { ...player }
    all.forEach((updatedInfo) => {
      if (updatedInfo.player_id === player.id) {
        let position_id = player.position_id
        let subposition_id = player.subposition_id
        if (position_id === undefined) {
          position_id = (!('player_last_position_id' in updatedInfo) || updatedInfo.player_last_position_id === '0') ? updatedInfo.player_position_id : updatedInfo.player_last_position_id
        }
        if (subposition_id === undefined) {
          subposition_id = (!('player_last_sub_position_id' in updatedInfo) || updatedInfo.player_last_sub_position_id === '0') ? updatedInfo.player_sub_position_id : updatedInfo.player_last_sub_position_id
        }
        updatedPlayer = {
          ...updatedPlayer,
          position: updatedInfo.player_position_sigla,
          position_id: position_id,
          subposition_id: subposition_id,
          backup_position_id: position_id,
          backup_subposition_id: subposition_id,
          nationality: updatedInfo.player_nationality_sigla,
          flag: updatedInfo.player_flag,
          photo: updatedInfo.img_path,
          selected: false,
          positioned: false,
          positionedTactic: false,
          mainPercent:
            unfiltered.PROFILE && unfiltered.PROFILE.numTotalJogos !== '0'
              ? updatedInfo.titular / unfiltered.PROFILE.numTotalJogos
              : '0',
          benchPercent:
            unfiltered.PROFILE && unfiltered.PROFILE.numTotalJogos !== '0'
              ? (updatedInfo.suplente + updatedInfo.suplente_util) /
              unfiltered.PROFILE.numTotalJogos
              : '0',
        }
      }
    })
    return updatedPlayer
  })
}

const matchSetup = (state = initialState, action) => {
  switch (action.type) {
    case matchSetupConstants.MATCH_SETUP_SET_MATCH_ID:
      return { ...state, matchId: `${action.payload}` }
    case matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM:
      return {
        ...state,
        requestHome: true,
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM_SUCCESS:
      return {
        ...state,
        error: false,
        requestHome: false,
        home: {
          ...state.home,
          updatedPlayers: true,
          unfiltered: action.payload,
          main: updatePlayersInGame(
            state.home.main,
            action.payload.PLAYERS,
            action.payload,
          ),
          substitutes: updatePlayersInGame(
            state.home.substitutes,
            action.payload.PLAYERS,
            action.payload,
          ),
          all: filterPlayersAll(
            action.payload.PLAYERS,
            teamTypeConstants.HOME,
            state,
            action.payload,
          ),
        },
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_HOME_TEAM_FAILURE:
      // eslint-disable-next-line
      const cachedHome = JSON.parse(
        localStorage.getItem(teamTypeConstants.HOME),
      )
      if (
        cachedHome &&
        state.info &&
        cachedHome.PROFILE.ID === state.info.id_home_team
      ) {
        return {
          ...state,
          error: false,
          requestHome: false,
          home: {
            ...state.home,
            unfiltered: cachedHome,
            main: updatePlayersInGame(
              state.home.main,
              cachedHome.PLAYERS,
              action.payload,
            ),
            substitutes: updatePlayersInGame(
              state.home.substitutes,
              cachedHome.PLAYERS,
              action.payload,
            ),
            all: filterPlayersAll(
              cachedHome.PLAYERS,
              teamTypeConstants.HOME,
              state,
              action.payload,
            ),
          },
        }
      } else {
        return {
          ...state,
          home: {
            name: '',
            unfiltered: [],
            main: [],
            substitutes: [],
            all: [],
          },
          requestHome: false,
          error: true,
        }
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM:
      return {
        ...state,
        requestAway: true,
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM_SUCCESS:
      return {
        ...state,
        error: false,
        requestAway: false,
        away: {
          ...state.away,
          updatedPlayers: true,
          unfiltered: action.payload,
          main: updatePlayersInGame(
            state.away.main,
            action.payload.PLAYERS,
            action.payload,
          ),
          substitutes: updatePlayersInGame(
            state.away.substitutes,
            action.payload.PLAYERS,
            action.payload,
          ),
          all: filterPlayersAll(
            action.payload.PLAYERS,
            teamTypeConstants.AWAY,
            state,
            action.payload,
          ),
        },
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_AWAY_TEAM_FAILURE:
      // eslint-disable-next-line
      const cachedAway = JSON.parse(
        localStorage.getItem(teamTypeConstants.AWAY),
      )
      if (
        cachedAway &&
        state.info &&
        cachedAway.PROFILE.ID === state.info.id_away_team
      ) {
        return {
          ...state,
          error: false,
          requestAway: false,
          away: {
            ...state.away,
            unfiltered: cachedAway,
            main: updatePlayersInGame(
              state.away.main,
              cachedAway.PLAYERS,
              action.payload,
            ),
            substitutes: updatePlayersInGame(
              state.away.substitutes,
              cachedAway.PLAYERS,
              action.payload,
            ),
            all: filterPlayersAll(
              cachedAway.PLAYERS,
              teamTypeConstants.AWAY,
              state,
              action.payload,
            ),
          },
        }
      } else {
        return {
          ...state,
          away: {
            name: '',
            unfiltered: [],
            main: [],
            substitutes: [],
            all: [],
          },
          requestAway: false,
          error: true,
        }
      }
    case matchSetupConstants.MATCH_SETUP_UPDATE_HOME_TEAM:
      return {
        ...state,
        home: action.payload,
      }
    case matchSetupConstants.MATCH_SETUP_UPDATE_AWAY_TEAM:
      return {
        ...state,
        away: action.payload,
      }
    case matchSetupConstants.MATCH_SETUP_UPDATE_HOME_PLAYER:
      return {
        ...state,
        home: {
          ...state.home,
          main:
            state.home.main.map((player) => {
              if (player.id === action.payload.id) {
                return action.payload
              }
              return player
            }),
        },
      }
    case matchSetupConstants.MATCH_SETUP_UPDATE_AWAY_PLAYER:
      return {
        ...state,
        away: {
          ...state.away,
          main:
            state.away.main.map((player) => {
              if (player.id === action.payload.id) {
                return action.payload
              }
              return player
            }),
        },
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_INFO:
      return {
        ...initialState,
        matchId: state.matchId,
        requestInfo: true,
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        error: false,
        requestInfo: false,
        info: action.payload,
        home: {
          ...state.home,
          name: action.payload.name_home_team,
          main: filterPlayersInGame(action.payload.home_starting_eleven, action.payload.tactic_board === '1'),
          substitutes: filterPlayersInGame(action.payload.home_bench),
        },
        away: {
          ...state.away,
          name: action.payload.name_away_team,
          main: filterPlayersInGame(action.payload.away_starting_eleven, action.payload.tactic_board === '1'),
          substitutes: filterPlayersInGame(action.payload.away_bench),
        },
      }
    case matchSetupConstants.MATCH_SETUP_REQUEST_INFO_FAILURE:
      // eslint-disable-next-line
      const cachedInfo = JSON.parse(localStorage.getItem('currentMatch'))
      if (cachedInfo && cachedInfo.id === state.matchId) {
        return {
          ...state,
          error: false,
          requestInfo: false,
          info: cachedInfo,
          home: {
            ...state.home,
            name: cachedInfo.name_home_team,
            main: filterPlayersInGame(cachedInfo.home_starting_eleven),
            substitutes: filterPlayersInGame(cachedInfo.home_bench),
          },
          away: {
            ...state.away,
            name: cachedInfo.name_away_team,
            main: filterPlayersInGame(cachedInfo.away_starting_eleven),
            substitutes: filterPlayersInGame(cachedInfo.away_bench),
          },
        }
      } else {
        return {
          ...state,
          info: '',
          requestInfo: false,
          error: true,
        }
      }
    case matchSetupConstants.MATCH_SETUP_RESET:
      return initialState
    default:
      return state
  }
}

export default matchSetup
