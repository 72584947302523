import { useDispatch, useSelector } from 'react-redux'

import { notificationsActions } from './../actions/notificationsActions'

const useNotification = (autoDismissable) => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications)

  const dismissNotification = (id) => {
    dispatch(notificationsActions.removeNotification(id))
  }

  const addNotification = (title, text) => {
    const id = `${Date.now() + Math.random()}`
    dispatch(notificationsActions.addNotification(id, title, text))
    if (autoDismissable) {
      setTimeout(dismissNotification, 4000, id)
    }
  }

  return { addNotification, dismissNotification, notifications }
}

export default useNotification
