import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './MatchTimeEditor.module.scss'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import closeIcon from '../../assets/eventEditorIcons/closeIcon.svg'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { generateTimestamp, getBoundaryValues } from '../../helpers/utils'

const MatchTimeEditor = ({ show, onHide, clock, setTime, state, totalDuration, extraTimePartDuration }) => {
  const { formatMessage: f } = useIntl()

  const [minutes, setMinutes] = useState(`${clock.minutos}`)
  const [seconds, setSeconds] = useState(`${clock.seconds}`)

  const handleSubmit = () => {
    setTime(generateTimestamp(minutes, seconds))
    onHide()
  }

  const [minMinute, maxMinute] = getBoundaryValues(state, parseInt(totalDuration), parseInt(extraTimePartDuration))

  const isValidInput = parseInt(minutes) >= minMinute && parseInt(minutes) <= maxMinute &&
    parseInt(seconds) >= 0 && parseInt(seconds) <= 59

  return (
    <Modal show={show} onHide={onHide} animation={true} className={styles.modal}>
      <Modal.Header>
        <Modal.Title className={classNames(styles.header_title)}>
          {f({ id: 'matchTimeEditor.edit' })}
        </Modal.Title>
        <img
          data-testid="closeButton"
          alt='close button'
          src={closeIcon}
          className={classNames(
            styles.header_icon,
            styles.cursor_on,
            styles.close_btn,
          )}
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          pattern="[0-9]*"
          onChange={(e) => {
            setMinutes(e.target.value)
          }}
          value={minutes}
        />
        {':'}
        <input
          type="text"
          pattern="[0-9]*"
          onChange={(e) => {
            setSeconds(e.target.value)
          }}
          value={seconds}
        />
        {!isValidInput && <p>{f({ id: 'matchTimeEditor.invalid' })}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="submitTimeEdit"
          className={classNames(styles.save_btn)}
          onClick={handleSubmit}
          disabled={!isValidInput}
          type="button"
        >
          {f({ id: 'match.edit.save' })}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

MatchTimeEditor.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  clock: PropTypes.shape({
    minutos: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }),
  setTime: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  totalDuration: PropTypes.number.isRequired,
  extraTimePartDuration: PropTypes.number.isRequired,
}

export default MatchTimeEditor
