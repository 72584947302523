import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import styles from './Notification.module.scss'

const Notification = ({ id, title, text, onClose, variant }) => {
  return (
    <div className={styles.notification}>
      <div
        className={classNames(
          styles.notificationHeader,
          variant ? styles[variant] : '',
        )}
      >
        {<strong>{title}</strong>}
        <button data-testid="notification-close" id={id} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className={styles.notificationBody}>{text}</div>
    </div>
  )
}

Notification.defaultProps = {
  title: '',
  variant: null,
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
}

export default Notification
