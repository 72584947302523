import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { teamTypeConstants } from '../Team'
import styles from './MatchActions.module.scss'
import { Button } from 'react-bootstrap'
import { TbSoccerField } from 'react-icons/tb'
import { useIntl } from 'react-intl'

const MatchActions = ({ selectedTeam, selectedFormation, handleTeamSelection, handleSaveClick, handleFormationSelection, needFormations = true }) => {
  const { id } = useParams()
  const { formatMessage: f } = useIntl()

  return (
    <div className={styles.teamPickerWrapper}>
      {needFormations && (
        <button
          formation={teamTypeConstants.HOME}
          className={
            selectedFormation === teamTypeConstants.HOME
              ? styles.teamPickerSelected
              : ''
          }
          onClick={handleFormationSelection}
        >
          <TbSoccerField size={'1.7em'} strokeWidth={'1'} />
        </button>
      )}
      <button
        onClick={handleTeamSelection}
        team={teamTypeConstants.HOME}
        className={
          selectedTeam === teamTypeConstants.HOME
            ? styles.teamPickerSelected
            : ''
        }
      >
        <FontAwesomeIcon icon={faUserFriends} />
      </button>
      <Button className={styles.date} onClick={() => handleSaveClick(id)}>
        <p>{f({ id: 'matchSetup.startLive' })}</p>
      </Button>
      <button
        onClick={handleTeamSelection}
        team={teamTypeConstants.AWAY}
        className={
          selectedTeam === teamTypeConstants.AWAY
            ? styles.teamPickerSelected
            : ''
        }
      >
        <FontAwesomeIcon icon={faUserFriends} />
      </button>
      {needFormations && (
        <button
          formation={teamTypeConstants.AWAY}
          className={
            selectedFormation === teamTypeConstants.AWAY
              ? styles.teamPickerSelected
              : ''
          }
          onClick={handleFormationSelection}
        >
          <TbSoccerField size={'1.7em'} strokeWidth={'1'} />
        </button>
      )}
    </div>
  )
}

MatchActions.defaultProps = {
  date: '-',
}

MatchActions.propTypes = {
  selectedTeam: PropTypes.string,
  selectedFormation: PropTypes.string,
  handleTeamSelection: PropTypes.func.isRequired,
  handleFormationSelection: PropTypes.func.isRequired,
  needFormations: PropTypes.bool,
}

export default MatchActions
