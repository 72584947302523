import React from 'react'
import PropTypes from 'prop-types'
import styles from './Events.module.scss'

import logo from '../../assets/logo48x48.svg'
import { Col } from 'react-bootstrap'

import { decodeHTML } from '../../helpers/htmlEntities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const Event = ({ id: ID, name: Name, icon: Icon, clickEvent, selected, disabledFunction = () => { }, disabled = false, star = false, isFav = false, onClickFav = () => {} }) => {
  const iconToUse = (!Icon || Icon === 'https://www.zerozero.pt/images/mc/live/') ? logo : Icon

  return (
    <>
      <Col className={styles.eventCard}>
        {star && (
          <FontAwesomeIcon icon={faStar} className={`${styles.favouritesStar} ${isFav ? styles.favourite : ''}`} onClick={() => onClickFav(ID, isFav)} />
        )}
        <div className={styles.circle + (selected ? ' ' + styles.selected : '')} data-testid={'circle-' + ID} onClick={() => {
          if (disabled) disabledFunction(ID)
          else clickEvent(ID)
        }}>
          <img src={iconToUse} alt={Name} className={styles.cardImage} style={{ opacity: disabled ? '0.2' : '1' }} />
        </div>
        <span className={styles.eventName}>
          {decodeHTML(Name)}
        </span>
      </Col>
    </>
  )
}

Event.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  clickEvent: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabledFunction: PropTypes.func,
  disabled: PropTypes.bool,
  star: PropTypes.bool,
  isFav: PropTypes.bool,
  onClickFav: PropTypes.func,
}

Event.defaultProps = {
  icon: 'https://www.zerozero.pt/images/mc/live/',
}

export default Event
